<template>
  <div class="composant-fiche-lieu">
    <ComposantInfosLieu />
    <div class="zone-affichage-numeros">
      <div class="frame-recherche">
        <p class="label-recherche">Recherche :</p>
        <input
          class="mots-cles"
          placeholder="effectuer une recherche"
          v-model="motsCles"
        />
        <p class="label-recherche">Trier par :&nbsp;</p>
        <select class="mots-cles" v-model="optionDeTri">
          <option value="numero">Numero</option>
          <option value="titre">Titre</option>
          <option value="quantite">Stock</option>
        </select>
      </div>
      <div class="liste-numero-container">
        <ComposantNumero
          class="composant-numero element-clickable"
          v-for="numero in listeNumerosFiltreeTriee(
            motsCles,
            optionDeTri,
            refLieu
          )"
          :key="numero.id_numero"
          :numero="numero"
          :id_lieu="lieu.id_lieu"
          @click="ficheNumero(numero.id_numero)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComposantNumero from "./composantNumero";
import ComposantInfosLieu from "./composantInfosLieu";

import { mapState, mapGetters, mapMutations } from "vuex";

const STATES_TO_MAP = ["listeLieux", "lieuVide"];

const GETTERS_TO_MAP = ["listeNumerosFiltreeTriee"];

export default {
  name: "pageFicheLieu",
  components: { ComposantNumero, ComposantInfosLieu },
  data: () => {
    return {
      motsCles: "",
      optionDeTri: "numero",
    };
  },
  methods: {
    nombreMisEnForme(nombre1, nombre2) {
      nombre1 = "" + nombre1;
      nombre2 = nombre2 || " ";
      var c = "",
        d = 0;
      while (nombre1.match(/^0[0-9]/)) {
        nombre1 = nombre1.substr(1);
      }
      for (var i = nombre1.length - 1; i >= 0; i--) {
        c = d != 0 && d % 3 == 0 ? nombre1[i] + nombre2 + c : nombre1[i] + c;
        d++;
      }
      return c;
    },
    ficheNumero(id_numero) {
      var routeData = this.$router.resolve("/edition/consulter-" + id_numero);
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    refLieu() {
      return this.$route.params.refLieu;
    },
    lieu() {
      return this.listeLieux.length <= this.refLieu // pour éviter le plantage le temps que la fonction asynchrone du store récupère la liste
        ? this.lieuVide
        : this.listeLieux[this.refLieu];
    },
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-fiche-lieu {
  padding: 17px 0px;
  width: 100vw;
  display: flex;
  padding-left: 450px;
  align-items: flex-start;
}
.infos-lieu {
  position: fixed;
  height: 100%;
  top: 50px;
  left: 8%;
}

.zone-affichage-numeros {
  margin-left: auto;
  margin-right: auto;
}

.frame-recherche {
  margin-bottom: 54px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.label-recherche {
  width: 107px;
  @include typo-medium;
  color: rgba(44, 82, 130, 1);
  text-align: center;
  margin-right: 19px;
}
.mots-cles {
  width: 268px;
  @include typo-small-medium;
  color: rgba(44, 82, 130, 1);
}
.liste-numero-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // padding-left: 50px;
}
</style>
