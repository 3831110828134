<template>
  <div v-if="tokenOK" class="composant-creation-compte-auteur">
    <p class="titre">Création compte auteur</p>
    <div class="separateur1"></div>
    <p class="titre-two">
      Bienvenue à l’Epicerie Séquentielle ! <br />
      Pour votre première connexion, merci de remplir le formulaire suivant :
    </p>
    <div class="frame-sous-partie">
      <div class="frame-ligne-modale">
        <p class="label">Nom*</p>
        <input class="input" v-model="infos.nom_contributeur" />
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Prénom*</p>
        <input class="input" v-model="infos.prenom_contributeur" />
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Date de naissance*</p>
        <input type="date" class="input" v-model="infos.date_naissance_contributeur" />
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Numero sécurité sociale*</p>
        <input class="input" maxlength="15" v-model="infos.numero_secu_contributeur" />
      </div>
    </div>
    <div class="separateur1"></div>
    <div class="frame-sous-partie">
      <div class="frame-ligne-modale">
        <p class="label">Adresse mail*</p>
        <input class="input" v-model="infos.email_contributeur" />
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Identifiant de connexion*</p>
        <input v-model="infos.identifiant_pass" @keyup="checkIdDisponible" class="input" />
        <div class="infobulle" :bulle-content="infobulleIdentifiant">
          {{ disponibiliteIdStatus }}
        </div>
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Nouveau mot de passe*</p>
        <input class="input" type="password" v-model="infos.password_pass" />
        <div class="infobulle" :bulle-content="infobullePassValidation">
          {{ passwordValidationStatus }}
        </div>
      </div>
      <div class="frame-ligne-modale">
        <p class="label">Confirmation mot de passe*</p>
        <input class="input" type="password" v-model="infos.confirmationNouveauPass" />
        <div class="infobulle" :bulle-content="infobulleEquality">
          {{ passwordEqualityStatus }}
        </div>
      </div>
    </div>
    <div class="btn btn-primary" @click="creerCompteAuteur">
      <p class="bouton-valider">Valider</p>
    </div>
  </div>
  <div v-else class="composant-creation-compte-auteur">
    <p class="titre">Création compte auteur</p>
    <div class="separateur1"></div>
    <div class="titre-two">
      <div class="frame-ligne-modale info-bad-token">
        Le lien d'invitation n'est plus valide...
      </div>
      <div class="frame-ligne-modale info-bad-token">
        Contactez l'Epicerie Sequentielle afin d'en obtenir un nouveau :
      </div>
      <div class="frame-ligne-modale info-bad-token">
        <a :href="'mailto:' + this.emailAssociation">{{ this.emailAssociation }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "pageCreationFuturMembre",
  data: () => {
    return {
      emailAssociation: "mail inconnu",

      infos: {
        nom_contributeur: "",
        prenom_contributeur: "",
        date_naissance_contributeur: "",
        numero_secu_contributeur: "",
        email_contributeur: "",
        identifiant_pass: "",
        password_pass: "",
        confirmationNouveauPass: "",
      },
      token: "",
      tokenOK: false,
      disponibiliteId: false,
    };
  },
  computed: {
    passwordValidation() {
      return this.infos.password_pass.match(
        /^(?=.*[A-Z])(?=.*[!@#$*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/
      );
    },
    disponibiliteIdStatus() {
      return this.disponibiliteId ? "✔️" : "❌";
    },
    passwordValidationStatus() {
      return this.passwordValidation ? "✔️" : "❌";
    },
    passwordEqualityStatus() {
      return this.infos.confirmationNouveauPass == this.infos.password_pass ? "✔️" : "❌";
    },
    infobullePassValidation() {
      return this.passwordValidation
        ? "Mot de passe suffisamment robuste"
        : "Mot de passe trop faible : Le mot de passe doit faire au moins 8 caractères et doit contenir au moins : une majuscule, une minuscule, un chiffre un caractère spécial ( ! @ # $ * )";
    },
    infobulleEquality() {
      return this.infos.confirmationNouveauPass == this.infos.password_pass
        ? "Les mots de passe sont identiques"
        : "Les mots de passe ne sont pas identiques";
    },
    infobulleIdentifiant() {
      return this.disponibiliteId ? "Identifiant disponible" : "Identifiant non disponible";
    },
    emailValidation() {
      return this.infos.email_contributeur.match(/^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i);
    },
  },
  methods: {
    ...mapActions(["modaleAlert", "phpPost"]),
    checkIdDisponible() {
      this.phpPost({
        phpFile: "assets/php/verifierIdentifiantToken.php",
        sendObject: {
          nouvelIdentifiant: this.infos.identifiant_pass,
          token: this.token,
        },
        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.idDisponible) this.disponibiliteId = true;
          else this.disponibiliteId = false;
        },
      });
    },
    allIsOK() {},
    creerCompteAuteur() {
      for (let info in this.infos) {
        if (this.infos[info] == "") return this.modaleAlert("Tous les champs doivent être remplis");
      }
      if (!this.emailValidation) return this.modaleAlert("Le mail saisi n'est pas correct.");

      if (!this.disponibiliteId)
        return this.modaleAlert("L'identifiant que vous avez choisi n'est pas disponible");

      if (this.infos.password_pass != this.infos.confirmationNouveauPass)
        return this.modaleAlert("Les deux mots de passe ne sont pas identiques");

      if (!this.passwordValidation)
        return this.modaleAlert(
          `Le mot de passe doit faire au moins 8 caractères et doit contenir au moins :
          <div style="text-align: left; margin-left: auto; margin-right: auto;">
            <ul>
              <li>Une majuscule</li>
              <li>Une minuscule</li>
              <li>Un chiffre</li>
              <li>Un caractère spécial ( ! @ # $ * )</li>
            </ul>
          </div>`
        );

      this.phpPost({
        phpFile: "assets/php/ajouterNouveauMembreInvite.php",
        sendObject: { ...this.infos, token: this.token },
        callBack: (response) => {
          if (response.reqSuccess) this.$router.push("/");
        },
      });
    },
  },
  created: function() {
    this.tokenOK = false;
    let token = this.$route.params.token;
    this.phpPost({
      phpFile: "assets/php/checkToken.php",
      sendObject: { token },
      disableThumbnail: true,
      callBack: (response) => {
        if (response.reqSuccess) {
          this.tokenOK = response.tokenOK;
          this.token = token;
          this.infos.email_contributeur = response.email;
        }
      },
    });

    this.phpPost({
      phpFile: "assets/php/mailAssociation.php",
      disableThumbnail: true,
      callBack: (response) => {
        if (response.reqSuccess) this.emailAssociation = response.emailAssociation;
      },
    });
  },
  // mounted: function() {
  // },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-creation-compte-auteur {
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 32px;
  @include typo-large;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 32px;
}
.titre-two {
  color: $couleur-texte-fonce;
  align-self: stretch;
  text-align: center;
  margin-bottom: 32px;
  @include typo-medium;
}
.frame-sous-partie {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frame-ligne-modale {
  background-color: $fond-page;
  margin-bottom: 17px;
  padding: 10px;
  display: flex;
  align-items: center;
  &.info-bad-token {
    justify-content: center;
  }
}
.label {
  width: 300px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}

.input {
  width: 300px;
  // height: 23px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-valider {
  background-color: $neutral-btn;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13px;
  align-self: stretch;
}
.bouton-valider {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
</style>
