<template>
  <form class="composant-connexion">
    <p class="gros-titre">Bienvenue chez Georges !</p>
    <!-- <p class="titre">Connexion</p> -->
    <p class="titre">Dites-moi qui vous êtes... Et prouvez-le.</p>
    <div class="separateur1"></div>
    <div class="frame-identifiant-two">
      <p class="label-identifiant">Identifiant</p>
      <input
        class="frame-identifiant"
        placeholder="username"
        v-model="username"
        autocomplete="username"
        @keypress.enter="login"
      />
    </div>
    <div class="frame-identifiant-two">
      <p class="label-identifiant">Mot de passe</p>
      <input
        class="frame-password"
        placeholder="*******"
        v-model="password"
        type="password"
        autocomplete="current-password"
        @keypress.enter="login"
      />
    </div>
    <div class="separateur1"></div>
    <div class="frame-connexion btn btn-primary" @click="login">
      <p class="bouton-connexion">
        Connexion
      </p>
    </div>
    <div class="pass-oublie element-clickable" @click="reinitPassword">
      Mot de passe oublié ?
    </div>
    <div class="pass-oublie element-clickable" @click="formulaireContact">
      Un problème pour vous connecter ?
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ComposantConnexion",
  props: {
    callBack: { default: undefined },
  },
  data: () => {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["phpPost", "modaleOuiNon", "modaleAlert"]),
    ...mapMutations([
      "definirVisibiliteModale",
      "setAuthorization",
      "masquerModale",
      "afficherModale",
      "definirContenuModale",
    ]),
    login() {
      this.phpPost({
        phpFile: "assets/php/__grantTest.php",
        dispatchAtTheEnd: "initialisation",
        dontSendObjectBack: true,
        sendObject: {
          username: this.username,
          password: this.password,
        },
        callBack: (response) => {
          this.definirVisibiliteModale(false);
          this.phpPost({
            phpFile: "./assets/php/exportsBackup.php",
            disableThumbnailForAll: true,
          });
          if (this.callBack != undefined) this.callBack(response);
        },
      });
    },
    reinitPassword() {
      if (this.username == "")
        return this.modaleAlert("Veuillez renseigner un identifiant");
      this.definirContenuModale({
        componentName: "ComposantReinitPass",
        props: {
          identifiant: this.username,
        },
        allowQuitOnClick: true,
      });
      this.afficherModale();
    },
    formulaireContact() {
      window.location = "https://coda.io/form/Formulaire-remarques_dGoidzOQLLk";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-connexion {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 24px 23px 23px 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gros-titre {
  @include typo-titre;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.titre {
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur1 {
  width: 90%;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-identifiant-two {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-identifiant {
  width: 250px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-right: 27px;
  @media (max-width: $mobile-break-point) {
    width: 100px;
  }
}
.frame-identifiant {
  width: 150px;
  padding: 2px 0;
  @include typo-medium;
  color: $couleur-texte-fonce;
  @media (max-width: $mobile-break-point) {
    // width: 100px;
  }
}
.frame-password {
  width: 150px;
  padding: 2px 0 0;
  @include typo-medium;
  color: $couleur-texte-fonce;
  @media (max-width: $mobile-break-point) {
    // width: 100px;
  }
}
.frame-connexion {
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 160px;
}
.bouton-connexion {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.pass-oublie {
  margin-top: 10px;
  padding: 6px;
  @include typo-small;
  color: $couleur-texte-fonce;
}
</style>
