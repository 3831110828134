<template>
<div>
  <embed type='application/pdf' class="facturePDF" :src="'assets/php/'+fichierPdf" width=800/>
  <div class="frame-bouton">
      <div class="btn btn-primary" @click="enregistrerPdf">Télécharger</div>
      <div class="btn btn-primary" @click="sendMail">Envoyer la demande</div>
      <div class="btn btn-danger" @click="deleteAndQuit">Annuler</div>
  </div>
</div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'


export default {
    name: 'composantVisualisationFacture',
    props: {
        fichierPdf: { default: undefined },
        numeroFacture: { default: undefined },
        contributeur: { default: undefined },
        precompteFacture: { default: undefined },
        montantFacture: { default: undefined }
    },
    methods: {
        ...mapActions(['phpPost', 'modaleAlert']),
        ...mapMutations(['definirVisibiliteModale']),
        enregistrerPdf() {
            const anchor = document.createElement('a')
            anchor.setAttribute('href', 'assets/php/' + this.fichierPdf)
            anchor.setAttribute('download', 'facture.pdf')
            anchor.click()
        },
        sendMail(){
            // this.modaleAlert({
            //     message: 'Envoi en cours...',
            //     displayLoader: true
            // })
            this.phpPost({
                phpFile: 'assets/php/envoyerFacture.php',
                displayLoader: true, 
                closeAllAfter: true,
                sendObject: {
                    prenom_contributeur: this.contributeur.prenom_contributeur,
                    nom_contributeur: this.contributeur.nom_contributeur,
                    email_contributeur: this.contributeur.email_contributeur,
                    nomFichier: this.fichierPdf,
                    numeroFacture: this.numeroFacture,
                    precompteFacture: this.precompteFacture,
                    idContributeur: this.contributeur.id_contributeur,
                    montantFacture: this.montantFacture
                }
                // ,
                // callBack: ((response)=>{
                //     this.definirVisibiliteModale(false)
                // }).bind(this)
            })
        },
        deleteAndQuit() {
            this.phpPost({
                phpFile: 'assets/php/supprimerFichierTmp.php',
                sendObject: { tmpFileToDelete: this.fichierPdf },
                disableThumbnailForAll: true,
            })
            
            this.definirVisibiliteModale(false)
        }
    }
}
</script>

 <style lang="scss" scoped>
    .frame-bouton {
        margin-top: 20px;
        width: auto;
        display: flex;
        justify-content: space-around;
    }
    .facturePDF {
        height: 68vh;
    }
 </style>