<template>
  <div v-if="user.adminAccessGranted" class="page-ajout-contributeur">
    <div class="frame-titre">
      <p class="label-titre">Ajout contributeur</p>
    </div>
    <div class="frame-choix-mode-invitation">
      <div class="choix-mode-invitation">
        <input
          type="radio"
          :value="false"
          name="radio-type-ajout"
          v-model="saisieManuelle"
          id="invitation"
          class="radio-invitation"
        />
        <label for="invitation">Envoi d'invitation : </label>
      </div>
      <div
        class="btn btn-primary bouton-invitation"
        @click="inviterNouveauMembre"
      >
        Inviter Nouveau Membre
      </div>
    </div>
    <div class="frame-choix-mode-invitation">
      <div class="choix-mode-invitation">
        <input
          type="radio"
          :value="true"
          name="radio-type-ajout"
          v-model="saisieManuelle"
          id="saisie-manuelle"
          class="radio-invitation"
        />
        <label for="saisie-manuelle">Saisie manuelle :</label>
      </div>
    </div>
    <div v-if="saisieManuelle" class="frame-infos">
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contributeur</p>
          <div class="frame-nom">
            <p class="label-two">Nom*</p>
            <input
              v-model="contributeur.nom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Prenom*</p>
            <input
              v-model="contributeur.prenom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Nom d'artiste</p>
            <input
              v-model="contributeur.nom_artiste_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Date naissance</p>
            <input
              type="date"
              v-model="contributeur.date_naissance_contributeur"
              class="nom-contributeur date-naissance"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Numero Secu</p>
            <input
              v-model="contributeur.numero_secu_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-siret">
            <p class="label-two">Siret</p>
            <input
              v-model="contributeur.siret_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Adresse</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Libellé</p>
            <input
              v-model="contributeur.adresse_contributeur.libelle_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Infos comp.</p>
            <input
              v-model="
                contributeur.adresse_contributeur.infos_complementaires_adresse
              "
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Code postal</p>
            <input
              v-model="contributeur.adresse_contributeur.code_postal_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Ville</p>
            <input
              v-model="contributeur.adresse_contributeur.ville_adresse"
              class="nom-contributeur"
            />
          </div>
        </div>
      </div>
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contacts</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Site web</p>
            <input
              v-model="contributeur.site_web_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Email</p>
            <input
              v-model="contributeur.email_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Tel</p>
            <input
              v-model="contributeur.tel_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Connexion</p>
          <div class="frame-libelle-adresse">
            <div class="frame-label-identifiant">
              <p class="label-three">
                Identifiant connexion*
              </p>
            </div>
            <div class="frame-input-identifiant">
              <input
                @keyup="checkId"
                @blur="checkId"
                v-model="contributeur.identifiant"
                class="identifiant-contributeur"
                autocomplete="off"
              />
              <p
                class="validation infobulle"
                bulle-content="Disponibilité de l'identifiant"
              >
                {{ disponibiliteId }}
              </p>
              <p
                class="auto-fill-id infobulle"
                bulle-content="Remplir l'identifiant avec le nom saisi"
                @click="remplirIdAvecNom"
              >
                ✏️
              </p>
            </div>
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Création de mot de passe</p>
            <div class="frame-reinitialiser">
              <select
                class="frame-password-creation"
                name="passwordCreationMethod"
                id="passwordCreationMethod"
                v-model="methodeCreationPassword"
              >
                <option value="auto">Automatique</option>
                <option value="manual">Saisie manuelle</option>
              </select>
            </div>
          </div>
          <div
            v-if="methodeCreationPassword == 'manual'"
            class="frame-libelle-adresse"
          >
            <p class="label-two">Mot de passe*</p>
            <div class="frame-reinitialiser">
              <input
                type="password"
                v-model="password"
                class="password-contributeur"
                autocomplete="off"
              />
              <p
                class="label-three validation infobulle"
                :bulle-content="infosPassword"
              >
                {{ checkPassword }}
              </p>
              <p class="infobulle" :bulle-content="messageValidationPassword">
                ❓
              </p>
            </div>
          </div>
          <div v-if="!emailValidation" class="frame-libelle-adresse">
            <p class="label-two" v-html="messageMailAbsent"></p>
          </div>
          <div
            v-else-if="methodeCreationPassword == 'auto'"
            class="frame-libelle-adresse"
          >
            <p class="label-two">
              L'utilisateur recevra un mot de passe aléatoire sur son adresse
              mail
            </p>
          </div>
          <div v-else class="frame-libelle-adresse">
            <p class="label-two">
              L'utilisateur recevra le mot de passe sur son adresse mail
            </p>
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Accreditation*</p>
            <select
              name="accreditation-contributeur"
              v-model="contributeur.id_acces"
            >
              <option value="-">Niveau d'acces</option>
              <option
                v-for="acces in listeAcces"
                :key="acces.id"
                :value="acces.id_acces"
                >{{ acces.libelle_acces }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="saisieManuelle" class="frame-boutons">
      <div class="frame-ajouter" @click="ajouterContributeur">
        <p class="bouton-ajouter-button">Ajouter contributeur</p>
      </div>
      <!-- <div class="frame-annuler">
        <p class="bouton-ajouter-button" @click="$router.push('admin')">Annuler</p>
      </div> -->
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "PageAjoutContributeur",
  data: () => {
    return {
      saisieManuelle: false,
      contributeur: undefined,
      contributeurAvantModification: undefined,
      listeAcces: [],
      contributeurVide: {
        nom_contributeur: "",
        prenom_contributeur: "",
        date_naissance_contributeur: "",
        email_contributeur: "",
        tel_contributeur: "",
        adresse_contributeur: {
          ville_adresse: "",
          code_postal_adresse: "",
          libelle_adresse: "",
          infos_complementaires_adresse: "",
        },
        identifiant: "",
        id_acces: "-",
      },
      password: "",
      disponibiliteId: "❌",
      methodeCreationPassword: "auto",
      messageValidationPassword:
        "Le mot de passe doit contenir au moins une majuscule | une minuscule | un chiffre | un caractère spécial (!@#$&*)",
    };
  },
  computed: {
    ...mapState(["user", "userConnected"]),
    messageMailAbsent() {
      if (this.methodeCreationPassword == "manual")
        return "⚠️ Un email valide est conseillé pour la transmission du mot de passe. Si aucun email n'est renseigné, vous devrez envoyer vous-même le mot de passe choisi au contributeur";
      else
        return "⚠️ Un Email valide est requis pour la transmission du mot de passe";
    },
    infosPassword() {
      return this.passwordValidation
        ? "Mot de passe suffisamment robuste"
        : this.messageValidationPassword;
    },
    emailValidation() {
      return this.contributeur.email_contributeur.match(
        /^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i
      );
    },
    idValidation() {
      return (
        this.contributeur.identifiant.match(/\w{3,}/) &&
        !this.contributeur.identifiant.match(/^$| +/) &&
        this.contributeur.identifiant.match(/^[A-Za-z0-9\-éè]+$/)
      );
    },
    checkPassword() {
      return this.passwordValidation ? "✔️" : "❌";
    },
    passwordValidation() {
      return this.password.match(
        /^(?=.*[A-Z])(?=.*[!@#$*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/
      );
    },
  },
  methods: {
    ...mapActions([
      "phpPost",
      "modaleAlert",
      "modaleComposant",
      "initDetectionModification",
    ]),
    ...mapMutations(["masquerModale", "resetModificationPresente"]),
    inviterNouveauMembre() {
      this.modaleComposant({
        componentName: "ComposantInvitationNouveauMembre",
        allowQuitOnClick: true,
        props: {
          listeAcces: this.listeAcces,
        },
      });
    },
    remplirIdAvecNom() {
      this.contributeur.identifiant = this.contributeur.nom_contributeur
        .toUpperCase()
        .noAccent();
      this.checkId();
    },
    ajouterContributeur() {
      if (
        this.contributeur.nom_contributeur == "" ||
        this.contributeur.prenom_contributeur == ""
      )
        return this.modaleAlert(
          "Erreur - les noms et prénoms doivent être remplis"
        );
      if (!this.emailValidation && this.methodeCreationPassword == "auto")
        return this.modaleAlert("Erreur - l'adresse mail est invalide");
      if (!this.idValidation)
        return this.modaleAlert("Erreur - l'identifiant est invalide");
      if (this.methodeCreationPassword == "manual") {
        if (!this.passwordValidation)
          return this.modaleAlert(this.messageValidationPassword);
      }
      if (!this.emailValidation) {
        if (
          !confirm(
            "Vous n'avez pas renseginé d'adresse mail - le mot de passe choisi ne sera pas transmis automatiquement au contributeur.\nÊtes-vous sûr.e de vouloir continuer ?"
          )
        ) {
          return;
        }
      }
      if (this.contributeur.id_acces == "-")
        return this.modaleAlert(
          "Vous devez choisir un niveau d'accréditation pour le contributeur"
        );
      this.contributeur.nom_contributeur = this.contributeur.nom_contributeur.toUpperCase();
      if (this.methodeCreationPassword == "auto")
        this.contributeur.password = "auto";
      else this.contributeur.password = this.password;
      this.modaleAlert({
        message: "Patientez...",
        displayLoader: true,
      });
      this.phpPost({
        phpFile: "assets/php/ajouterContributeur.php",
        sendObject: this.contributeur,
        dispatchAtTheEnd: "miseAJourContributeurs",
        callBack: (response) => {
          if (response.reqSuccess) {
            this.contributeur = JSON.parse(
              JSON.stringify(this.contributeurVide)
            );
            this.resetModificationPresente();
          }
          this.masquerModale();
        },
      });
    },
    checkId() {
      this.contributeur.identifiant = this.contributeur.identifiant
        .toUpperCase()
        .noAccent();

      if (!this.idValidation) return (this.disponibiliteId = "❌");
      this.disponibiliteId = "🕒";
      this.contributeur.identifiant = this.contributeur.identifiant.trim();
      if (!this.idValidation) {
        this.disponibiliteId = "❌";
      } else {
        this.phpPost({
          phpFile: "assets/php/verifierIdentifiant.php",
          sendObject: { nouvelIdentifiant: this.contributeur.identifiant },
          disableThumbnailForAll: true,
          callBack: (response) => {
            if (response.idDisponible) this.disponibiliteId = "✔️";
            else this.disponibiliteId = "❌";
          },
        });
      }
    },
  },
  created: function() {
    this.contributeur = JSON.parse(JSON.stringify(this.contributeurVide));
  },
  mounted: function() {
    this.resetModificationPresente();
    this.$nextTick(() => {
      this.initDetectionModification();
    });
    this.phpPost({
      phpFile: "assets/php/listeAcces.php",
      disableThumbnail: true,
      callBack: (response) => {
        if (response.reqSuccess) {
          this.listeAcces = response.reqList;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.page-ajout-contributeur {
  width: 1100px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $contour-page;
}
.frame-titre {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-titre {
  color: $couleur-texte-fonce;
  @include typo-titre;
}
.frame-infos {
  margin-bottom: 58.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.frame-choix-mode-invitation {
  color: $couleur-texte-fonce;
  @include typo-medium;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.choix-mode-invitation {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.radio-invitation {
  margin-right: 10px;
}

.bouton-invitation {
  @include typo-medium;
}

.frame-partie1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 46px;
  }
}
.frame-denomination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 19px;
  }
}
.label {
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
  @include typo-large;
}
.frame-nom {
  background-color: $couleur-fond-composant-lieu;
  margin-bottom: 12px;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.label-two {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.date-naissance {
  width: 200px;
}

.nom-contributeur {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.frame-siret {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 12px 25px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.frame-libelle-adresse {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.frame-label-identifiant {
  display: flex;
  align-items: center;
}
.frame-input-identifiant {
  display: flex;
  align-items: center;
}

.label-three {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.validation {
  margin-left: 10px;
  &:hover {
    cursor: help;
  }
}

.auto-fill-id {
  font-size: 0.9em;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}

.frame-reinitialiser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;
}
.frame-password-creation {
  width: 200px;
}

.frame-boutons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
}

@mixin clickable($couleur) {
  &:hover {
    cursor: pointer;
    background-color: darken($couleur, 10);
  }
}

.identifiant-contributeur {
  width: 150px;
}

.frame-ajouter {
  @include clickable($neutral-btn);
  width: 200px;

  background-color: $neutral-btn;
  margin-right: 67.5px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.bouton-ajouter-button {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}

.frame-annuler {
  @include clickable($danger-btn);
  background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  align-self: stretch;
}
</style>
