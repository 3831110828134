<template>
  <div class="composant-liste-contenu-pack">
    <p class="titre">Contenu du pack</p>
    <p class="titre">{{ titrePack }}</p>
    <div class="separateur1"></div>
    <div v-for="(numero) of listeNumeros" :key="numero.id_numero" class="composant-numero-pack">
      <div class="numero-numero-container">
        <p class="numero-numero">{{ numero.numero_numero }}</p>
      </div>
      <div class="titre-numero-pack-container">
        <p class="titre-numero-pack">{{ numero.titre_numero }}</p>
      </div>
      <!-- <img
        alt=""
        class="icone-supprimer"
        @click="retirerNumeroListe(index)"
        src="https://static.overlay-tech.com/assets/ce5393fd-6f97-4ada-971e-8bdcf1dee119.png"
      /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ComposantListeContenuPack",
  props: {
    listeNumeros: { default: [] },
    titrePack: { default: "Inconnu"}
  }, 
  // computed: { 
  //   numeroNumero() { 
  //     return "72"
  //   },
  //   titreNumero() {
  //     return "Coquilles et controverses"
  //   },
  //   titrePack() {
  //     return "C20 - Coffret 2020"
  //   }

  // }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-liste-contenu-pack {
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 28px;
  padding: 19.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 10px;
  @include typo-large;
}
.separateur1 {
  height: 1px;
  background-color: $silver;
  margin-bottom: 10px;
  align-self: stretch;
}
.composant-numero-pack {
  background-color: $fond-page;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid rgba(88, 92, 98, 1);
  margin-bottom: 2px;
}
.numero-numero-container {
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  align-self: stretch;
  // border: 1px solid rgba(88, 92, 98, 1);
}
.numero-numero {
  color: $noir;
  flex: 1;
  text-align: center;
  @include typo-medium;
}
.titre-numero-pack-container {
  @include typo-medium;
  margin-right: 10px;
  border-radius: 10px;
  padding: 0 0 0 4px;
  display: flex;
  align-items: center;
  width: 350px;
  align-self: stretch;
  justify-content: left;
  // border: 1px solid rgba(88, 92, 98, 1);
  // text-align: left;
}
// .icone-supprimer {
//   @extend .element-clickable;
//   width: 35px;
//   height: 34px;
// }
</style>