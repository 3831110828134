<template>
    <div 
        class="option"
        :class="disabled && disabledInfo != undefined ? 'infobulle':''" 
        :bulle-content="disabledInfo"
    >
        <input 
            type="checkbox" 
            v-model="privateChecked" 
            :id="'checkbox-' + labelComputed + '-' + idSalt()" 
            @change="update"
            :disabled="disabled"
        >
        <label :for="'checkbox-' + labelComputed + '-' + idSalt()">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'ComposantCheckbox',
    model: {
        prop: 'checked',
        event: 'update'
    },
    data: ()=>{
        return { 
            salt: undefined
        }
    },
    props: {
        disabledInfo: { default: undefined }, // Texte à afficher lorsque désactivé et survolé par la souris
        disabled: { default: false }, // true = desactive la checkbox
        checked: { default: false }, // statut - le v-model fait référence à cette prop
        label: { default: 'Option'} // label de la checkbox
    },
    computed: {
        labelComputed() {
            return this.label.replace(/ /g, '-')
        }
    },
    methods: {
        update(){
            this.$emit('update', this.privateChecked);
        },
        idSalt() {
            if (this.salt == undefined) this.salt = Math.round(Math.random()*1000000)
            return this.salt
        }
    },
    created: function () {
        this.privateChecked = this.checked
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.option {
  & label {
    margin-top: auto;
    margin-bottom: auto;
  }
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
//   margin-bottom: 20px;
}

input[type="checkbox"] {
  margin-right: 15px;
}
</style>