var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user.adminAccessGranted)?_c('div',{staticClass:"corpsDePage"},[_c('section',{staticClass:"breadcrumb"},[_c('h4',[_vm._v("Modifier un comptage")]),_c('div',{staticClass:"sousSection"},[_c('h5',{staticClass:"titreSousSection"},[_vm._v("Numero à compter")]),(
            _vm.comptageAModifier != undefined &&
              _vm.comptageAModifier.id_festival != undefined
          )?_c('div',{staticClass:"message-cloture-festival"},[_c('span',{staticClass:"icone-info"},[_vm._v("🛈")]),_vm._v(" Ce comptage a été ajouté automatiquement suite à la clôture du festival \""+_vm._s(_vm.infosFestival.nom_festival)+"\". Il est déconseillé de le modifier / supprimer manuellement ")]):_vm._e(),_c('div',{attrs:{"id":"ajoutComptageSelectionNumeroContainer"}},[_c('div',{attrs:{"id":"#ajoutComptageSelectionNumeroPartieGauche"}},[_c('div',{staticClass:"ligneFormulaire"},[_c('span',{staticClass:"labelFormulaire"},[_vm._v("Exemplaire concerné")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.idNumeroSelectionne),expression:"idNumeroSelectionne"}],attrs:{"type":"select","id":"selectionNumeroACompter"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.idNumeroSelectionne=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.definirNumeroACompter()}]}},[_c('option',{attrs:{"value":"-"}},[_vm._v(" -- ")]),_vm._l((_vm.listeNumeros),function(numero){return _c('option',{key:numero.id_numero,domProps:{"value":numero.id_numero}},[_vm._v(_vm._s(numero.numero_numero + " - " + numero.titre_numero))])})],2)]),(_vm.numeroACompter != undefined)?_c('div',{staticClass:"ligneFormulaire"},[_c('span',{staticClass:"labelFormulaire"},[_vm._v("Date comptage : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.idComptageSelectionne),expression:"idComptageSelectionne"}],attrs:{"type":"select","id":"selectionComptageAModifier"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.idComptageSelectionne=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},() => {
                    this.$router.push(
                      '/modifcomptage-' +
                        this.idNumeroSelectionne +
                        '/' +
                        this.idComptageSelectionne
                    );
                  }]}},[(_vm.listeComptages == undefined)?_c('option',{attrs:{"value":"noCpt"}},[_vm._v("Aucun Comptage")]):_vm._e(),_vm._l((_vm.listeComptages),function(comptage){return _c('option',{key:comptage.id_comptage,domProps:{"value":comptage.id_comptage}},[_vm._v(_vm._s(_vm.dateFrancaise(comptage.date_comptage)))])})],2)]):_vm._e(),(
                _vm.numeroACompter != undefined &&
                  !_vm.numeroACompter.liste_comptages[0].aucunComptage
              )?_c('div',[_c('div',_vm._l((_vm.listeStockages),function(lieu,i){return _c('div',{key:i,staticClass:"ligneFormulaire"},[_c('div',{staticClass:"labelFormulaire"},[_vm._v(_vm._s(lieu.nom_lieu))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.listeStockages[i].quantite_stockage),expression:"listeStockages[i].quantite_stockage"}],staticClass:"inputComptage",attrs:{"type":"text"},domProps:{"value":(_vm.listeStockages[i].quantite_stockage)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.listeStockages[i], "quantite_stockage", $event.target.value)},_vm.calculTotalComptage]}}),(_vm.listeStockagesValeursOriginales != undefined)?_c('div',{staticClass:"valeurs-avant-modif"},[_vm._v(" (Avant modif : "+_vm._s(_vm.listeStockagesValeursOriginales[i].quantite_stockage)+") ")]):_vm._e()])}),0),(
                  _vm.comptageAModifier != undefined &&
                    _vm.numeroACompter != undefined
                )?_c('div',{staticClass:"ligneFormulaire totalComptage"},[_c('div',{staticClass:"labelFormulaire"},[_vm._v("Total")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.comptageAModifier.quantite_comptage),expression:"comptageAModifier.quantite_comptage"}],staticClass:"inputComptage",attrs:{"disabled":"","type":"text"},domProps:{"value":(_vm.comptageAModifier.quantite_comptage)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.comptageAModifier, "quantite_comptage", $event.target.value)}}}),_c('div',[_vm._v(" (Avant modif : "+_vm._s(_vm.quantiteComptageValeurOriginale)+") ")])]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"dflex centered space-around",staticStyle:{"width":"400px","margin-top":"20px"}},[_c('div',{staticClass:"btn btn-primary",attrs:{"id":"btnValiderModifComptage"},on:{"click":_vm.validerModificationsComptage}},[_vm._v(" Valider Modifications ")]),_c('div',{staticClass:"btn btn-danger",attrs:{"id":"btnSupprimerComptage"},on:{"click":_vm.supprimerComptage}},[_vm._v(" Supprimer Comptage ")])])])])]):_c('div',{staticClass:"message-interdiction"},[_c('h3',[_vm._v("403 - Non autorisé")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }