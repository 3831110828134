<template>
  <div class="composant-definir-numero-festival">
    <p class="titre">
      Definir un stock pour tous les numéros
    </p>
    <div class="separateur1"></div>
    <div class="saisie-stock-container">
      <ComposantSaisieStock :labelType="true" class="composant-saisie-stock" />
      <ComposantSaisieStock
        :displayLock="false"
        typeSaisie="Stock"
        class="composant-saisie-stock"
        v-model="nouveauStock"
      />
    </div>
    <div class="frame-boutons">
      <div class="frame-definir">
        <p class="bouton-ajouter" @click="validerSaisie">
          Definir
        </p>
      </div>
      <div class="frame-annuler">
        <p class="bouton-ajouter" @click="masquerModale">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// import { ComposantSaisieStock } from "./composantSaisieStock";

export default {
  name: "ComposantDefinirNumeroFestival",
  components: {
    ComposantSaisieStock: () => import("./composantSaisieStock"),
  },
  props: {
    modifierStockGlobal: {
      type: Function,
      default: () => {
        console.error(
          "ComposantDefinirNouveauNumero : Aucune fonction transmise pour définir le stock global"
        );
      },
    },
  },
  data: () => {
    return {
      nouveauStock: {
        cartons_unite_festival: 0,
        libres_unite_festival: 0,
        ramettes_unite_festival: 0,
      },
    };
  },
  methods: {
    ...mapActions(["modaleAlert"]),
    ...mapMutations(["masquerModale"]),
    validerSaisie() {
      this.modaleAlert({
        message: "Merci de patienter...",
        displayLoader: true,
      });
      this.modifierStockGlobal(this.nouveauStock);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-definir-numero-festival {
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.saisie-stock-container {
  // border: 1px solid #000;
  padding-right: 20px;
}
.frame-ligne-modale {
  background-color: $couleur-fond-menu-navigation;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
  width: 244px;
  margin-top: 20px;
}
.frame-definir {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.bouton-ajouter {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
.frame-annuler {
  background-color: $danger-btn;
  @include btnHover($danger-btn);
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>
