<template>
  <div
    v-if="!modeEdition"
    v-html="contenuMisEnForme"
    class="content"
    :class="dynamicClass"
    @dblclick="makeItEditable('dblClick')"
    @click="makeItEditable('singleClick')"
  ></div>
  <select
    v-else-if="format == 'select'"
    ref="input"
    class="content"
    name="labelList"
    id="labelList"
    @change="synchroVmodel(true)"
    v-model="privateContent"
    @blur="synchroVmodel(saveOnBlur)"
  >
    <option
      v-for="(option, index) of selectList"
      :value="chooseListValue(index)"
      :key="index"
      >{{ option }}</option
    >
  </select>
  <textarea
    v-else-if="format == 'textarea'"
    ref="input"
    class="content"
    v-model="privateContent"
    @keypress.enter.exact="synchroVmodel(true)"
    @keydown.esc="synchroVmodel(false)"
    @blur="synchroVmodel(saveOnBlur)"
    style="width: 100%;"
    :rows="rowsNumber"
  >
  </textarea>
  <input
    v-else
    ref="input"
    class="content"
    :type="typeComputed"
    :maxlength="length"
    v-model="privateContent"
    @keydown.esc="synchroVmodel(false)"
    @keypress.enter="synchroVmodel(true)"
    @blur="synchroVmodel(saveOnBlur)"
  />
</template>

<script>
/**
 *  Champ de texte editable par double clic.
 *  Options :
 *      - editOnSingleClick: true / false permet le passage en mode edition avec un simple click
 *      - locked : true / false pour empêcher le basculement en mode edition
 *      - length = longueur maximal de l'input
 *      - align = left, center, right - default = left
 *      - format :
 *          - default : si rien n'est précisé, texte
 *          - 'date' : vérifie que le format est bien de type date et affiche un champ date en mode edition
 *          - 'select' :
 *              - affiche un input de type select
 *              - y met les éléments passés par la prop 'selectList'
 *              - la value renvoyé lors de l'evenement 'change' vaut l'élément de la liste (et non son index)
 *          - textarea :
 *              - affiche un textarea
 *              - par defaut : 5 lignes, paramétrable par rows=n
 *              - si rows n'est pas défini, la taille du textarea s'adapte à la taille du texte
 *
 *
 */
import { testDate } from "../js/fonctionsGlobales";
export default {
  name: "LabelEditable",
  model: {
    prop: "content",
    event: "change",
  },
  data: function() {
    return {
      modeEdition: false,
      privateContent: this.content,
    };
  },
  props: {
    align: { default: "left" },
    content: { default: "" },
    locked: { default: false },
    format: { default: "" },
    selectList: { default: "" },
    saveOnBlur: { default: true },
    rows: { default: undefined },
    length: { default: "" },
    postLabel: { default: "" },
    preLabel: { default: "" },
    editOnSingleClick: { default: true },
    selectListValue: { default: "content" },
    maxLength: { default: "15" },
  },
  computed: {
    dynamicClass() {
      // if (this.align=="center") return "center-content";
      return this.align + "-content";
    },
    typeComputed() {
      if (this.format == "date") return "date";
      else return "text";
    },
    contenuMisEnForme() {
      let output = "";
      if (this.format != "date") {
        if (isNaN(this.privateContent))
          output =
            this.privateContent != undefined
              ? this.privateContent.replace(/\n/g, "<br>").replace("\r", "<br>")
              : undefined;
        else {
          if (this.format == "select") {
            if (this.selectListValue == "index")
              output = this.selectList[this.privateContent];
            else output = this.privateContent;
          } else output = this.privateContent;
        }
      } else if (isNaN(this.privateContent)) {
        let date = this.privateContent
          .split("-")
          .reverse()
          .join("/");
        if (!testDate(date)) output = "--";
        else output = date;
      }
      return this.preLabel + output + this.postLabel;
    },
    rowsNumber() {
      if (this.rows == undefined) {
        let countLines = this.privateContent.match(/\n/g);
        if (countLines != undefined) return countLines.length + 2;
        else return 5;
      } else return this.rows;
    },
  },
  methods: {
    chooseListValue(index) {
      if (this.selectListValue == "content") return this.selectList[index];
      else return index;
    },
    makeItEditable(eventType) {
      if (eventType == "singleClick") this.$emit("click");

      if (
        (eventType == "singleClick" && this.editOnSingleClick) ||
        eventType == "dblClick"
      ) {
        this.modeEdition = true && !this.locked;
        this.$nextTick(() => {
          if (this.modeEdition) this.$refs.input.focus();
        });
      }
    },
    synchroVmodel(applyChanges) {
      if (this.privateContent != this.content && applyChanges) {
        this.$emit("change", this.privateContent);
      } else this.privateContent = this.content;
      this.modeEdition = false;
    },
  },
  watch: {
    content() {
      this.privateContent = this.content;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  // white-space: nowrap;
  // margin-left: auto;
}
.center-content {
  justify-content: center;
}
.left-content {
  justify-content: flex-start;
}
.right-content {
  justify-content: flex-end;
}
</style>
