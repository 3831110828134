 <template>
  <div class="composant-nouveau-pass">
    <p class="titre">Nouveau mot de passe</p>
    <div class="separateur1"></div>
    <div class="frame-numero-facture">
      <p class="label-nouveau-pass">
        Mot de passe actuel :
      </p>
      <input 
        type="password"
        class="frame-nouveau-pass" 
        placeholder="****"
        v-model="currentPassword"
      />
      <div class="frame-check"></div>
    </div>
    <div class="frame-numero-facture">
      <p class="label-nouveau-pass">
        Nouveau mot de passe :
      </p>
      <input 
        type="password"
        class="frame-nouveau-pass" 
        placeholder="****"
        v-model="password"
      />
      <div class='infobulle' bulle-content="Le mot de passe doit contenir au moins 1 chiffre, 1 majuscule, 1 minuscule, 1 caractère spécial : ! @ # $ *">
        {{ checkPassword }}
      </div>
    </div>
    <div class="frame-numero-facture">
      <p class="label-nouveau-pass">Confirmation :</p>
      <input 
        type="password"
        class="confirmation-pass" 
        placeholder="****" 
        v-model="passwordConfirmation"
      />
      <div class='frame-check infobulle' :bulle-content="passwordIdentiques ? 'Les deux mots de passe sont identiques' : 'Les deux mots de passe sont différent'">
        {{ checkPasswordIdentiques }}
      </div>
    </div>
    <div class="separateur1"></div>
    <div class="frame-boutons">
      <div class="frame-ajouter">
        <p class="bouton-ajouter-button" @click="modifierPass">Confirmer</p>
      </div>
      <div class="frame-annuler">
        <p class="bouton-ajouter-button" @click="masquerModale">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  name: "ComposantNouveauPass", 
  props: {
    idContributeur:  { default : '-' }
  },
  data: () => {
      return {
        currentPassword: '',
        passwordConfirmation: '',
        password: ''
      }
  },
  computed: {
    checkPassword() {
        return this.passwordValidation ? "✔️" : "❌"
    },
    passwordValidation() {
        return this.password.match(/^(?=.*[A-Z])(?=.*[!@#$*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/)
    },
    passwordIdentiques() {
      return (this.passwordConfirmation == this.password) && this.passwordValidation
    },
    checkPasswordIdentiques() {
        return this.passwordIdentiques ? "✔️" : "❌"
    }

  },
  methods: {
    ...mapMutations(['masquerModale']),
    ...mapActions(['modaleAlert', 'phpPost']),
    modifierPass() {
      if (this.idContributeur == '-') return this.modaleAlert('Contributeur inconnu')
      if (this.currentPassword == '') return this.modaleAlert('Le mot de passe actuel doit être saisi')
      if (!this.passwordValidation || !this.checkPasswordIdentiques) this.modaleAlert("Erreur de saisie - le mot de passe ne remplit pas les conditions (passer la souris sur la croix rouge à côté du mot de passe) ou les deux mots de passe ne sont pas identiques")
      this.phpPost({
        phpFile: 'assets/php/modifierPass.php',
        sendObject: {
          newPassword: this.password,
          currentPassword: this.currentPassword,
          id_contributeur: this.idContributeur
        },
        displayLoader: "Modification en cours...",
        callBack: (response) => {
          if (response.reqSuccess) {
            this.masquerModale()
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-nouveau-pass {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-check{
  width: 30px;
}
.frame-numero-facture {
  width: 100%;
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-nouveau-pass {
  width: 250px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}
.frame-nouveau-pass {
  width: 150px;
  padding: 2px 0;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.confirmation-pass {
  width: 150px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-boutons {
  display: flex;
  align-items: flex-start;
  height: 37px;
}
.frame-ajouter {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width:  110px;
  align-self: stretch;
}
.bouton-ajouter-button {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
.frame-annuler {
  background-color: $danger-btn;
  @include btnHover($danger-btn);

  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  align-self: stretch;
}
</style>