<template>
  <div class="page-espace-personnel">
    <div class="frame-titre">
        <!-- <p class="label-titre">Espace personnel</p> -->
        <p class="label-nom-contributeur">
            {{ nomCompletContributeur }}
        </p>
    </div>
    <div class="frame-infos">
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contributeur</p>
          <div class="frame-nom">
            <p class="label-two">Nom</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.nom_contributeur" class="label-editable" />
          </div>
          <div class="frame-nom">
            <p class="label-two">Prenom</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.prenom_contributeur" class="label-editable" />
          </div>
          <div class="frame-nom">
            <p class="label-two">Nom d'artiste</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.nom_artiste_contributeur" class="label-editable" />
          </div>
          <div class="frame-nom">
            <p class="label-two">Date naissance</p>
            <LabelEditable format="date" v-on:change="modifierContributeur" v-model="contributeur.date_naissance_contributeur" class="label-editable" />
          </div>
          <div class="frame-nom">
            <p class="label-two">Numero Secu</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.numero_secu_contributeur" length=15 class="label-editable" />
          </div>
          <div class="frame-nom">
            <p class="label-two">Siret</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.siret_contributeur" class="label-editable" />
          </div>
        </div>
        <div v-if="contributeur.adresse_contributeur != undefined" class="frame-denomination">
          <p class="label" >Adresse</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Libellé</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.adresse_contributeur.libelle_adresse" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Infos comp.</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.adresse_contributeur.infos_complementaires_adresse" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Code postal</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.adresse_contributeur.code_postal_adresse" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Ville</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.adresse_contributeur.ville_adresse" class="label-editable" />
          </div>
        </div>
      </div>
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contacts</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Site web</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.site_web_contributeur" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Email</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.email_contributeur" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Tel</p>
            <LabelEditable v-on:change="modifierContributeur" v-model="contributeur.tel_contributeur" class="label-editable" />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Admin espace personnel</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Identifiant connexion</p>
            <!-- <LabelEditable v-model="identifiantConnexion" class="label-editable" /> -->
            <LabelEditable v-on:change="modifierIdentifiant" v-model="contributeur.identifiant" class="label-editable" />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Mot de passe</p>
            <div class="frame-reinitialiser element-clickable" @click="reinitPassword">
              <p class="label-two">Modifier</p>
            </div>
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Niveau d'accès</p>
            <!-- <LabelEditable v-model="identifiantConnexion" class="label-editable" /> -->
            <div class="label-editable"> {{ niveauAcces }} </div>
          </div>          
          <div class="frame-libelle-adresse" v-if='user.adminAccessGranted'>
            <p class="label-two">Révocation droit admin <span class='infobulle info-revocation' bulle-content="Cliquer sur 'Révoquer' permet d'annuler ses droits administrateur - vous n'aurez alors plus accès aux fonctions d'administration">ℹ️</span></p>
            <div class="frame-reinitialiser element-clickable" @click="reinitAcces">
              <p class="label-two">Révoquer</p>
            </div>
          </div>
          <div class="frame-libelle-adresse" v-else>
            <p class="label-two">Demander droits admin <span class='infobulle info-revocation' bulle-content="Cliquer sur 'Demander' permet d'envoyer une demande automatique pour obtenir les droits administrateur">ℹ️</span></p>
            <div class="frame-reinitialiser element-clickable" @click="demanderAccesAdmin">
              <p class="label-two">Demander</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: "PageEspacePersonnel",
  data: function () {
    return { 
        contributeurAvantModification: '',
        listeAcces: [],
        contributeur: undefined
    }
  },
  methods: {
        ...mapActions(['phpPost', 'modaleAlert', 'modaleOuiNon', 'modaleComposant']),
        ...mapMutations(['masquerModale','afficherModale']),
        demanderAccesAdmin(){
          this.modaleOuiNon({
            message: "Êtes-vous sûr.e de vouloir envoyer une demande de modification de droits d'accès ?",
            callBackYes: () => {
              this.modaleAlert({
                message: 'Envoi en cours...',
                displayLoader: true
              })
              this.phpPost({
                phpFile: 'assets/php/demanderAccesAdmin.php',
                sendObject: { idContributeur: this.user.id_contributeur },
                callBack: () => {
                  this.masquerModale()
                }
              })
            }
          })
        },
        reinitAcces() {
          this.modaleOuiNon({
            message: "Attention ! Cette action va annuler vos droits administrateurs - vous n'aurez alors plus accès aux fonctionnalisté d'adminitration.<br>Etes-vous sûr.e de vouloir continuer ?",
            captionYes: "Réinitialiser",
            captionNo: "Annuler",
            callBackYes: () => {
              this.phpPost({
                phpFile : 'assets/php/reInitDroitsAcces.php',
                sendObject: { idContributeur: this.user.id_contributeur },
                dispatchAtTheEnd: 'miseAJourContributeurs'
              })
            }
          })
        },
        reinitPassword() {
          this.modaleComposant({
            componentName: "ComposantNouveauPass",
            allowQuitOnClick: true,
            props: {
              idContributeur: this.contributeur.id_contributeur
            },
          })
          this.afficherModale()
        },
        modifierIdentifiant(nouvelIdentifiant) {
             this.phpPost({
                phpFile : 'assets/php/modifierIdentifiant.php',
                sendObject: {
                    identifiant_pass: nouvelIdentifiant,
                    id_contributeur: this.user.id_contributeur
                },
                dispatchAtTheEnd: 'miseAJourContributeurs',
                callBack: (response) => {
                    if (!response.reqSuccess) this.contributeur = JSON.parse(JSON.stringify(this.contributeurAvantModification))
                    else this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeur))
                }
            })
        },
        modifierContributeur() {
            this.phpPost({
                phpFile: 'assets/php/modifierContributeur.php',
                sendObject: this.contributeur,
                dispatchAtTheEnd: 'miseAJourContributeurs',
                callBack: (response) => {
                    if (!response.reqSuccess) this.contributeur = JSON.parse(JSON.stringify(this.contributeurAvantModification))
                    else this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeur))
                }
            })
        }
  },
    computed: {
        ...mapState(['userConnected', 'user', 'contributeurVide']),
        niveauAcces() {
          if (this.contributeur.id_acces == undefined) return 'Inconnu'
          if (this.listeAcces.length == 0) return 'recherche...'
          let accesContributeur = 'Unknow';
          this.listeAcces.forEach((acces) => { if (acces.id_acces == this.contributeur.id_acces) accesContributeur = acces.libelle_acces})
          // let acces = this.listeAcces.find((acces)=> acces.id_acces = this.contruiteur.id_acces)
          return accesContributeur
        },
        identifiantConnexion: {
            get(){
                if (this.user!= undefined) return this.user.identifiant
                else return undefined
            },
            set(nouvelIdentifiant) {
                this.phpPost({
                    phpFile : 'assets/php/modifierIdentifiant.php',
                    sendObject: {
                        identifiant_pass: nouvelIdentifiant,
                        id_contributeur: this.user.id_contributeur
                    },
                    dispatchAtTheEnd: 'miseAJourContributeurs'
                })
            }
        },
        nomCompletContributeur() {
            if (this.userConnected) return this.user.nom_contributeur + ' ' + this.user.prenom_contributeur;
            return 'Non connecté'
        },
        userId() {
            if (this.userConnected) return this.user.id_contributeur
            return undefined
        },
        emailValidation() {
            return this.contributeur.email_contributeur.match(/^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i);
        },
    },
    watch: {
        user() {
            this.contributeur = this.user;
            this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeur))
        }
    },
    created: function () {
        this.contributeur = this.contributeurVide
        this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeur))
    },
    mounted: function () {
        if (this.userConnected) this.contributeur = this.user
        else this.contributeur = this.contributeurVide
        this.phpPost({
          phpFile: "assets/php/listeAcces.php",
          sendObject: { idContributeur: this.user.id_contributeur },
          disableThumbnail: true,
          callBack: (response) => {
            if (response.reqSuccess) {
              this.listeAcces = response.reqList
            }
          }
        })
        this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeur))
    }
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.page-espace-personnel {
    width: 1100px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-titre {
  margin-bottom: 58.5px;
//   display: flex;
  align-items: center;
//   align-self: stretch;
}
.label-titre {
  @include typo-titre;
  color: $couleur-texte-fonce;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
}

.label-nom-contributeur {
  @include typo-titre;
  color: $couleur-texte-fonce;
  margin-bottom: 50px;  
  &:not(:last-of-type) {
    margin-right: 15px;
  }
}

.frame-infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.frame-partie1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 86px;
  }
}
.frame-denomination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 19px;
  }
}
.label {
  @include typo-large;
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
}
.frame-nom {
  background-color: $couleur-fond-composant-lieu;
  margin-bottom: 12px;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.label-two {
  @include typo-small-medium;
  color: $couleur-texte-fonce;
}
.label-editable {
  align-self: stretch;
  height: 27px;
}
.frame-siret {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 12px 25px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.frame-libelle-adresse {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.frame-reinitialiser {
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  border: 1px solid #ccc;
  height: 27px;
}

.info-revocation{
  margin-left: 3px;
  padding: 0px 5px;
  border-radius: 20%;
  border: 1px solid #000;
}
</style>