<template>
  <div class="composant-details-numero">
    <div class="frame-infos">
      <p class="titre">{{ titre }}</p>
      <div class="separateur"></div>
      <div class="frame-ligne-details">
        <p class="label-ligne-details">Date de parution</p>
        <p class="date-parution">{{ dateParution }}</p>
      </div>
      <div class="frame-ligne-details">
        <p class="label-ligne-details">Stock actuel</p>
        <p class="date-parution">{{ stockActuel }}</p>
      </div>
      <div class="frame-ligne-details">
        <p class="label-ligne-details">Nombre ventes</p>
        <p class="date-parution">{{ ventesNumero }}</p>
      </div>
      <div class="frame-ligne-details">
        <p class="label-ligne-details">Impressions</p>
        <p class="date-parution">{{ nombreImpressions }}</p>
      </div>
      <div class="frame-ligne-details">
        <p class="label-ligne-details">Pilons</p>
        <p class="date-parution">{{ nombrePilons }}</p>
      </div>
      <div class="frame-ligne-details-two">
        <p class="label-ligne-details-two">Contributions</p>
        <div class="frame-liste-contributions">
          <p class="date-parution" v-html="detailsContribution"></p>
        </div>
      </div>
    </div>
    <div class="separateur-two"></div>
    <img :src="couvertureNumero" class="frame-couverture" />
  </div>
</template>

<script>
export default {
  name: "ComposantDetailsNumero",
  props: {
    dateParution: { type: String, default: "Date" },
    detailsContribution: {
      type: String,
      default: "  - Prenom Nom (Type contribution - pourcentage %)",
    },
    nombreImpressions: {
      // type: String,
      default: "Nb impressions",
    },
    nombrePilons: {
      // type: String,
      default: "Nb pilons",
    },
    stockActuel: { type: String, default: "Stock" },
    titre: { type: String, default: "N°x - Titre" },
    ventesNumero: { type: String, default: "Ventes" },
    couvertureNumero: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-details-numero {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    flex-direction: column;
    padding: 0 5px;
    top: $ruban-height;
  }
}
.frame-infos {
  margin-right: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.titre {
  //   text-align: left
  color: $couleur-texte-fonce;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  @include typo-titre;
}
.separateur {
  height: 1px;
  background-color: $silver;
  margin-bottom: 19px;
  align-self: stretch;
}
.frame-ligne-details {
  margin-bottom: 5px;
  border-radius: 9px;
  padding: 5px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-ligne-details {
  width: 192px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.date-parution {
  color: $couleur-texte-fonce;
  @include typo-medium;
  min-width: 200px;
}
.frame-ligne-details-two {
  border-radius: 9px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.label-ligne-details-two {
  color: $couleur-texte-fonce;
  margin-bottom: 9.5px;
  @include typo-medium;
}
.frame-liste-contributions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  min-width: 450px;
}
.separateur-two {
  width: 1px;
  background-color: $silver;
  margin-right: 20px;
  align-self: stretch;
}
.frame-couverture {
  width: 253px;
  height: 336.93px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  //   background-image: url("https://static.overlay-tech.com/assets/6a3097d9-c625-42c0-96ad-808d5b4de1d3.png");
  background-size: 100% 100%;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    height: auto;
  }
}
</style>
