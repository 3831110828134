<template>
  <div>
    <input
      type="radio"
      name="mode-visualisation"
      id="stockParNumero"
      value="stockParNumero"
      v-model="modeVisualisation"
    /><label for="stockParNumero">Stock par numéro</label><br />
    <input
      type="radio"
      name="mode-visualisation"
      id="ventesParNumero"
      value="ventesParNumero"
      v-model="modeVisualisation"
    /><label for="ventesParNumero">Ventes par numero</label><br />
    <input
      type="radio"
      name="mode-visualisation"
      id="stockTotal"
      value="stockTotal"
      v-model="modeVisualisation"
    /><label for="stockTotal">Evolution stock global</label><br />
    <input
      type="radio"
      name="mode-visualisation"
      id="ventesNumeros"
      value="ventesNumeros"
      v-model="modeVisualisation"
    /><label for="ventesNumeros">Ventes de tous les numeros</label><br />
    <br />
    <!-- <div v-if="modeVisualisation == 'ventesParNumero'"> -->
    <!-- </div> -->
    <!-- <div v-if="modeVisualisation == 'stockParNumero'"> -->
    <!-- </div> -->
    <div v-if="modeVisualisation != 'stockTotal'">
      <input
        type="checkbox"
        name=""
        id="interpolation-ventes"
        v-model="interpolerVentes"
      /><label for="interpolation-ventes"
        >Interpoler les données manquantes</label
      ><br />
      <input
        type="checkbox"
        name=""
        id="correction-ventes"
        v-model="corrigerVentes"
      /><label for="correction-ventes">Corriger les ventes négatives</label
      ><br />
      <input
        type="checkbox"
        name=""
        id="interpolation-stock"
        v-model="interpolerStock"
      /><label for="interpolation-stock"
        >Corriger les erreurs d'inventaire</label
      ><br />
      <br />
      <select name="numero" v-model="idNumero">
        <option value="-">Choisir un numéro</option>
        <option
          v-for="(numero, index) in listeNumeros"
          :key="index"
          :value="numero.id_numero"
          >{{ numero.numero_numero + " " + numero.titre_numero }}
        </option>
      </select>
    </div>

    <div v-if="idNumero != undefined">
      <canvas id="myChart" width="1500" height="600"></canvas>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data: () => {
    return {
      id1: 20,
      id2: 30,
      id3: 40,
      idNumero: "-",
      myChart: undefined,
      modeVisualisation: "stockParNumero",
      interpolerVentes: false,
      corrigerVentes: false,
      interpolerStock: false,
      interpolationDeep: 4,
    };
  },
  computed: {
    ...mapState(["listeNumeros"]),
    ...mapGetters(["trouveNumeroParId", "formatDateNomDuMois"]),
  },
  methods: {
    // ...mapActions([]),
    filterLastNMonths(data, nMonth) {
      let dataLength = data.length;
      return data.filter((value, index) => {
        return dataLength - index <= nMonth;
      });
    },
    interpolateSales(data) {
      if (this.interpolerVentes) {
        let returnData = [];
        for (var i = 0; i < data.length; i++) {
          if (i == 0) returnData.push(data[i]);
          else {
            var moisPrecedent = parseInt(
              data[i - 1].date_comptage.split("-")[1]
            );
            var moisEnCours = parseInt(data[i].date_comptage.split("-")[1]);
            let nbreMoisEcoule = moisEnCours - moisPrecedent;
            if (nbreMoisEcoule < 0) nbreMoisEcoule += 12;
            if (nbreMoisEcoule == 1) {
              returnData.push(data[i]);
            } else {
              console.warn("----------- Interpolation nécessaire");
              console.warn("Id numero ", data.id_numero);
              console.warn("Interpolation de ", nbreMoisEcoule, "mois");
              let nombreVentesInterpole = Math.round(
                (data[i].ventes - data[i - 1].ventes) / nbreMoisEcoule
              );
              for (
                let missingMonth = 0;
                missingMonth < nbreMoisEcoule;
                missingMonth++
              ) {
                let dataCopy = JSON.parse(JSON.stringify(data[i]));
                dataCopy.ventes = nombreVentesInterpole;
                returnData.push(dataCopy);
              }
            }
          }
        }
        return returnData;
      } else return data;
    },
    interpolateStock(data, degree) {
      if (this.interpolerStock) {
        if (degree > 1) data = this.interpolateStock(data, degree - 1);
        for (var i = 2; i < data.length; i++) {
          if (data[i] - data[i - 1] > 0 && data[i] - data[i - 1] < 2000) {
            // console.log('data[i-1]', data[i-1])
            data[i - 1] = Math.round(
              (parseInt(data[i - 2]) + parseInt(data[i])) / 2
            );
            // console.log('data[i]', data[i])
          }
        }
      }
      return data;
    },
    correctNegativeSales(data) {
      if (this.corrigerVentes) {
        for (var i = 1; i < data.length; i++) {
          if (data[i].ventes < 0) {
            if (data[i - 1].ventes != 0) {
              let ventesReelles = Math.round(
                (data[i - 1].ventes + data[i].ventes) / 2
              );
              if (ventesReelles < 1000) {
                data[i - 1].ventes = ventesReelles;
                data[i].ventes = ventesReelles;
              }
            }
          }
        }
      }
      return data;
    },
    filterFirstsNMonths(data, nMonth) {
      return data.filter((value, index) => {
        return index < nMonth;
      });
    },
    convertStockToSale(stockArray) {
      for (let index = 0; index < stockArray.length; index++) {
        if (index == 0) stockArray[index].ventes = 0;
        else {
          stockArray[index].ventes =
            stockArray[index - 1].quantite_comptage -
            stockArray[index].quantite_comptage;
          if (stockArray[index].ventes < -1500)
            stockArray[index].ventes += 3000;
        }
      }
      return stockArray;
    },
    drawGraph(idNumero) {
      let numero = this.trouveNumeroParId(idNumero);
      let label = [];
      let serie = [];
      let listeComptages = this.filterLastNMonths(numero.liste_comptages, 70);
      for (let comptage of listeComptages) {
        label.push(this.formatDateNomDuMois(comptage.date_comptage));
        serie.push(comptage.quantite_comptage);
      }

      this.myChart = new window.Chart("myChart", {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              label: "Stock",
              data: serie,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text:
                "N°" +
                numero.numero_numero +
                " " +
                numero.titre_numero +
                " - Evolution des stocks | Parution : " +
                this.formatDateNomDuMois(numero.date_parution_numero),
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        },
      });
    },
    computeDataForSales(idNumero) {
      let numero = this.trouveNumeroParId(idNumero);
      let label = [];
      let serie = [];
      let listeComptages = this.interpolateStock(
        numero.liste_comptages.map((cpt) => {
          return cpt.quantite_comptage;
        }),
        this.interpolationDeep
      );
      numero.liste_comptages = numero.liste_comptages.map((cpt, index) => {
        cpt.quantite_comptage = listeComptages[index];
        return cpt;
      });
      listeComptages = this.correctNegativeSales(
        this.interpolateSales(this.convertStockToSale(numero.liste_comptages))
      );
      for (let comptage of listeComptages) {
        label.push(this.formatDateNomDuMois(comptage.date_comptage));
        serie.push(comptage.ventes);
      }
      return { labels: label, serie: serie, numero: numero };
    },
    drawStockTotalGraph() {
      function stockParDate(numero, annee, mois) {
        let validCount = numero.liste_comptages.filter((cpt) => {
          let date = cpt.date_comptage.split("-");
          let m = date[1];
          let a = date[0];
          return parseInt(m) == mois && parseInt(a) == annee;
        });
        if (validCount.length != 0)
          return parseInt(validCount[0].quantite_comptage);
        else return undefined;
      }

      let data = [];
      let label = [];
      let listeMois = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      let listeAnnee = [2015, 2016, 2017, 2018, 2019, 2020, 2021];
      for (let annee of listeAnnee) {
        for (let mois of listeMois) {
          label.push(this.formatDateNomDuMois(annee + "-" + mois + "-1"));
          let stock = 0;
          for (let numero of this.listeNumeros) {
            let stockNumero = stockParDate(numero, annee, mois);
            if (stockNumero != undefined) stock += stockNumero;
          }
          data.push(stock);
        }
      }
      this.drawChart({
        labels: label,
        serie: data,
        title: "Evolution du stock",
        dataLabel: "stock",
      });
    },
    drawHallOfFame() {
      function stockParDate(numero, annee, mois) {
        let validCount = numero.liste_comptages.filter((cpt) => {
          let date = cpt.date_comptage.split("-");
          let m = date[1];
          let a = date[0];
          return parseInt(m) == mois && parseInt(a) == annee;
        });
        if (validCount.length != 0)
          return parseInt(validCount[0].quantite_comptage);
        else return undefined;
      }

      let data = [];
      let label = [];
      let liste = this.listeNumeros.sort((a, b) => {
        return a.nombre_ventes_numero > b.nombre_ventes_numero ? -1 : 1;
      });
      liste = liste.filter((n, i) => i < 36);
      for (let numero of liste) {
        label.push(numero.numero_numero + " " + numero.titre_numero);
        data.push(numero.nombre_ventes_numero);
      }

      this.drawChart({
        labels: label,
        serie: data,
        title: "les 35 Numeros les mieux vendus",
        dataLabel: "Ventes",
        chartType: "bar",
      });
    },
    drawSalesGraph(idNumero) {
      let data = this.computeDataForSales(idNumero);
      let numero = data.numero;
      this.drawChart({
        ...data,
        title:
          "N°" +
          numero.numero_numero +
          " " +
          numero.titre_numero +
          " - Evolution des stocks | Parution : " +
          this.formatDateNomDuMois(numero.date_parution_numero),
        dataLabel: "Ventes",
      });
    },
    drawStockGraph(idNumero) {
      let numero = this.trouveNumeroParId(idNumero);
      let serie = numero.liste_comptages.map((cpt) => {
        return cpt.quantite_comptage;
      });
      serie = this.interpolateStock(serie, this.interpolationDeep);
      let labels = numero.liste_comptages.map((cpt) => {
        return this.formatDateNomDuMois(cpt.date_comptage);
      });
      this.drawChart({
        labels: labels,
        serie: serie,
        title: "Evolution stock " + numero.titre_numero,
        dataLabel: "Stock",
      });
    },
    drawChart(data) {
      if (this.myChart != undefined) this.myChart.destroy();
      if (data.chartType == undefined) data.chartType = "line";
      if (data.dataLabel == undefined) data.dataLabel = "Data";

      this.myChart = new window.Chart("myChart", {
        type: data.chartType,
        data: {
          labels: data.labels,
          datasets: [
            {
              label: data.dataLabel,
              data: data.serie,
              // cubicInterpolationMode: 'monotone',
              // tension: 0.4
            },
          ],
        },
        options: {
          interaction: {
            intersect: false,
            mode: "nearest",
          },
          plugins: {
            title: {
              display: true,
              text: data.title,
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        },
      });
    },
    checkOptionsAndDraw() {
      if (this.myChart != undefined) this.myChart.destroy();
      if (this.modeVisualisation == "stockTotal") this.drawStockTotalGraph();
      if (this.modeVisualisation == "ventesParNumero" && this.idNumero != "-")
        this.drawSalesGraph(this.idNumero);
      if (this.modeVisualisation == "stockParNumero" && this.idNumero != "-")
        this.drawStockGraph(this.idNumero);
      if (this.modeVisualisation == "ventesNumeros") this.drawHallOfFame();
    },
  },
  watch: {
    interpolerStock() {
      this.checkOptionsAndDraw();
    },
    interpolerVentes() {
      this.checkOptionsAndDraw();
    },
    corrigerVentes() {
      this.checkOptionsAndDraw();
    },
    modeVisualisation() {
      this.checkOptionsAndDraw();
    },
    idNumero() {
      this.checkOptionsAndDraw();
    },
  },
  mounted: function() {
    //   if (this.listeNumeros !=undefined)
    //     // this.drawGraph(14)
  },
};
</script>

<style></style>
