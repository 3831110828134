<template>
  <div class="home">
    <div class="upper-ruban">
      <div class="zone-titre">
        <p class="titre-page">
          Epicerie Séquentielle - Gestion
        </p>

        <div class="zone-infos">
          <Loader
            v-if="requetesEnCours > 0"
            color="white"
            size="20"
            label="Traitement"
            class="loader-global"
          />
          <div v-if="!screenIsPhone">
            <a
              v-if="userConnected"
              href="https://coda.io/d/Guide-dutilisation_d-m_mDsynsy/Guide-dutilisation_suFfQ#_luYTt"
              target="_blank"
              style="color: white;"
              class="mr-5"
              >Guide d'utilisation</a
            >
            <a
              v-if="userConnected"
              href="https://coda.io/form/Formulaire-remarques_dGoidzOQLLk"
              target="_blank"
              style="color: white;"
              class="mr-5"
              >Signaler un problème / Faire une suggestion</a
            >
          </div>
          <div class="debug-switch" v-if="user.identifiant == 'PESNOT-PIN'">
            <div>Log console</div>
            <ComposantSwitch :status="afficherLogsConsole" @switch="definirAffichageLogsConsole" />
          </div>
          <div class="debug-switch" v-if="user.identifiant == 'PESNOT-PIN'">
            <div>Log retours</div>
            <ComposantSwitch :status="afficherLogsRetour" @switch="definirAffichageLogsRetour" />
          </div>
        </div>

        <!-- <Loader v-if="requetesEnCours > 0" color="white" size="25" /> -->
      </div>
      <ComposantInfosContributeur
        class="infos-contributeur"
        :userName="userName"
        :userId="userId"
        :connected="userConnected"
        :toggleMenu="toggleMenu"
      />
    </div>
    <div v-if="userConnected" class="frame-corps-de-page">
      <MenuNavigation
        v-on:click="definirSection"
        class="menu-nav"
        :menuVisible="menuVisible"
        :toggleMenu="toggleMenu"
      />
      <router-view style="margin-left:auto;margin-right:auto;"></router-view>
    </div>
    <div v-else-if="$route.name == 'inscription'" class="frame-corps-de-page">
      <pageCreationCompteFuturMembre />
    </div>
    <div v-else class="frame-composant-connexion">
      <ComposantConnexion />
    </div>
    <ComposantVignette
      v-model="vignette.visible"
      position="centered"
      :thumbnailText="vignette.message"
      :thumbnailType="vignette.type"
      :autoHide="vignette.type == 'success' && vignette.autoHide"
      :closeCross="vignette.type == 'error' || !vignette.autoHide"
      :elementClickable="vignette.elementClickable"
      :detailsButton="vignette.detailsButton"
      @details-click="afficherDetailsVignette"
      :autoHideTime="vignette.duree"
    />
    <Modale v-on:modale-event="modaleFetch" v-model="modale.visible" :content="modale.content" />
  </div>
</template>

<script>
import MenuNavigation from "./menuNavigation";
import ComposantVignette from "./composantVignette";
import ComposantInfosContributeur from "./composantInfosContributeur";
import { mapMutations, mapState, mapActions } from "vuex";
import Modale from "./modale";
import { PHONE_BREAK_POINT } from "../js/storeConst";

const STATES_TO_MAP = [
  "vignette",
  "modale",
  "userConnected",
  "user",
  "requetesEnCours",
  "afficherLogsConsole",
  "afficherLogsRetour",
  "screenIsPhone",
];

const MUTATIONS_TO_MAP = [
  "definirVisibiliteModale",
  "modaleFetch",
  "definirScreenIsPhone",
  "definirAffichageLogsConsole",
  "definirAffichageLogsRetour",
];

const ACTIONS_TO_MAP = ["modaleAlert", "modaleComposant", "checkConnection", "phpPost"];

export default {
  name: "Home",
  components: {
    MenuNavigation,
    ComposantVignette,
    Modale,
    ComposantInfosContributeur,
    ComposantSwitch: () => import("./_debug/composantSwitch"),
  },
  data: () => {
    return { menuVisible: false };
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    userName() {
      if (this.userConnected)
        return this.user.nom_contributeur + " " + this.user.prenom_contributeur;
      return "Non connecté";
    },
    userId() {
      if (this.userConnected) return this.user.id_contributeur;
      return undefined;
    },
  },
  methods: {
    ...mapMutations(MUTATIONS_TO_MAP),
    ...mapActions(ACTIONS_TO_MAP),
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    definirSection(section) {
      this.menuVisible = false;
      if (this.$route.path != section) this.$router.push(section);
    },
    afficherDetailsVignette() {
      this.modaleAlert(this.vignette.detailsContent);
    },
  },
  mounted: function() {
    if (this.$route.name !== "inscription") this.checkConnection();
    this.definirScreenIsPhone(window.innerWidth < PHONE_BREAK_POINT);
    window.addEventListener("resize", () => {
      this.definirScreenIsPhone(window.innerWidth < PHONE_BREAK_POINT);
    });
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";
@import "src/js/modale.scss";

.home {
  background-color: $fond-page;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upper-ruban {
  display: flex;
  // border: 3px solid #ff0000;
  z-index: 100;
  width: 100%;
  height: $ruban-height;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.loader-global {
  position: relative;
  right: 50px;
  // top: 2px;
}

.infos-contributeur {
  position: fixed;
  right: 0;
  width: 350px;
  height: $ruban-height;
  z-index: 101;
  cursor: pointer;
  @media (max-width: $mobile-break-point) {
    left: 0;
    width: 100%;
  }
}

.zone-titre {
  z-index: 100;
  position: fixed;
  left: 0px;

  background-color: $couleur-fond-barre-titre;
  // box-shadow: 0 4px 26px 0 $opacity-25;

  // padding: 26px 26px 26px 47px;
  padding-left: 47px;
  height: $ruban-height;
  width: calc(100vw - 350px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.titre-page {
  display: flex;
  align-items: center;
  @include typo-large;
  @media (max-width: $mobile-break-point) {
    @include typo-small-medium;
  }
  color: $couleur-texte-blanc;
  width: auto;
}
.frame-corps-de-page {
  // margin-top: 0px;
  padding: 39px;
  @media (max-width: $mobile-break-point) {
    padding: 5px;
  }
  display: flex;
  align-items: flex-start;
  // height: 592px;
  align-self: stretch;
}
.menu-nav {
}

/******************************************************** */
/******************************************************** */

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

table {
  border-collapse: collapse;
}

/*********************************** Général ****************************************************/

.infobulle {
  position: relative; /* les .infobulle deviennent référents */
  cursor: help;
  color: $couleur-texte-fonce;
}

/* on génère un élément :after lors du survol et du focus :*/
.infobulle:hover::after {
  @include typo-small-medium;
  color: $couleur-texte-fonce;
  // text-align: center;
  content: attr(bulle-content);
  background-color: $fond-page;
  padding: 10px;
  border-radius: 15px;
  @extend .bshadow;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.4);
  min-width: 250px;
  // max-width: 350px;
  position: absolute;

  top: -20px;
  left: 50%;
  transform: translateX(-120%);
  z-index: 5; /* pour s'afficher au dessus des éléments en position relative */
}

.zone-infos-modale {
  width: auto;
  height: auto;
  min-width: 300px;
  min-height: 100px;

  padding: 20px;
  background-color: $fond-page;
  border-radius: 39px;
  box-shadow: 0 5px 10px 0 $opacity-25;
  border: 1px solid $couleur-texte-clair;
}

input:disabled {
  color: #000;
  background-color: #eee;
}

.dflex {
  display: flex;
}

.dashed {
  border: 1px dashed #000;
}

.padd-left {
  padding-left: 100px;
}

.space-around {
  justify-content: space-around;
}

.cursorPointer {
  cursor: pointer;
}

.divTextCenter {
  text-align: center;
}

.iblock {
  display: inline-block;
}

.bradius {
  border-radius: 6px;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.bshadow {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
}

.btn {
  @extend .bshadow;
  // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}

nav {
  background-color: #999;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.nav-item {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  color: #fff;
  font-size: 1.1em;
  padding: 15px 10px;
  height: auto;
}
.nav-item:hover {
  text-decoration: none;
  color: #444;
  background-color: #b3b3b3;
  border-radius: 25px;
}

.selected {
  text-decoration: none;
  color: white;
  background-color: #b3b3b3;
  border-radius: 25px;
}

section {
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: #edf2f7;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}

.ligneFormulaire {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.labelFormulaire {
  width: 200px;
}

.corpsDePage {
  width: 800px;
  margin: 0 auto;
  // padding-top: 100px;
}

.sousSection,
.sousSectionModale {
  margin-top: 30px;
  width: auto;
  width: 770px;
  border: 1px solid #999;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}

.sousSectionModale {
  max-height: 80vh;
}

.titreSousSection {
  margin-bottom: 20px;
}

.details {
  width: 100%;
  // border: 1px dashed #999;
  border-radius: 5px;
  padding: 20px 10px 10px 10px;
  margin-top: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.frame-composant-connexion {
  margin: auto;
  margin-top: 15vh;
  // width: 100%;
  @media (max-width: $mobile-break-point) {
    margin-top: 20px;
  }
}

.zone-infos {
  display: flex;
  align-items: center;
}

.debug-switch {
  // border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  // width: 200px;
  margin-top: 10px;
  & div {
    @include typo-medium;
    color: $couleur-texte-blanc;
    margin-right: 10px;
  }
}
</style>
