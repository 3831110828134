<template>
  <div v-if="user.adminAccessGranted" class="page-admin-asso">
    <div class="frame-titre">
      <p class="label-titre">Demandes de versement</p>
    </div>
    <div class="frame-filtres">
      <div class="titre-filtres">Filtrer les demandes</div>
      <!--  -->
      <div class="frame-filtre-contributeur">
        <label>Afficher les demandes de</label
        ><select class="filtre-contributeur" v-model="idContributeur">
          <!-- @change="defiirContributeurAAfficher" -->
          <option value="-">Tous les contributeurs</option>
          <option
            v-for="contributeur of listeContributeurs"
            :key="contributeur.id_contributeur"
            :value="contributeur.id_contributeur"
            >{{ contributeur.nom_contributeur + " " + contributeur.prenom_contributeur }}</option
          >
        </select>
      </div>
      <ComposantCheckbox
        label="Afficher uniquement les demandes non réglées"
        v-model="afficherUniquementDemandesEnCours"
      />
    </div>
    <div class="frame-infos-versements">
      <div class="versement-container">
        <div class="identifiant-contributeur">Identifiant</div>
        <ComposantDemandeVersement header="true" />
      </div>
      <div v-if="Object.keys(listeDemandesFiltree).length == 0">
        Aucune demande
      </div>
      <div v-else>
        <div
          v-for="(listeDemandeVersement, key) in listeDemandesFiltree"
          :key="key"
          class="versement-container"
        >
          <div class="identifiant-contributeur">{{ key }}</div>
          <div>
            <div
              v-for="demandeVersement in listeDemandeVersement"
              :key="demandeVersement.id_demande_versement"
              class="demande-versement"
            >
              <!-- {{ demandeVersement.nom_contributeur }} {{ demandeVersement.prenom_contributeur }} -->
              <ComposantDemandeVersement
                :demandeVersement="demandeVersement"
                @update-list="recupererListeDemandes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PageAdministration",
  data: () => {
    return {
      idContributeur: "-",
      listeDemandesVersement: [],
      listeParNom: {},
      afficherUniquementDemandesEnCours: false,
    };
  },
  computed: {
    ...mapState(["user", "userConnected", "listeContributeurs"]),
    listeDemandesFiltree() {
      let output = {};
      Object.keys(this.listeParNom).forEach((contributeur) => {
        let listeDemandesFiltree = this.listeParNom[contributeur].filter((demande) =>
          this.demandeCorrespondAuxFiltres(demande)
        );
        if (listeDemandesFiltree.length > 0) output[contributeur] = listeDemandesFiltree;
      });
      return output;
    },
  },
  methods: {
    ...mapActions(["phpPost"]),
    demandeCorrespondAuxFiltres(demande) {
      return (
        (!this.afficherUniquementDemandesEnCours || demande.id_versement == undefined) &&
        (this.idContributeur == "-" || demande.id_contributeur === this.idContributeur)
      );
    },
    recupererListeDemandes() {
      this.phpPost({
        phpFile: "assets/php/listeCompleteDemandeVersement.php",
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.listeDemandesVersement = response.reqList;
            this.listeParNom = {};
            this.listeDemandesVersement.forEach((demandeVersement) => {
              if (this.listeParNom[demandeVersement.identifiant_pass] == undefined)
                this.listeParNom[demandeVersement.identifiant_pass] = [];

              this.listeParNom[demandeVersement.identifiant_pass].push(demandeVersement);
            });
          }
          console.log("this.listeParNom", this.listeParNom);
        },
      });
    },
  },
  mounted: function() {
    if (!this.userConnected) this.listeDemandesVersement = [];
    else this.recupererListeDemandes();
  },
  watch: {
    userConnected() {},
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

.page-admin-asso {
  width: 100%;
  border: $contour-page;
  padding: 63px 20px;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0 5px 20px 0 $opacity-25;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-titre {
  width: 100%;
  margin-bottom: 59px;
}
.label-titre {
  @include typo-titre;
  color: $couleur-texte-fonce;
}
.frame-filtres {
  width: 780px;
  padding: 10px;
  border-radius: 15px;
  background-color: $fond-page;
  @extend .bshadow;
  display: flex;
  flex-direction: column;
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-bottom: 10px;
  & .titre-filtres {
    @include typo-large;
    margin-bottom: 10px;
  }
}

.frame-filtre-contributeur {
  margin-bottom: 10px;
  // display: flex;
  // align-items: center;
}

.filtre-contributeur {
  width: 300px;
  margin-left: 10px;
}

.frame-infos-versements {
  padding: 10px;
  border-radius: 15px;
  background-color: $fond-page;
  @extend .bshadow;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.versement-container {
  display: flex;
  border-bottom: 1px solid $couleur-texte-fonce;
  margin-top: 5px;
  padding: 5px 0;
}

.identifiant-contributeur {
  @include typo-medium;
  color: $couleur-texte-fonce;

  width: 200px;
}

.demande-versement {
  &:not(:last-child) {
    border-bottom: 1px dashed $couleur-texte-fonce;
  }
  padding: 5px 0;
}
</style>
