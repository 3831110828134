<template>
  <div class="loader-container">
    <div class="loader">
      <div
        class="lds-dual-ring"
        :style="
          `
          width: ${size}px; 
          height: ${size}px;
          border-color: ${color} transparent ${color} transparent;
        `
        "
      ></div>
    </div>
    <div v-if="label != ''" class="label" :style="`color: ${color};`">
      {{ label }}
    </div>
    <!-- <div class="label" :style="'color: white;'">{{ label }}</div> -->
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: { default: 34 },
    color: { default: "#000" },
    label: { default: "" },
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  align-items: center;
  // border: 1px solid white;
}
.label {
  // border: 1px solid white;
  margin-left: 5px;
  margin-bottom: auto;
  margin-top: auto;
}
.loader {
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 12px 16px;
  // align-self: stretch;
  // text-align: center;
  // margin-bottom: 20px;
}

.lds-dual-ring {
  content: " ";
  display: inline-block;
  // width: 30px;
  // height: 30px;
  border-radius: 50%;
  border: 2px solid #000;
  // border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
