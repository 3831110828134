<template>
  <div class="composant-transfert">
    <p class="titre">Transfert de numéros</p>
    <div class="separateur"></div>
    <div class="frame-lieu-depart">
      <p class="label-lieu-depart">Lieu de départ</p>
      <select class='select-lieu' v-model="infosTransferts.idLieuDepart">
        <option value="-">-</option>
        <option v-for="lieu of listeLieux" :key="lieu.id_lieu" :value="lieu.id_lieu">{{ lieu.nom_lieu }}</option>
      </select>
    </div>
    <div class="frame-lieu-depart">
      <p class="label-lieu-depart">Lieu d’arrivée</p>
      <select class='select-lieu' v-model="infosTransferts.idLieuArrivee">
        <option value="-">-</option>
        <option v-for="lieu of listeLieux" :key="lieu.id_lieu" :value="lieu.id_lieu">{{ lieu.nom_lieu }}</option>
      </select>
    </div>
    <div class="frame-lieu-depart">
      <p class="label-lieu-depart">Date transfert</p>
      <input type="date" v-model='infosTransferts.dateTransfert'>
    </div>
    <div class="frame-lieu-depart">
      <p class="label-exemplaires-concernes">
        Exemlaires concernés
      </p>
    </div>
    <div class="frame-ajouter-numero">
      <select class='select-numero' v-model="numeroChoisi">
        <option value="-">-</option>
        <option v-for="(numero, indexN) of listeNumeros" :key="numero.id_numero" :value="indexN">{{ numero.numero_numero + ' ' + numero.titre_numero }}</option>
      </select>
      <input placeholder="Qté" type='text' v-model='quantiteChoisie' class="input-quantite-choisie" @keypress.enter='ajouterNumero'>
      <div
        class="infobulle stock-numero-lieu"
        bulle-content="Nombre de numéro disponible dans le lieu choisi"
      >{{ stockageNumeroChoisiLieuDepart }}</div>
      <!-- <img 
        class="bouton-ajouter-numero element-clickable" 
        src="assets/icones/add.png" 
        alt="ajouter numero"
        title="Ajouter un numero"
        @click="ajouterNumero"
      /> -->
      <div
      class="bouton-ajouter-numero btn btn-primary" 
        title="Ajouter un numero"
        @click="ajouterNumero"
      >
        Ajouter
      </div>
    </div>
    <div class='liste-numeros'>
      <div v-for="(numero, indexN) of infosTransferts.listeNumerosATransferer" :key="numero.id_numero">
        <ComposantNumeroTransfert 
          :titreNumero="numero.numero_numero + ' ' + numero.titre_numero" 
          :indexN='indexN'
          v-model="infosTransferts.listeNumerosATransferer[indexN].quantiteATransferer"
          @supprimerTransfert="supprimerTransfert"
        />
      </div>
    </div>
    <div class="frame-boutons">
      <div class="frame-transferer">
        <p class="bouton-transferer-button" @click="enregistrerTransferts">Transférer</p>
      </div>
      <div class="frame-annuler">
        <p class="bouton-transferer-button" @click="quitterComposant">Annuler</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { getToday } from '../js/fonctionsGlobales';
import { modaleAlert } from '../js/modale';
import ComposantNumeroTransfert from './composantNumeroTransfert'

const STATES_TO_MAP = [
  'listeLieux',
  'listeNumeros'
]

const MUTATIONS_TO_MAP = [
  'definirVisibiliteModale',
  'definirContenuModale'
]

const ACTIONS_TO_MAP = [
  'phpPost',
  'modaleAlert'
]

export default {
  name: "ComposantAjoutTransfert",
  components: { ComposantNumeroTransfert },
 
  data: ()=>{
    return {
      numeroChoisi: '-',
      quantiteChoisie: '0',
      infosTransferts: {
        dateTransfert: getToday(),
        listeNumerosATransferer: [],
        idLieuDepart: '-',
        idLieuArrivee: '-'
      }
    }
  },
  methods: {
    ...mapMutations(MUTATIONS_TO_MAP),
    ...mapActions(ACTIONS_TO_MAP),
    quitterComposant(){
      this.definirContenuModale({
        componentName: "ComposantListeTransfert",
        allowQuitOnClick: true
      })
    },
    enregistrerTransferts() {
      if (this.infosTransferts.idLieuDepart == '-') return this.modaleAlert('Le lieu de départ est manquant')
      if (this.infosTransferts.idLieuArrivee == '-') return this.modaleAlert('Le lieu d\'arrivée est manquant')
      if (this.infosTransferts.dateTransfert == '0000-00-00' 
        || this.infosTransferts.dateTransfert == undefined
        || this.infosTransferts.dateTransfert == '') 
          return this.modaleAlert('Aucune date n\'a été spécifiée ou la date entrée est incorrecte')
      if (this.infosTransferts.listeNumerosATransferer.length == 0) return this.modaleAlert('Au moins un numéro doit être transféré<br>Pensez à ajouter le numero en cliquant sur +')
      this.phpPost({
        phpFile: './assets/php/ajouterTransfert.php',
        sendObject: this.infosTransferts,
        dispatchAtTheEnd: ['miseAJourNumeros', 'miseAJourLieux'],
        callBack: (response) => {
          if (response.reqSuccess) this.definirVisibiliteModale(false); 
        }
      })
    },
    ajouterNumero() {
      if (this.infosTransferts.idLieuDepart == '-') return this.modaleAlert('Vous devez choisir un lieu de départ avant de pouvoir ajouter des numéros à transférer')
      var numeroATransferer = this.listeNumeros[this.numeroChoisi]

      var listeId = this.infosTransferts.listeNumerosATransferer.map((numero) => { return numero.id_numero })
      if (this.numeroChoisi!='-' && listeId.indexOf(numeroATransferer.id_numero)==-1) {
        this.infosTransferts.listeNumerosATransferer.push({
          titre_numero: numeroATransferer.titre_numero,
          numero_numero: numeroATransferer.numero_numero,
          id_numero: numeroATransferer.id_numero,
          quantiteATransferer: this.quantiteChoisie
        })
      }
    },
    supprimerTransfert(indexN) {
      this.infosTransferts.listeNumerosATransferer.splice(indexN, 1)
    }
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    stockageNumeroChoisiLieuDepart() {
      if (this.numeroChoisi != '-' && this.infosTransferts.idLieuDepart!='-') {
        var liste = this.listeNumeros[this.numeroChoisi].liste_derniers_stockages
        var stockNumeroLieu = liste.find((lieu) => { return lieu.id_lieu == this.infosTransferts.idLieuDepart })
        return stockNumeroLieu.quantite_stockage
      } else return "-"
    }
  },
  watch: {

  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-transfert {
  max-height: 70vh;
  top: 100px;
  overflow: auto;
  // background-color: $fond-page;
  border-radius: 10px;
  padding: 24px 23px 23px 24px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
}
.separateur {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-lieu-depart {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-lieu-depart {
  width: 178px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  margin-right: 26px;
}
.select-lieu {
  width: 150px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  padding: 3px;
}
.label-exemplaires-concernes {
  @include typo-medium;
  color: $couleur-texte-fonce;
}

.frame-ajouter-numero{
  display: flex;
  justify-content: space-around;
  width: 600px;
}

.select-numero {
  // width: 250px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  padding: 3px;
}

.stock-numero-lieu{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border: 1px solid black;
  border-radius: 3px;
  min-width: 30px;
}

.input-quantite-choisie{
  width: 50px;
}

.bouton-ajouter-numero {
  //  border: 1px solid black;
  //  border-radius: 15px;
   margin-left: 5px;
  // width: 35px;
}

.liste-numeros {
  margin-top: 20px;
  box-shadow: 0 5px 10px 5px $opacity-25;
  border-radius: 10px;

}

.frame-boutons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 100%;
  margin-top: 30px;
}
.frame-transferer {
  @include btnHover($neutral-btn);
  background-color: $neutral-btn;
  margin-right: 44px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  align-self: stretch;
}
.bouton-transferer-button {
  @include typo-medium;
  color: $couleur-texte-blanc;
  text-align: center;
}
.frame-annuler {
  @include btnHover($danger-btn);
  background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  align-self: stretch;
}

</style>