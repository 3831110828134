<template>
  <div class="composant-demande-versement">
    <p v-if="!header" class="date-demande">
      {{ dateFrancaise(demandeVersement.date_demande_versement) }}
    </p>
    <p v-else class="date-demande">Date</p>

    <LabelEditable
      v-if="!header"
      align="center"
      class="montant-demande"
      v-model="demandeVersement.montant_facture_contributeur"
      :locked="!user.adminAccessGranted || screenIsPhone"
      @change="enregisterModifications"
    />
    <div v-else align="center" class="montant-demande">Montant</div>

    <LabelEditable
      align="center"
      class="numero-facture-demande"
      v-if="user.adminAccessGranted && !screenIsPhone && !header"
      v-model="demandeVersement.numero_facture_contributeur"
      :locked="!user.adminAccessGranted || screenIsPhone"
      @change="enregisterModifications"
    />
    <div v-else-if="header" align="center" class="numero-facture-demande">N°Facture</div>

    <div class="frame-file-facture" v-if="!screenIsPhone && !header">
      <img
        alt=""
        class="file-text-outline clickable"
        src="assets/icones/file-icone.png"
        @click="afficherFacture"
      />
    </div>
    <div class="frame-file-facture" v-if="header">PDF</div>

    <div v-if="!header" class="frame-icone-paiement infobulle" :bulle-content="contenuBulleInfo">
      <img
        class="statut-paiement clickable"
        alt=""
        :src="iconeStatutPaiement"
        @click="inverserStatutPaiement"
      />
    </div>
    <div class="frame-icone-paiement" v-else>Payée</div>

    <div v-if="!header" class="frame-icone-supprimer">
      <img
        v-if="user.adminAccessGranted && !screenIsPhone"
        class="croix-rouge clickable"
        alt=""
        src="assets/icones/iconeSupprimer.png"
        @click="supprimerFacture"
      />
    </div>
    <div v-else class="frame-icone-supprimer"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "ComposantDemandeVersement",
  props: {
    header: { default: false },
    demandeVersement: {
      default: () => ({
        date_demande_versement: "--/--/----",
        facture_demande_versement: "",
      }),
    },
  },
  data: () => {
    return {
      demandeVersementRegle: false,
    };
  },
  computed: {
    ...mapGetters(["dateFrancaise"]),
    ...mapState(["imgUnchecked", "imgChecked", "user", "screenIsPhone"]),
    contenuBulleInfo() {
      if (this.user.adminAccessGranted)
        return this.demandeVersementRegle
          ? "Cliquez pour marquer cette demande comme non payée"
          : "Cliquez pour marquer cette demande comme payée";
      else
        return this.demandeVersementRegle
          ? "Cette demande vous a été réglé - consulter la liste des versements pour plus d'informations"
          : "Cette demande ne vous a pas encore été réglée";
    },
    iconeStatutPaiement() {
      return this.demandeVersementRegle ? this.imgChecked : this.imgUnchecked;
    },
  },
  methods: {
    ...mapActions(["modaleOuiNon", "phpPost"]),
    enregisterModifications() {
      this.phpPost({
        phpFile: "./assets/php/modifierNumeroFactureDemandeVersement.php",
        sendObject: this.demandeVersement,
        dispatchAtTheEnd: "miseAJourContributeurs",
        // callBack: (response) => {
        //   if (!response.reqSuccess) this.listeVersementsPrivate = JSON.parse(JSON.stringify(this.listeVersements))
        //   else this.$emit('modale-event', { message: 'versement modifié' })
        // }
      });
    },

    declarerVersement() {
      this.modaleOuiNon({
        message:
          "Valider cette demande ajoutera un versement du montant indiqué au contributeur et ses droits seront mis à jour.<br>Êtes vous sûr.e de vouloir continuer ?",
        captionYes: "Valider",
        captionNo: "Annuler",
        // keepOpen: true,
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/ajouterReglementDemandeVersement.php",
            sendObject: {
              dateVersement: this.dateVersement,
              idCompteDroits:
                this.demandeVersement.idCompteDroits | this.demandeVersement.id_compte_droits,
              versementRegle: this.demandeVersementRegle,
              ...this.demandeVersement,
            },
            dispatchAtTheEnd: "miseAJourContributeurs",
            callBack: (response) => {
              if (response.reqSuccess) this.demandeVersementRegle = true;
              this.$emit("update-list");
            },
          });
        },
      });
    },
    annulerVersement() {
      this.modaleOuiNon({
        message:
          "Vous êtes sur le point de supprimer le versement associé à cette demande. Cette action mettra les droits d'auteur à jour dans la fiche auteur<br>Êtes-vous sûr.e de vouloir continuer ?",
        captionYes: "Continuer",
        captionNo: "Annuler",
        // keepOpen: true,
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/supprimerReglementDemandeVersement.php",
            sendObject: {
              id_demande_versement: this.demandeVersement.id_demande_versement,
              idCompteDroits:
                this.demandeVersement.idCompteDroits | this.demandeVersement.id_compte_droits,
              ...this.demandeVersement,
            },
            dispatchAtTheEnd: "miseAJourContributeurs",
            callBack: (response) => {
              if (response.reqSuccess) this.demandeVersementRegle = false;
              this.$emit("update-list");
              // this.updateList();
            },
          });
        },
        // callBackNo: () =>{
        //   this.pageAAfficher = VISUALISATION_LISTE
        // }
      });
    },
    inverserStatutPaiement() {
      if (!this.user.adminAccessGranted) return;
      if (!this.demandeVersementRegle) {
        this.declarerVersement();
      } else {
        this.annulerVersement();
      }
    },
    afficherFacture() {
      window.open("https://" + this.demandeVersement.fichier_facture_contributeur, "_blank");
    },
    supprimerFacture() {
      this.modaleOuiNon({
        message: `<h2>⚠️</h2><h4>Cette option ne doit être utilisée qu'en cas de problème / erreur de facture.</h6><br>
           Êtes-vous sûr de vouloir supprimer la demande de versement ? Conséquences de cette opération : <br><br>
          <div style="width: 100%; text-align: left;margin-left: 20px">- La facture sera définitivement supprimée.</div>
          <div style="width: 100%; text-align: left;margin-left: 20px">- Le versement associé (s'il existe) sera définitivement supprimé.</div>
          <div style="width: 100%; text-align: left;margin-left: 20px">- Le reste à verser à l'auteur sera recalculé</div>`,
        captionYes: "Supprimer",
        captionNo: "Annuler",
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/supprimerFactureContributeur.php",
            sendObject: { demandeVersement: this.demandeVersement },
            dispatchAtTheEnd: "miseAJourContributeurs",
            callBack: (response) => {
              this.$emit("update-list");
            },
          });
        },
      });
    },
  },
  mounted: function() {
    this.demandeVersementRegle = this.demandeVersement.id_versement != undefined;
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
@import "src/assets/css/listeVersements.scss";

.clickable {
  cursor: pointer;
}

.composant-demande-versement {
  background-color: $fond-page;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-demande {
  width: $largeur-date;
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-medium;
}

.montant-demande {
  width: $largeur-montant;
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-medium;
}

.numero-facture-demande {
  width: $largeur-numero-facture;
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-medium;
}

.frame-file-facture {
  text-align: center;
  width: $largeur-icone-facture;
}

.file-text-outline {
  height: 30px;
}

.frame-icone-paiement {
  display: flex;
  justify-content: center;
  width: $largeur-statut-paiement;
}

.croix-rouge {
  height: 30px;
}

.statut-paiement {
  height: 30px;
}

.frame-icone-paiement {
  width: 50px;
}

.frame-icone-supprimer {
  text-align: center;
  width: $largeur-icone-supprimer;
}
</style>
