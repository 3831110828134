<template>
  <div class="composant-numero-transfert">
    <img 
      class="element-clickable bouton-supprimer"
      src="assets/icones/iconeSupprimer.png" 
      alt="supprimer"
      title="Supprimer transfert"
      @click="$emit('supprimerTransfert', indexN)"
    >
      <!-- @click="supprimerLieu" -->
    <div class="infos-numero">
      <p class="titre-numero">{{ titreNumero }}</p>
      <LabelEditable 
        class="quantite-transfert" 
        v-model="quantitePrivate" 
        title="Doublie clic pour modifier"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComposantNumeroTransfert",
  model: {
      prop: 'quantite',
      event: 'update'
  },
  computed: {
      quantitePrivate: {
          get(){
              return this.quantite
          },
          set(value){
              this.$emit('update', value)
          }
      }
  },
  props: {
    indexN: {
        type: Number,
        default: -1
    },
    quantite: {
        type: String, 
        default: "0"
    },
    titreNumero: {
      type: String,
      default: "44 - Biennale de la danse"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-numero-transfert {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infos-numero {
  display: flex;
  align-items: center;
}
.titre-numero {
  width: 300px;
  @include typo-small-medium;
  color: $couleur-texte-fonce;
  margin-right: 11px;
}
.quantite-transfert {
  cursor: pointer;
  width: 50px;
  @include typo-small-medium;
  color: $couleur-texte-fonce;
}
.bouton-supprimer{
    margin-right: 10px;
    height: 30px;
    padding: 3px;
}
</style>