<template>
  <div class="composant-reinitialiser-pass">
    <p class="titre">Réinitialisation de mot de passe</p>
    <p class="label-identifiant">
      Pour réinitialiser le mot de passe, merci de saisir le
      mail associé à l'identifiant saisi
    </p>
    <div class="separateur1"></div>
    <div class="frame-password">
      <p class="label-email">Email :</p>
      <input
        class="e-mail"
        placeholder="email@example.com"
        v-model='email'
      />
    </div>
    <div class="separateur1"></div>
    <div class="frame-connexion btn btn-primary" @click="reinitPassword">
      <p>
        Réinitialiser
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
  name: "ComposantReinitPass",
  data: ()=> {
      return { 
          email: ''
      }
  },
  props:{
      identifiant: { default: undefined },
  },
  methods: {
    ...mapActions(['phpPost', 'modaleOuiNon', 'modaleAlert']),
    ...mapMutations(['definirVisibiliteModale', 'setAuthorization', 'masquerModale', 'afficherModale', 'definirContenuModale']),
      reinitPassword() {
          if (this.email == '') return this.modaleAlert('Veuillez renseigner le mail associé au compte')
          this.modaleAlert({
            message: "Patientez...",
            displayLoader: true
          })
          this.phpPost({
            phpFile: 'assets/php/reInitPassword.php',
            sendObject: { 
                identifiant : this.identifiant ,
                email: this.email
            },
            disableThumbnail: true,
            callBack: (response)=> {
              this.masquerModale()
              if (response.reqSuccess) {
                this.modaleAlert({
                  message: "Mot de passe réinitialisé - un mail vous a été envoyé avec votre nouveau mot de passe", 
                  closeAllAfter: true
                })
              }
            }
          })
      }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-reinitialiser-pass {
  background-color: $fond-page;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.label-identifiant {
  color: $couleur-texte-fonce;
  align-self: stretch;
  text-align: center;
  margin-bottom: 20px;
  @include typo-medium;
}
.separateur1 {
  width: 400px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-password {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-email {
  width: 82px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}
.e-mail {
  flex: 1;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-connexion {
  @include typo-medium;
  @include btnHover($neutral-btn);
  background-color: $neutral-btn;
  border-radius: 9px;
  padding: 22px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
}

</style>