<template>
  <div v-if="listeVersements.length != 0" class="composant-liste-versements">
    <p class="titre">Liste des versement</p>
    <div class="separateur1"></div>
    <div class="frame-titres-liste-versements">
      <p class="date">Date</p>
      <p v-if="user.adminAccessGranted && !screenIsPhone" class="montants">
        N°Facture
      </p>
      <p class="montants" v-if="!screenIsPhone">Precompte</p>
      <p class="montants">Montant</p>
      <p class="pdf" v-if="!screenIsPhone">pdf</p>
      <div
        v-if="user.adminAccessGranted && !screenIsPhone"
        class="frame-remplissage"
      ></div>
    </div>
    <div class="separateur1"></div>

    <div
      v-for="(versement, index) in listeVersementsPrivate"
      :key="versement.id_versement"
      class="frame-frame-versement"
    >
      <div class="frame-versement">
        <LabelEditable
          :locked="!user.adminAccessGranted || screenIsPhone"
          class="label-editable date"
          :saveOnBlur="false"
          v-model="listeVersementsPrivate[index].date_versement"
          type="date"
          format="date"
          v-on:change="enregistrerModifications(index)"
        />
        <LabelEditable
          v-if="user.adminAccessGranted && !screenIsPhone"
          :locked="!user.adminAccessGranted || screenIsPhone"
          class="label-editable montants"
          :saveOnBlur="false"
          v-model="listeVersementsPrivate[index].numero_facture_contributeur"
          v-on:change="enregistrerModifications(index)"
        />
        <LabelEditable
          :locked="!user.adminAccessGranted || screenIsPhone"
          class="label-editable montants"
          :saveOnBlur="false"
          v-model="listeVersementsPrivate[index].precompte_versement"
          v-on:change="enregistrerModifications(index)"
          v-if="!screenIsPhone"
        />
        <LabelEditable
          :locked="!user.adminAccessGranted || screenIsPhone"
          class="label-editable montants"
          :saveOnBlur="false"
          v-model="listeVersementsPrivate[index].montant_versement"
          v-on:change="enregistrerModifications(index)"
        />
        <div class="pdf element-clickable" v-if="!screenIsPhone">
          <img
            alt="Facture"
            class=""
            src="assets/icones/file-icone.png"
            @click="afficherFacture(index)"
          />
        </div>
        <img
          v-if="user.adminAccessGranted && !screenIsPhone"
          class=" element-clickable"
          alt="supprimer"
          src="assets/uploads/imgs/remove.png"
          @click="supprimerVersement(index)"
        />
      </div>
      <div class="separateur1"></div>
    </div>
    <div v-if="user.adminAccessGranted && !screenIsPhone" class="consignes">
      (Clic pour modifier - ❌ pour supprimer)
    </div>
  </div>

  <div v-else class="aucun-versement">Aucun versement enregistré</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const ACTIONS_TO_MAP = ["phpPost", "modaleOuiNon"];

const STATES_TO_MAP = ["user", "screenIsPhone"];

export default {
  name: "composantListeVersement",
  props: {
    listeVersements: {
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      listeVersementsPrivate: [],
    };
  },
  computed: {
    ...mapState(STATES_TO_MAP),
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    supprimerVersement(index) {
      this.modaleOuiNon({
        message:
          "Êtes vous sûr de vouloir supprimer ce versement ? (Les droits du contributeur seront recalculés)",
        callBackYes: () => {
          // Si oui
          this.phpPost({
            phpFile: "./assets/php/supprimerVersement.php",
            dispatchAtTheEnd: "miseAJourContributeurs",
            sendObject: {
              idVersement: this.listeVersements[index].id_versement,
              idCompteDroits: this.listeVersements[index].id_compte_droits,
              idFactureContributeur: this.listeVersements[index]
                .id_facture_contributeur,
            },
            callBack: (response) => {
              // Permet de forcer la mise à jour à l'écran car la prop passée dans 'is' n'est pas réactive
              if (response.reqSuccess)
                this.$emit("modale-event", {
                  message: "liste versement modifiée",
                });
            },
          });
        },
      });
    },
    enregistrerModifications(index) {
      this.phpPost({
        phpFile: "./assets/php/modifierVersement.php",
        sendObject: this.listeVersementsPrivate[index],
        dispatchAtTheEnd: "miseAJourContributeurs",
        callBack: (response) => {
          if (!response.reqSuccess)
            this.listeVersementsPrivate = JSON.parse(
              JSON.stringify(this.listeVersements)
            );
          else this.$emit("modale-event", { message: "versement modifié" });
        },
      });
      //     this.$emit(
      //         'modale-event',
      //         {
      //             event: 'versement-modifie',
      //             newContent: this.listeVersementsPrivate[index]
      //         }
      //     )
    },
    afficherFacture(index) {
      window.open(
        "https://" +
          this.listeVersementsPrivate[index].fichier_facture_contributeur,
        "_blank"
      );
    },
  },
  watch: {
    listeVersements() {
      this.listeVersementsPrivate = JSON.parse(
        JSON.stringify(this.listeVersements)
      );
    },
  },
  mounted: function() {
    this.listeVersementsPrivate = JSON.parse(
      JSON.stringify(this.listeVersements)
    );
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-liste-versements {
  background-color: $fond-page;
  border-radius: 28px;
  padding: 20px 19px 19px 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    padding: 2px;
    width: 100%;
    height: 50vh;
    overflow: auto;
    // bottom: 0;
  }
}

.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  @include typo-large;
  margin-bottom: 20px;
}
.frame-titres-liste-versements {
  background-color: $fond-page;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
$largeur-date: 160px;
$largeur-montant: 100px;
$largeur-pdf: 30px;

$largeur-date-mobile: 40%;
$largeur-montant-mobile: 40%;
$largeur-pdf-mobile: 20%;

.date {
  width: $largeur-date;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @media (max-width: $mobile-break-point) {
    width: $largeur-date-mobile !important;
  }
}

.montants {
  width: $largeur-montant;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @media (max-width: $mobile-break-point) {
    width: $largeur-montant-mobile !important;
  }
}

.pdf {
  width: $largeur-pdf;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @media (max-width: $mobile-break-point) {
    width: $largeur-pdf-mobile !important;
  }
  & img {
    cursor: pointer;
    height: 25px;
  }
}

.frame-remplissage {
  width: 35px;
  height: 15px;
}
.separateur1 {
  width: 521px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 10px;
  @media (max-width: $mobile-break-point) {
    width: 90%;
  }
}

.frame-frame-versement {
  width: 100%;
}

.frame-versement {
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition-property: background-color;
  transition-duration: 300ms;
}
img {
  cursor: pointer;
  height: 35px;
}
.aucun-versement {
  padding: 10px 40px;
  color: $couleur-texte-fonce;
  @include typo-large;
}
.consignes {
  color: $couleur-texte-fonce;
  @include typo_small;
  margin-top: 30px;
}
.label-editable {
  // cursor: pointer;
  justify-content: center;
  &.date {
    width: $largeur-date !important;
    margin-right: 10px;
  }

  &.montants {
    width: $largeur-montant !important;
    margin-right: 10px;
  }

  &.pdf {
    width: $largeur-pdf !important;
    margin-right: 10px;
  }
  @media (max-width: $mobile-break-point) {
    &.date {
      width: $largeur-date-mobile !important;
      margin-right: 10px;
    }

    &.montants {
      width: $largeur-montant-mobile !important;
      margin-right: 10px;
    }

    &.pdf {
      width: $largeur-pdf-mobile !important;
      margin-right: 10px;
    }
  }
}
</style>
