<template>
  <div class="composant-invitation-nouveau-membre">
    <p class="titre">Envoyer une invitation</p>
    <div class="separateur1"></div>
    <div class="frame-email">
      <p class="label-identifiant">Email</p>
      <input v-model="infosInvitation.email" type="text" class="input-infos" />
    </div>
    <div class="frame-email">
      <p class="label-identifiant">Accreditation</p>
      <select name="" id="" class="input-infos" v-model="infosInvitation.acces">
        <option value="--">Choisir un niveau d'acces</option>
        <option
          v-for="acces in listeAcces"
          :key="acces.id_acces"
          :value="acces.id_acces"
          >{{ acces.libelle_acces }}</option
        >
      </select>
    </div>
    <div class="separateur1"></div>
    <div class="btn btn-primary" @click="envoyerInvitation">
      <p class="label-envoyer">Envoyer Invitation</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ComposantInvitationNouveauMembre",
  data: () => {
    return {
      infosInvitation: {
        email: "",
        acces: "--",
      },
      listeAcces: [],
    };
  },
  computed: {
    emailValidation() {
      return this.infosInvitation.email.match(
        /^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})/i
      );
    },
  },
  methods: {
    ...mapActions(["phpPost", "modaleAlert"]),
    ...mapMutations(["masquerModale"]),
    envoyerInvitation() {
      if (!this.emailValidation)
        return this.modaleAlert("Vous devez entrer un email valide");

      if (this.infosInvitation.acces == "--")
        return this.modaleAlert(
          "Vous devez choisir un niveau d'accréditation avant de pouvoir envoyer l'invitation"
        );
      this.modaleAlert({
        message: "Patientez...",
        displayLoader: true,
      });
      this.phpPost({
        phpFile: "assets/php/envoyerMailInvitation.php",
        sendObject: this.infosInvitation,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.masquerModale({ ignoreAllWaitingWindows: true });
          }
        },
      });
    },
  },
  mounted: function() {
    this.phpPost({
      phpFile: "assets/php/listeAcces.php",
      disableThumbnail: true,
      callBack: (response) => {
        if (response.reqSuccess) this.listeAcces = response.reqList;
      },
    });
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-invitation-nouveau-membre {
  background-color: $fond-page;
  overflow: hidden;
  border-radius: 10px;
  padding: 23.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 20px;
  @include typo-large;
}
.separateur1 {
  width: 450px;
  height: 1px;
  background-color: $silver;
  margin-bottom: 20px;
}
.frame-email {
  background-color: $fond-page;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.label-identifiant {
  width: 163px;
  color: $couleur-texte-fonce;
  margin-right: 26.5px;
  @include typo-medium;
}
.input-infos {
  width: 300px;
  color: $couleur-texte-fonce;
  @include typo-medium;
}
.frame-envoyer {
  background-color: $neutral-btn;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 118px;
  cursor: pointer;
}
.label-envoyer {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
</style>
