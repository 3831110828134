<template>
  <div class="informations-lieux">
    <div class="titre-gestion-lieux">Stock total : {{ stockGlobal }}</div>
    <div class="labels-infos">
      <p class="label-info-lieu">Lieu</p>
      <p class="label-info-remplissage">Remplissage</p>
      <p class="label-info-stock">Stock</p>
      <p class="label-info-fiche">Fiche</p>
      <p class="label-info-options"></p>
    </div>
    <div class="ligne-separation-horizontale"></div>
    <!-- <transition-group name="list" tag="p"> -->
    <ComposantLieu
      v-for="(lieu, index) in listeLieux"
      :key="index"
      class="composant-lieu list-item"
      :lieu="lieu"
      :index="index"
      v-on:click="afficherItem"
    />
    <!-- </transition-group> -->
    <div class="frame-boutons-ajouter">
      <div
        v-if="user.adminAccessGranted"
        class="frame-bouton frame-ajouter-lieu element-clickable"
        @click="ajouterLieu"
      >
        <img
          class="imageAjouter element-clickable"
          src="assets/icones/add.png"
          alt="ajouter lieu"
          title="Ajouter un lieu"
        />
        <div class="label-bouton-ajouter">Ajouter lieu</div>
      </div>
      <div
        class="frame-bouton frame-ajouter-transfert element-clickable"
        @click="transfererNumeros"
      >
        <img
          class="imageAjouter element-clickable"
          src="assets/icones/transfert.png"
          alt="ajouter transfert"
          title="Déclarer un transfert"
        />
        <div class="label-bouton-ajouter">Transferts</div>
      </div>
    </div>
  </div>
</template>

<script>
import ComposantLieu from "./composantLieu";

import { mapActions, mapMutations, mapState } from "vuex";
import { consoleMessage } from "../js/consoleMessage";

const STATES_TO_MAP = ["listeNumeros", "listeLieux", "user"];

const ACTIONS_TO_MAP = ["phpPost"];

const MUTATIONS_TO_MAP = ["definirVisibiliteModale", "definirContenuModale"];

export default {
  name: "pageGestionLieux",
  components: { ComposantLieu },
  computed: {
    ...mapState(STATES_TO_MAP),
    stockGlobal() {
      let total = 0;
      for (let numero of this.listeNumeros)
        if (!numero.estUnPack) {
          let quantite =
            numero.liste_comptages[numero.liste_comptages.length - 1]
              .quantite_comptage;
          if (!isNaN(parseInt(quantite))) total += parseInt(quantite);
          else consoleMessage({ quantite });
        }
      total = total.toString();
      let totalMisEnForme = "";
      for (let i = total.length - 1; i >= 0; i--)
        totalMisEnForme =
          ((total.length - i) % 3 == 0 ? " " : "") + total[i] + totalMisEnForme;
      return totalMisEnForme;
    },
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    transfererNumeros() {
      this.definirContenuModale({
        // componentName: 'ComposantAjoutTransfert',
        componentName: "ComposantListeTransfert",
        allowQuitOnClick: true,
      });
      this.definirVisibiliteModale(true);
    },
    ajouterLieu() {
      this.phpPost({
        phpFile: "assets/php/ajouterLieu.php",
        dispatchAtTheEnd: "miseAJourLieux",
      });
    },
    afficherItem(item) {
      if (item.type == "fiche") {
        this.$router.push({
          path: "/ficheLieu/" + item.indexLieu,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.informations-lieux {
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 10px;
  background-color: #edf2f7;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
  width: 900px;
}
.titre-gestion-lieux {
  @include typo-titre;
  color: $couleur-texte-fonce;
  margin-bottom: 30px;
}
.labels-infos {
  margin-bottom: 21px;
  padding: 0 34px;
  display: flex;
  align-items: center;
  //  justify-content: space-between;
  align-self: stretch;
  width: 100%;
}
.label-info-lieu {
  width: 410px;
  @include typo-large;
  color: $couleur-texte-fonce;
}
.label-info-remplissage {
  width: 170px;
  @include typo-large;
  color: $couleur-texte-fonce;
}
.label-info-stock {
  width: 72px;
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
}
.label-info-fiche {
  width: 70px;
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
}
.label-info-options {
  width: 40px;
  @include typo-large;
  color: $couleur-texte-fonce;
  text-align: center;
}
.ligne-separation-horizontale {
  width: 100%;
  height: 1px;
  background-color: $couleur-conteneur-barre-progression;
  margin-bottom: 21px;
}
.composant-lieu {
  // width: 100%;
  padding: 0 34px;
  transition: all 0.4s;
}

.frame-boutons-ajouter {
  margin-top: 50px;
  // width: 250px;
  display: flex;
  justify-content: space-between;
}

.frame-bouton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}

.imageAjouter {
  height: 50px;
}

.label-bouton-ajouter {
  @include typo-medium;
}

.list-item {
  transition: all 0.4s;
}

// .list-leave-active {
// position: absolute;
// }

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
}
</style>
