<template>
  <div v-if='user.adminAccessGranted' class="page-modif-contributeur">
    <div class="frame-titre">
      <p class="label-titre">Modifier contributeur</p>
    </div>
    <div class="frame-contributeur-a-modifier" style="width: 100%;">
      <div class="label-contributeur-a-modifier">Contributeur à modifier : </div>
      <select v-model="idContributeur" @change="definirContributeurAAfficher">
        <option value="-">Choisir un contributeur</option>
        <option 
          v-for='contributeur of listeContributeurs' 
          :key="contributeur.id_contributeur" 
          :value="contributeur.id_contributeur"
        >{{  contributeur.nom_contributeur + ' ' + contributeur.prenom_contributeur }}</option>
      </select>
    </div>
    <div v-if="idContributeur!='-'" class="frame-infos">
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contributeur</p>
          <div class="frame-nom">
            <p class="label-two">Nom*</p>
            <input v-model="contributeurAAfficher.nom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Prenom*</p>
            <input v-model="contributeurAAfficher.prenom_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Nom d'artiste</p>
            <input v-model="contributeurAAfficher.nom_artiste_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Date naissance</p>
            <input type=date v-model="contributeurAAfficher.date_naissance_contributeur"
              class="nom-contributeur date-naissance"
            />
          </div>
          <div class="frame-nom">
            <p class="label-two">Numero Secu</p>
            <input v-model="contributeurAAfficher.numero_secu_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-siret">
            <p class="label-two">Siret</p>
            <input v-model="contributeurAAfficher.siret_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label">Adresse</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Libellé</p>
            <input v-model="contributeurAAfficher.adresse_contributeur.libelle_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Infos comp.</p>
            <input v-model="contributeurAAfficher.adresse_contributeur.infos_complementaires_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Code postal</p>
            <input v-model="contributeurAAfficher.adresse_contributeur.code_postal_adresse"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Ville</p>
            <input v-model="contributeurAAfficher.adresse_contributeur.ville_adresse"
              class="nom-contributeur"
            />
          </div>
        </div>
      </div>
      <div class="frame-partie1">
        <div class="frame-denomination">
          <p class="label">Contacts</p>
          <div class="frame-libelle-adresse">
            <p class="label-two">Site web</p>
            <input v-model="contributeurAAfficher.site_web_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Email</p>
            <input v-model="contributeurAAfficher.email_contributeur"
              class="nom-contributeur"
            />
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Tel</p>
            <input v-model="contributeurAAfficher.tel_contributeur"
              class="nom-contributeur"
            />
          </div>
        </div>
        <div class="frame-denomination">
          <p class="label"> {{ existenceCompteAuteur ?  "Compte auteur" : "Création compte auteur" }}</p>
          <div class="frame-libelle-adresse">
            <div class="frame-label-identifiant">
              <p class="label-three">
                Identifiant connexion*
              </p>
            </div>
            <div class='frame-input-identifiant'>
              <input 
                  @keyup="checkId"
                  @blur="checkId"
                  v-model="nouvelIdentifiant"
                  class="identifiant-contributeur"
                  :class="existenceCompteAuteur ? '': 'ignore-detection'"
                  autocomplete="off"
              />
              <p class="validation infobulle" bulle-content="Disponibilité de l'identifiant">{{ emojiDisponibiliteId }}</p>
              <p class="auto-fill-id infobulle" bulle-content="Remplir l'identifiant avec le nom saisi" @click="remplirIdAvecNom"> ✏️ </p>
            </div>
          </div>
          <div class="frame-creation-password" v-if="!existenceCompteAuteur">
            <div class="frame-libelle-adresse">
              <p class="label-two">Création de mot de passe</p>
              <div class="frame-reinitialiser">
                Automatique
                  <!-- <select 
                      class="frame-methode-creation-password" 
                      name="passwordCreationMethod" 
                      id="passwordCreationMethod"
                      v-model="methodeCreationPassword"
                  >
                      <option value="auto">Automatique</option>
                      <option value="manual">Saisie manuelle</option>
                  </select> -->
                <!-- <p class="label-two">Automatique</p> -->
              </div>
            </div>
            <div v-if="methodeCreationPassword == 'manual'" class="frame-libelle-adresse">
              <p class="label-two">Mot de passe*</p>
              <div class="frame-reinitialiser">
                  <input 
                  type="password"
                      v-model="password"
                      class="password-contributeur"
                      autocomplete="off"
                  />
              <p class="label-three validation infobulle" :bulle-content="infosPassword">{{ checkPassword }}</p>
              <p class="infobulle" :bulle-content="messageValidationPassword">❓</p>
              </div>
            </div>
            <div v-if="!emailValidation" class="frame-libelle-adresse">
              <p class="label-two" v-html="messageMailAbsent"></p>
            </div>
            <div v-else-if="methodeCreationPassword == 'auto'" class="frame-libelle-adresse">
              <p class="label-two">L'utilisateur recevra un mot de passe aléatoire sur son adresse mail</p>
            </div>
            <div v-else class="frame-libelle-adresse">
              <p class="label-two">L'utilisateur recevra le mot de passe sur son adresse mail</p>
            </div>
          </div>
          <div v-else class="frame-libelle-adresse">
            <p class="label-two">Mot de passe</p>
            <div class="frame-reinitialiser element-clickable" @click="reinitPassword">
              <p class="label-two">Réinitialiser</p>
            </div>
          </div>
          <div class="frame-libelle-adresse">
            <p class="label-two">Accreditation*</p>
            <select name="accreditation-contributeur" v-model="contributeurAAfficher.id_acces" @change="marquerModificationPresente">
              <option value="-">Niveau d'acces</option>
              <option v-for="acces in listeAcces" :key="acces.id" :value="acces.id_acces">{{ acces.libelle_acces }} </option>
            </select>
          </div>
          <div 
            v-if="!existenceCompteAuteur" 
            @click="creerCompte" 
            class="bouton-creation-compte" 
            :class="modificationPresente ? 'disabled infobulle':''"
            bulle-content="Les modifications doivent être enregistrées avant de pouvoir créer un compte auteur"
          >
            Créer compte
          </div>
        </div>
      </div>
      <div class="frame-boutons" v-if="modificationPresente">
        <div class="frame-modifier" :class="modificationPresente ? '':'disabled'" @click="modifierContributeur">
          <p class="bouton-label">Modifier</p>
        </div>
        <div class="frame-annuler" :class="modificationPresente ? '':'disabled'" @click="resetPage">
          <p class="bouton-label">Annuler</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="message-interdiction"><h3>403 - Non autorisé</h3></div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
export default {
    name: "composantModifContributeur",
    data:() => {
        return {
            idContributeur: "-",
            contributeurAAfficher: undefined,
            contributeurAvantModification: undefined,
            listeAcces: [],
            existenceCompteAuteur: false,
            idFree: false,
            contributeurVide: {
                nom_contributeur: '',
                prenom_contributeur: '',
                date_naissance_contributeur: '',
                email_contributeur: '',
                tel_contributeur: '',
                adresse_contributeur: {
                    ville_adresse: '',
                    code_postal_adresse: '',
                    libelle_adresse: '',
                    infos_complementaires_adresse: '',
                },
                identifiant: '',
                id_acces: "-"
            },
            nouvelIdentifiant: "",
            password:"",
            emojiDisponibiliteId: "❌",
            methodeCreationPassword: "auto",
            messageValidationPassword: "Le mot de passe doit contenir au moins une majuscule | une minuscule | un chiffre | un caractère spécial (!@#$&*)",
        }
    },
    computed: {
        ...mapState(['user', 'userConnected', 'listeContributeurs', 'modificationPresente']),
        ...mapGetters(['trouveContributeurParId']),
        nomCompletContributeur() {
          return this.contributeurAAfficher.nom_contributeur + ' ' + this.contributeurAAfficher.prenom_contributeur
        },
        messageMailAbsent() {
          if (this.methodeCreationPassword == 'manual') return "⚠️ Un email valide est conseillé pour la transmission du mot de passe. Si aucun email n'est renseigné, vous devrez envoyer vous-même le mot de passe choisi au contributeur"
          else return "⚠️ Un Email valide est requis pour la transmission du mot de passe"
        },
        infosPassword() {
          return this.passwordValidation ? 
          "Mot de passe suffisamment robuste"
          : this.messageValidationPassword
        },
        checkPassword() {
          return this.passwordValidation ? "✔️" : "❌"
        },
        emailValidation() {
            return this.contributeurAAfficher.email_contributeur.match(/^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4}$|^$)/i);
        },
        idValidation() {
          return this.nouvelIdentifiant.match(/\w{3,}/) 
                 && !this.nouvelIdentifiant.match(/^$/)
                 && this.nouvelIdentifiant.match(/^[A-Za-z0-9\-éè]+$/)
        },
        passwordValidation() {
          return this.password.match(/^(?=.*[A-Z])(?=.*[!@#$*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/)
        }
    },
    methods: {
      ...mapActions(['phpPost', 'modaleAlert','modaleOuiNon', 'initDetectionModification']),
      ...mapMutations(['masquerModale', 'resetModificationPresente', 'marquerModificationPresente']),
      resetPage() {
        this.contributeurAAfficher = JSON.parse(JSON.stringify(this.contributeurAvantModification))
        this.nouvelIdentifiant = this.contributeurAvantModification.identifiant
        this.resetModificationPresente()
      },
      definirContributeurAAfficher() {
        if (this.idContributeur != '-') { 
          this.contributeurAAfficher = Object.assign({}, this.trouveContributeurParId(this.idContributeur))
          this.phpPost({
            phpFile: "assets/php/obtenirIdentifiant.php",
            sendObject: { id_contributeur: this.idContributeur },
            disableThumbnail: true,
            callBack: (response) => {
              if (response.reqSuccess) this.contributeurAAfficher.identifiant = response.identifiant
              else this.contributeurAAfficher.identifiant = ""
              this.nouvelIdentifiant = this.contributeurAAfficher.identifiant
              this.existenceCompteAuteur = this.contributeurAAfficher.identifiant != ""
              this.contributeurAvantModification.identifiant = this.contributeurAAfficher.identifiant
              this.checkId()
              this.$forceUpdate()
            }
          })
        } else {
          this.contributeurAAfficher = this.contributeurVide
          this.nouvelIdentifiant = ""
        }
        if (this.contributeurAAfficher.adresse_contributeur == undefined) this.contributeurAAfficher.adresse_contributeur = this.contributeurVide.adresse_contributeur
        this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeurAAfficher))
        this.$nextTick(() => {
          this.initDetectionModification()
        })
      },
      remplirIdAvecNom() {
        this.nouvelIdentifiant = this.contributeurAAfficher.nom_contributeur.toUpperCase().noAccent()
        this.checkId()
      },
      modifierContributeur() {
        if (this.contributeurAAfficher.nom_contributeur == '' || this.contributeurAAfficher.prenom_contributeur == '') {
          this.modaleAlert("Erreur - les noms et prénoms doivent être remplis")
          return false
        }
        if ((!this.idValidation || !this.idFree) && this.existenceCompteAuteur) {
          this.modaleAlert("Erreur - l'identifiant est invalide")
          return false
        }

        if (this.existenceCompteAuteur) this.contributeurAAfficher.identifiant_pass = this.nouvelIdentifiant
        else this.contributeurAAfficher.identifiant_pass = "";
        
        if (this.contributeurAAfficher.id_acces == '-') { 
          this.modaleAlert("Vous devez choisir un niveau d'accréditation pour le contributeur")
          return false
        }
        this.contributeurAAfficher.nom_contributeur = this.contributeurAAfficher.nom_contributeur.toUpperCase()
        if (this.methodeCreationPassword == 'auto') this.contributeurAAfficher.password = "auto"
        else this.contributeurAAfficher.password = this.password
        this.modaleAlert({
          message: 'Patientez...', 
          displayLoader: true
        })
        this.phpPost({
            phpFile: "assets/php/modifierContributeur.php",
            sendObject: this.contributeurAAfficher,
            dispatchAtTheEnd: 'miseAJourContributeurs',
            callBack: (response)=> {
              if (response.reqSuccess) {
                this.resetModificationPresente()
                this.afficherBoutons = false;
              }
              this.masquerModale()
            }
        })
        return true
      },
      creerCompte(){
        if (this.modificationPresente) return
        if (!this.emailValidation) {
          return this.modaleAlert("L'adresse mail est incorrecte - une adresse mail valide est nécessaire pour la création du compte (envoi du mot de passe)")
        }
        if (!this.idFree) {
          return this.modaleAlert("Identifiant incorrect ou déjà pris")
        }
        this.modaleOuiNon({
          message: "Création de compte - un mail de confirmation avec un mot de passe temporaire va être envoyé sur l'adresse mail indiquée. Êtes-vous sûr de vouloir continuer ?",
          callBackYes: ()=>{
            this.modaleAlert({
              message: 'Patientez...', 
              displayLoader: true
            })
            this.phpPost({
              phpFile: 'assets/php/ajouterCompteAuteur.php',
              dispatchAtTheEnd: 'miseAJourContributeurs',
              sendObject: { 
                identifiant: this.nouvelIdentifiant,
                password: 'auto',
                id_contributeur: this.idContributeur,
                email_contributeur: this.contributeurAAfficher.email_contributeur
              },
              callBack: () => {
                this.definirContributeurAAfficher()
                this.masquerModale()
              }
            })
          }
        })
      },
      checkId() {
        this.nouvelIdentifiant = this.nouvelIdentifiant.toUpperCase().noAccent()

        if (!this.idValidation) {
          this.idFree = false
          return this.emojiDisponibiliteId = "❌"
        }
        this.emojiDisponibiliteId = "🕒"
        this.nouvelIdentifiant = this.nouvelIdentifiant.trim()
        if (!this.idValidation) {
          this.idFree = false
          this.emojiDisponibiliteId = "❌"
        } else {
          this.phpPost({
            phpFile: "assets/php/verifierIdentifiant.php",
            sendObject: { 
              identifiantActuel: this.contributeurAAfficher.identifiant,
              nouvelIdentifiant: this.nouvelIdentifiant
              },
            disableThumbnailForAll: true,
            callBack: (response) => {
              this.idFree = response.idDisponible
              if (this.idFree) this.emojiDisponibiliteId = "✔️"
              else this.emojiDisponibiliteId = "❌"
            }
          }) 
        }
      },
      reinitPassword() {
        if (!this.emailValidation) return this.modaleAlert('Email non valide - merci de renseigner un email valide (xxxx@xxx.xxx)')
        this.modaleOuiNon({
          message: "Un nouveau mot de passe aléatoire va être envoyé à l'adresse mail indiquée.<br>Êtes vous sûr de vouloir continuer ?",
          captionYes:"Envoyer",
          captionNo:"Annuler",
          callBackYes: ()=>{
            this.phpPost({
              phpFile: 'assets/php/reInitPassword.php',
              displayLoader: "Réinitialisation en cours...",
              sendObject: { 
                identifiant : this.contributeurAAfficher.identifiant,
                email: this.contributeurAAfficher.email_contributeur
              },
              callBack: ()=> {
                this.masquerModale()
              }
            })
          }
        })
      },
    },
    created: function () {
        this.contributeurAAfficher = JSON.parse(JSON.stringify(this.contributeurVide))
        this.contributeurAvantModification = JSON.parse(JSON.stringify(this.contributeurAAfficher))
    },
    mounted: function () {
      this.resetModificationPresente()
      this.phpPost({
        phpFile: "assets/php/listeAcces.php",
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.listeAcces = response.reqList
          }
        }
      })
    }
  };
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.page-modif-contributeur {
  width: 1100px;
  background-color: $couleur-fond-menu-navigation;
  border-radius: 25px;
  padding: 63px 20px 0 20px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $contour-page;
}
.frame-titre {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.label-titre {
  color: $couleur-texte-fonce;
  @include typo-titre;
}
.frame-contributeur-a-modifier {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.label-contributeur-a-modifier {
  color: $couleur-texte-fonce;
  @include typo-large;
  margin-right: 10px;
}

.frame-infos {
  margin-bottom: 58.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.frame-partie1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  &:not(:last-of-type) {
     margin-bottom: 46px;
  }
}
.frame-denomination {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 19px;
  }
}
.label {
  color: $couleur-texte-fonce;
  margin-bottom: 12px;
  @include typo-large;
}
.frame-nom {
  background-color: $couleur-fond-composant-lieu;
  margin-bottom: 12px;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.label-two {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.date-naissance{
    width: 200px;
}

.nom-contributeur {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
}
.frame-siret {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 12px 25px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.frame-libelle-adresse {
  background-color: $couleur-fond-composant-lieu;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 12px;
}
.frame-label-identifiant {
  display: flex;
  align-items: center;
}
.frame-input-identifiant{
  display: flex;
  align-items: center;
}

.label-three {
  color: $couleur-texte-fonce;
  @include typo-small-medium;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.validation{
  margin-left: 10px;
   &:hover {
    cursor: help;
  }
}

.auto-fill-id {
  font-size: 0.9em;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}

.frame-reinitialiser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;
}

.bouton-creation-compte{
  @include btnHover($neutral-btn);
  // margin-left: 7px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  background-color: $neutral-btn;
  color: $fond-page;
  border-radius: 9px;
   &.disabled {
    // pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}

.frame-methode-creation-password {
    width: 200px;
}

.frame-boutons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;
}


.identifiant-contributeur {
  width: 150px;
}

.frame-reinitialiser {
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  border: 1px solid #ccc;
  height: 27px;
}


.frame-modifier {
  @include clickable($neutral-btn);
  background-color: $neutral-btn;
  margin-right: 67.5px;
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  align-self: stretch;
  &.disabled {
    pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}

.bouton-label {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}

.frame-annuler {
  @include clickable($danger-btn);
  background-color: $danger-btn;
  border-radius: 10px;
  padding: 12px 14px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  align-self: stretch;
  &.disabled {
    pointer-events: none;
    background-color: rgb(156, 156, 156);
  }
}



</style>