<template>
  <div>
    <div id="loaderSessionComptage" class="lds-ellipsis centered">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div v-if="!fichierSessionChecked">
      <h2 class="divTextCenter">Recherche de session inventaire en cours...</h2>
      <br />
    </div>
    <div v-if="sessionComptageEnCours" class="corpsDePageSessionComptage">
      <section class="breadcrumb sectionSessionComptage">
        <h1 class="divTextCenter">Session d'inventaire</h1>
        <br />
        <div class="divTextCenter titre-large">
          Date de debut d'inventaire : {{ dateDebutSessionComptage }}
        </div>
        <div class="divTextCenter infosSynchroSessionContainer">
          <div class="dflex space-around centered" style="width: 230px">
            <div>{{ texteStatutSynchro }}</div>
          </div>
        </div>

        <div class="zoneBoutonsSessionComptage" v-if="user.adminAccessGranted">
          <div class="btn btn-success" @click="cloturerSession">
            Cloturer la session
          </div>
          <div class="btn btn-danger" @click="annulerSessionComptage">
            Annuler la session
          </div>
        </div>

        <!-- 
                **************************************************************************************
                ******************************* zone filtre // Resume ********************************
                **************************************************************************************
                -->

        <div class="zoneFiltreResume">
          <!-- Résumé Statuts lieux -->
          <div class="sectionZoneFiltreResume">
            <div class="labelZoneFiltreResume">Statut par lieu :</div>
            <div
              v-for="(lieu, indexL) of listeLieux"
              :key="indexL"
              class="ligneZoneFiltreResume resumeStatutLieux"
            >
              <div class="nomLieuStatutInventaire">
                - {{ lieu.nom_lieu }}
                <!-- ({{ abreviationLieu(indexL) }}) -->
              </div>
              <img
                class="imgStatutLieu"
                :src="imageStatut(statutVerrouillageLieux(indexL))"
                alt="clock"
              />
              <div class="labelStatutInventaireLieu">
                Comptage
                {{ labelStatutInventaire(statutVerrouillageLieux(indexL)) }}
              </div>
            </div>
          </div>
          <div class="separateurZoneFiltreResume"></div>
          <!-- Filtre statut
                    <div class="sectionZoneFiltreResume">
                        <div class="labelZoneFiltreResume">Inventaires à afficher :</div>
                        <div class = "ligneZoneFiltreResume">
                            <input type="radio" name="checkboxStatut" id="checkboxStatutEnCoursEtTermine" value="enCoursEtTermine" v-model="filtreStatutInventaire" v-on:change="definirFiltresAffichage">
                            <label for="checkboxStatutEnCoursEtTermine">Inventaires terminés et non terminés</label>
                            <br>
                            <input type="radio" name="checkboxStatut" id="checkboxStatutTermine" value="termine" v-model="filtreStatutInventaire" v-on:change="definirFiltresAffichage">
                            <label for="checkboxStatutTermine">Uniquement les inventaires terminés</label>
                            <br>
                            <input type="radio" name="checkboxStatut" id="checkboxStatutEnCours" value="enCours" v-model="filtreStatutInventaire" v-on:change="definirFiltresAffichage">
                            <label for="checkboxStatutEnCours">Uniquement les inventaires non terminés</label>
                        </div>
                    </div> -->
          <!-- <div class="separateurZoneFiltreResume"></div> -->
          <!-- Filtre lieux -->
          <div class="sectionZoneFiltreResume">
            <div class="labelZoneFiltreResume">Lieux à afficher :</div>
            <div class="ligneZoneFiltreResume">
              <div
                class="resumeLieuxSelectionnes"
                v-for="(lieu, indexL) of listeLieux"
                :key="indexL"
              >
                <input
                  type="checkbox"
                  :name="'checkboxLieu-' + indexL"
                  :id="'checkboxLieu-' + indexL"
                  checked
                  @change="definirFiltresAffichageSimple"
                  v-model="afficherLieu[indexL]"
                />
                <label
                  :for="'checkboxLieu-' + indexL"
                  class="nomLieuStatutInventaire"
                  >{{ lieu.nom_lieu }}
                </label>
                <div
                  v-if="user.adminAccessGranted"
                  class="btn btn-primary"
                  @click="autoFill(indexL)"
                >
                  📝 Autofill
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <!-- 
                **************************************************************************************
                ******************************* Affichage des numéros ********************************
                **************************************************************************************
                -->

        <div id="zoneBoutonsVerrouillageGlobal">
          <div class="btn btn-primary btn-sm" @click="toutVerrouiller()">
            Tout fermer
          </div>
          <div class="btn btn-primary btn-sm" @click="toutDeverrouiller()">
            Tout ouvrir
          </div>
          <select class="bshadow" name="page" id="page" v-model="pageAAfficher">
            <option
              v-for="option in optionsPageAAfficher"
              :key="option"
              :value="option"
              >Page {{ option }} (Numéro
              {{ (option - 1) * nombreParPage + 1 }} -
              {{ option * nombreParPage }})</option
            >
          </select>
        </div>

        <!---------------------------- Affichage des numéros ------------------------------------>
        <div
          v-for="(numero, indexN) of listeNumerosPage(pageAAfficher)"
          :key="indexN"
        >
          <div v-if="ligneCorrespondAuxFiltres(numero.indexGlobal)">
            <div class="ligneSessionComptage global ligneVerrouillee dashed">
              <!---------------------------- Titre et statuts ------------------------------------>
              <div class="dflex">
                <div
                  class="sousLigne dflex titre-numero-container"
                  @click="changeVerouillageNumero(numero.indexGlobal)"
                >
                  <img
                    class="imgCadenasLigne infobulle"
                    bulle-content="Cliquer pour ouvrir la ligne"
                    :src="
                      imgIconeFold(listeVerrouillageNumeros[numero.indexGlobal])
                    "
                    alt="cadenas"
                  />
                  <div
                    class="titreNumero"
                    :class="
                      classeTitreNumero(
                        listeVerrouillageNumeros[numero.indexGlobal]
                      )
                    "
                  >
                    {{ numero.numero_numero }} - {{ numero.titre_numero }}
                  </div>
                </div>
                <div class="sousLigne dflex statut-comptage-numero">
                  <img
                    class="imgStatutLigne"
                    :src="
                      imageStatut(
                        statutVerrouillageLieuxSelectionnesLigne(
                          numero.indexGlobal
                        )
                      )
                    "
                  />
                  <div class="labelSessionComptage">
                    {{
                      labelStatutInventaireLieuxSelectionne(
                        statutVerrouillageLieuxSelectionnesLigne(
                          numero.indexGlobal
                        )
                      )
                    }}
                  </div>
                  <!-- bulle-content="Statut inventaire du numéro dans le (ou les) lieu(x) sélectionné(s)" -->
                </div>
                <div
                  class="sousLigne dflex infobulle"
                  bulle-content="Total des quantités saisies sur tous les lieux"
                >
                  <img
                    src="assets/uploads/imgs/info.png"
                    alt="infos"
                    class="imgStatutLigne"
                  />
                  <div class="labelSessionComptage">Stock Total</div>
                  <span class="stockTotal">{{
                    stockTotal(numero.indexGlobal)
                  }}</span>
                </div>
              </div>

              <!------------------------- Infos sur le numero (quand ligne déverrouillée)  ---------------------------->
              <div
                v-if="!listeVerrouillageNumeros[numero.indexGlobal]"
                class="ligneSessionComptage saisie-stock dflex"
              >
                <!------------------------------------ Zone de saisie des stocks --------------------------------------->
                <div class="partieGauche">
                  <div
                    v-for="(label, sousLigne) of listeElementsACompter(
                      numero.indexGlobal
                    )"
                    :key="sousLigne"
                    class="sousLigne dflex"
                  >
                    <div v-if="sousLigne == 0" class="dflex">
                      <!-- Zones vides (deux premieres colonnes de la première ligne) -->
                      <div class="labelUniteComptage"></div>
                      <div class="inputComptage"></div>
                      <!-- Nom des lieux -->
                      <div v-for="(lieu, indexL) of listeLieux" :key="indexL">
                        <div
                          v-if="afficherLieu[indexL] == true"
                          class="divTextCenter inputComptage"
                        >
                          {{ abreviationLieu(indexL) }}
                        </div>
                      </div>
                    </div>
                    <div v-else-if="sousLigne == 1" class="dflex">
                      <!-- Zones vides (deux premieres colonnes de la deuxième ligne) -->
                      <div class="labelUniteComptage"></div>
                      <div class="inputComptage"></div>
                      <!-- Etat du verrouillage des lieux -->
                      <div v-for="(lieu, indexL) of listeLieux" :key="indexL">
                        <div
                          class="inputComptage infobulle"
                          v-if="afficherLieu[indexL] == true"
                          bulle-content="Cadenas verrouillé = comptage validé"
                        >
                          <img
                            :src="
                              imageCadenasVerrouillage(
                                listeVerrouillageLieuxParNumero[
                                  numero.indexGlobal
                                ][indexL]
                              )
                            "
                            class="imgCadenasLieux"
                            @click="
                              changeVerrouillageLieuParNumero(
                                numero.indexGlobal,
                                indexL
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else class="dflex">
                      <!-- Zone de saisie - les deux première colonnes sont :
                                                1) le type d'éléments comptés (cartons, ramettes, etc...)
                                                2) la quantité par type d'élément (150 n° par carton, etc...) -->
                      <div class="labelUniteComptage">{{ label }}</div>
                      <input
                        v-if="
                          sousLigne == 2 &&
                            typeNumero(numero.indexGlobal) ==
                              TYPE_NUMERO_STANDARD
                        "
                        type="text"
                        class="inputComptage"
                        v-model="contenanceCarton[numero.indexGlobal]"
                        disabled
                      />
                      <input
                        v-else-if="
                          sousLigne == 3 &&
                            typeNumero(numero.indexGlobal) ==
                              TYPE_NUMERO_STANDARD
                        "
                        type="text"
                        class="inputComptage"
                        v-model="contenanceRamette"
                        disabled
                      />
                      <div v-else class="inputComptage"></div>
                      <span v-for="(lieu, indexL) of listeLieux" :key="indexL">
                        <!-- inputs de saisie de stock-->
                        <input
                          v-if="afficherLieu[indexL] == true"
                          :ref="
                            'ref-' +
                              numero.indexGlobal +
                              '-' +
                              indexL +
                              '-' +
                              (sousLigne - 2)
                          "
                          @focus="
                            inputFocus(
                              'ref-' +
                                numero.indexGlobal +
                                '-' +
                                indexL +
                                '-' +
                                (sousLigne - 2)
                            )
                          "
                          @blur="
                            inputBlur(
                              'ref-' +
                                numero.indexGlobal +
                                '-' +
                                indexL +
                                '-' +
                                (sousLigne - 2)
                            )
                          "
                          type="text"
                          class="inputComptage"
                          :disabled="
                            verrouillageInput(numero.indexGlobal, indexL) ||
                              sousLigne == 5
                          "
                          v-model="
                            listeComptages[numero.indexGlobal][indexL][
                              sousLigne - 2
                            ]
                          "
                          v-on:keyup="
                            tracerChangement(
                              numero.indexGlobal,
                              indexL,
                              sousLigne - 2
                            )
                          "
                          :id="
                            'input-n' +
                              numero.indexGlobal +
                              '-l' +
                              indexL +
                              '-sl' +
                              sousLigne
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ********************************** Affichage ligne verrouillée ***********************************-->
          </div>
        </div>
        <div class="pied-de-page">
          <div
            v-for="page in optionsPageAAfficher"
            :key="page"
            class="pages"
            :class="page == pageAAfficher ? 'page-courante' : ''"
            @click="definirPageAAfficher(page)"
          >
            Page {{ page }}
          </div>
        </div>
      </section>
      <ComposantVignette
        class="thumbnail"
        :thumbnailType="synchroReussie ? 'success' : 'error'"
        :thumbnailText="texteStatutSynchro"
      />
    </div>
    <!-- ********************************** Page si aucun comptage n'est en cours ***********************************-->
    <div
      v-if="
        !sessionComptageEnCours && fichierSessionChecked && !erreurDeConnexion
      "
      class="corpsDePageSessionComptage"
    >
      <h2 class="divTextCenter">Aucune session inventaire en cours</h2>
      <br />
      <div class="divTextCenter" v-if="user.adminAccessGranted">
        <div class="btn btn-primary centered" @click="creerSessionComptage">
          <div class="titre-large">Démarrer une session d'inventaire</div>
        </div>
      </div>
      <div v-else class="divTextCenter">
        Si une session a été créée récemment, vous pouvez y accéder en
        rafraichissant la page
      </div>
    </div>
    <div
      v-if="
        !sessionComptageEnCours && fichierSessionChecked && erreurDeConnexion
      "
      class="corpsDePageSessionComptage"
    >
      <h2 class="divTextCenter">
        Erreur de connexion - vérifiez que vous êtes bien connecté à internet
      </h2>
      <br />
    </div>
  </div>
</template>

<script>
import { getToday, getHour } from "./../js/fonctionsGlobales";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import ComposantVignette from "./composantVignette";

const STATES_TO_MAP = [
  "imgSuccessConst",
  "listeNumerosPrete",
  "listeLieux",
  "listeLieuxPrete",
  "imgVerrouillageConst",
  "imgHorloge",
  "imgLivre",
  "imgLivreOuvert",
  "user",
  "userConnected",
];

const ACTIONS_TO_MAP = ["phpPost", "modaleAlert", "modaleOuiNon"];

const MUTATIONS_TO_MAP = [
  "definirVisibiliteModale",
  "definirContenuModale",
  "masquerModale",
];

const GETTERS_TO_MAP = ["listeNumerosTriee", "formatDateNomDuMois"];

export default {
  name: "pageSessionComptage",
  template: "#pageSessionComptageTemplate",
  components: { ComposantVignette },
  data: () => {
    return {
      TYPE_NUMERO_STANDARD: "STANDARD",
      TYPE_NUMERO_COFFRET: "COFFRET",
      TYPE_NUMERO_HS: "HS",
      pageAAfficher: 1,
      nombreParPage: 20,
      nombreLignesAAfficher: 0,
      listeNumeros: [],
      listeVerrouillageNumeros: [],
      listeChangementsVerrouillageNumeros: [],
      listeVerrouillageLieuxParNumero: [],
      listeChangementsVerrouillageLieuxParNumero: [],
      protectedInput: false,
      listeComptages: [],
      listeChangementsInputs: [],
      changementsEnregistres: true,
      afficherInventairesEnCours: true,
      afficherInventairesTermines: true,
      afficherInventaireEnCoursEtTermines: true,
      filtreStatutInventaire: "enCoursEtTermine",
      sessionComptageEnCours: false,
      afficherLieu: [],
      contenanceCarton: [],
      contenanceRamette: 50,
      INDEX_CARTONS: 0,
      INDEX_RAMETTES: 1,
      INDEX_LIBRES: 2,
      INDEX_TOTAL: 3,
      INDEX_COFFRETS_VIDES: 0,
      INDEX_COFFRETS_PLEINS: 1,
      INDEX_NUMERO_HS: 0,
      dateDebutSessionComptage: getToday(),
      intervalHandler: undefined,
      blocageSynchro: false,
      synchroReussie: true,
      heureDerniereSynchro: getHour(),
      heureDerniereSynchroEnMs: new Date().getTime(),
      verrouilleTout: false,
      deverrouilleTout: false,
      filtreModifie: false,
      fichierSessionChecked: false,
      erreurDeConnexion: false,
      debug: undefined,
    };
  },
  methods: {
    ...mapActions(ACTIONS_TO_MAP),
    ...mapMutations(MUTATIONS_TO_MAP),
    typeNumero(indexN) {
      if (this.listeNumeros[indexN].estUnPack) return this.TYPE_NUMERO_COFFRET;
      let typeNumero = this.TYPE_NUMERO_STANDARD;
      let numero = this.listeNumeros[indexN].numero_numero;
      if (isNaN(numero)) {
        // if (numero[0] == "C") typeNumero = this.TYPE_NUMERO_COFFRET
        // else
        if (numero[0] == "H") typeNumero = this.TYPE_NUMERO_HS;
      }
      return typeNumero;
    },
    listeElementsACompter(indexN) {
      let typeNumero = this.typeNumero(indexN);
      if (typeNumero == this.TYPE_NUMERO_STANDARD)
        return ["", "", "cartons :", "ramettes :", "libres :", "Stock lieu"];
      else if (typeNumero == this.TYPE_NUMERO_COFFRET)
        return ["", "", "Coffrets vides", "Coffrets pleins"];
      else if (typeNumero == this.TYPE_NUMERO_HS)
        return ["", "", "Numeros libres"];

      return [];
    },
    autoFill(indexL) {
      this.modaleOuiNon({
        message:
          "Les stocks pour le lieu " +
          this.listeLieux[indexL].nom_lieu +
          " vont être remplis avec les derniers stocks connus. Êtes-vous sûr de vouloir continuer ?",
        callBackYes: () => {
          let lieu = this.listeLieux[indexL];
          this.listeNumerosTriee().forEach((numero, indexN) => {
            let dernierComptage = numero.liste_derniers_stockages.filter(
              (stockage) => {
                return stockage.id_lieu == lieu.id_lieu;
              }
            );
            if (dernierComptage.length > 0) {
              dernierComptage = dernierComptage[0].quantite_stockage;
            } else {
              dernierComptage = 0;
            }
            this.listeComptages[indexN][indexL][
              this.INDEX_TOTAL
            ] = dernierComptage;
            this.listeChangementsInputs.push([
              indexN,
              indexL,
              this.INDEX_TOTAL,
            ]);
            this.listeVerrouillageLieuxParNumero[indexN][indexL] = true;
            this.listeChangementsVerrouillageLieuxParNumero.push([
              indexN,
              indexL,
            ]);
          });
        },
      });
    },
    definirPageAAfficher(page) {
      this.pageAAfficher = page;
      window.scroll(0, 750);
    },
    classeTitreNumero(folded) {
      return folded ? "small" : "big";
    },
    imgIconeFold(folded) {
      return folded ? this.imgLivre : this.imgLivreOuvert;
    },
    abreviationLieu(indexL) {
      var nom = this.listeLieux[indexL].nom_lieu;
      return nom;
      // return nom[0] + nom[1] + nom[2]
    },
    listeNumerosPage(page) {
      // selectionne les numéros de la page demandée - chaque page possédant 'nombreParPage' numeros
      let listePage = this.listeNumerosTriee();
      for (var i in listePage) listePage[i].indexGlobal = i;

      return listePage.filter((numero, index) => {
        let borneInf = this.nombreParPage * (page - 1);
        let borneSup = this.nombreParPage * page;
        return index >= borneInf && index < borneSup;
      });
    },
    miseEnPlaceSynchroAuto() {
      // mise en place de la synchro automatique
      this.desactiverSynchroAuto();
      this.intervalHandler = setInterval(() => {
        if (!this.blocageSynchro) {
          this.enregistrerDataSession();
        }
      }, 2000);
    },
    bloquerSynchro(etat) {
      this.blocageSynchro = etat;
      if (!this.blocageSynchro) this.enregistrerDataSession();
    },
    infosInput(ref) {
      let infos = ref.split("-");
      infos.shift();
      return infos;
    },
    definirProtection(ref) {
      if (ref === false) return (this.protectedInput = false);
      let infos = this.infosInput(ref);
      this.protectedInput = [infos[0], infos[1], infos[2]];
    },
    inputFocus(ref) {
      this.$refs[ref][0].select();
      this.definirProtection(ref);
    },
    inputBlur(ref) {
      this.definirProtection(false);
    },
    desactiverSynchroAuto() {
      if (this.intervalHandler != undefined)
        clearInterval(this.intervalHandler);
    },
    creerFichierSession() {
      let dataToSend = this.dataSessionMisEnForme();
      this.phpPost({
        phpFile: "assets/php/creerSessionComptage.php",
        sendObject: dataToSend,
        callBack: ((response) => {
          if (response.reqSuccess) {
            this.sessionComptageEnCours = true; // déclenche le rendu dans vueJS
            this.miseEnPlaceSynchroAuto();
            this.enregistrerDataSession();
            this.definirVisibiliteModale(false);
          } else if (response.sessionAlreadyExists) {
            this.masquerModale();
            this.modaleAlert({
              message:
                "Un fichier session est déjà présent, vous pouvez accéder à la session en cours en rechargeant la page.",
            });
          } else this.definirVisibiliteModale(false);
        }).bind(this),
      });
    },
    creerSessionComptage() {
      this.modaleOuiNon({
        message:
          "Êtes-vous sûr.e.s de vouloir créer la session ?<br>Assurez-vous que tous les numéros récemment édités ont bien été saisis - il ne sera pas possible de le faire tant que la session sera en cours.",
        callBackYes: () => {
          this.blocageSynchro = false;
          this.computeData();
          this.modaleAlert({
            message: "Création de la session inventaire",
            displayLoader: true,
          });
          this.creerFichierSession();
        },
      });
    },
    inventaireTermine() {
      var verifierTousLesLieux = true;
      for (let indexLieu = 0; indexLieu < this.listeLieux.length; indexLieu++) {
        verifierTousLesLieux =
          verifierTousLesLieux && this.statutVerrouillageLieux(indexLieu);
      }
      return verifierTousLesLieux;
    },
    cloturerSession() {
      this.blocageSynchro = true; // désactivation de la mise à jour automatique

      if (!this.inventaireTermine())
        this.modaleAlert(
          "L'inventaire n'est pas terminé ! La clôture ne sera possible que lorsque tous les cadenas de tous les numéros et de tous les lieux seront verrouillés..."
        );
      else
        this.modaleOuiNon({
          message: `<h4>Êtes-vous sûr de vouloir clôturer la session ?</h4><br>
                                Conséquences de cette action : <br>
                                Enregistrement des inventaires de tous les numéros<br>
                                Recalcul du nombre de ventes<br>
                                Mise à jour des droits de tous les contributeurs`,
          captionYes: "Clôturer",
          captionNo: "Annuler",
          callBackYes: () => {
            // si confirmation de clôture
            this.modaleAlert({
              message:
                "Clôture en cours, cette opération peut prendre un certain temps...",
              displayLoader: true,
            });
            var inventaire = [];
            this.listeComptages.forEach((comptage, indexNumero) => {
              var detailsComptagesLieux = [];
              var nbreCoffretsPleins = 0;
              var nbreCoffretsVides = 0;
              comptage.forEach((detailsLieux, indexLieu) => {
                var detailsLieu = {
                  idLieu: this.listeLieux[indexLieu].id_lieu,
                  quantite: detailsLieux[this.INDEX_TOTAL],
                };
                if (this.typeNumero(indexNumero) == this.TYPE_NUMERO_COFFRET) {
                  detailsLieu.nbreCoffretsPleins = parseInt(
                    detailsLieux[this.INDEX_COFFRETS_PLEINS]
                  );
                  detailsLieu.nbreCoffretsVides = parseInt(
                    detailsLieux[this.INDEX_COFFRETS_VIDES]
                  );
                }
                detailsComptagesLieux.push(detailsLieu);
              });

              var detailComptagesNumero = {
                id_numero: this.listeNumeros[indexNumero].id_numero,
                numero_numero: this.listeNumeros[indexNumero].numero_numero,
                titreNumero: this.listeNumeros[indexNumero].titre_numero,
                estUnPack: this.listeNumeros[indexNumero].estUnPack,
                id_pack: this.listeNumeros[indexNumero].id_pack,
                stockages: detailsComptagesLieux,
                quantite_comptage: this.stockTotal(indexNumero),
              };

              inventaire.push(detailComptagesNumero);
            });

            this.phpPost({
              phpFile: "assets/php/cloturerSessionComptage.php",
              sendObject: { inventaire: inventaire },
              dispatchAtTheEnd: ["miseAJourNumeros", "miseAJourContributeurs"],
              autoHideThumbnail: false,
              callBack: (response) => {
                this.masquerModale();
                if (response.reqSuccess) this.initPage();
              },
            });
          },
          callBackNo: () => {
            // si annulation de clôture
            this.blocageSynchro = false; // réactivation de la mise à jour automatique
          },
        });
    },
    annulerSessionComptage() {
      this.blocageSynchro = true;
      this.modaleOuiNon({
        message:
          "Etes-vous sûr de vouloir annuler cette session d'inventaire ?<br>(Tous les inventaires saisis seront perdus)",
        callBackYes: () => {
          // Si confirmation d'annulation
          this.desactiverSynchroAuto();
          this.phpPost({
            phpFile: "assets/php/annulerSessionComptage.php",
            autoHideThumbnail: false,
            callBack: (response) => {
              if (!response.reqSuccess) {
                this.blocageSynchro = false;
                this.miseEnPlaceSynchroAuto();
              } else {
                this.desactiverSynchroAuto();
                this.initPage();
              }
            },
          });
        },
        callBackNo: () => {
          // si pas de confirmation d'annulation
          this.blocageSynchro = false;
        },
      });
    },
    arrayCompare(a, b) {
      if (a === []) return false;
      if (a.length != b.length) return false;
      for (var i = 0; i < a.length; i++) {
        if (a[i] != b[i]) return false;
      }
      return true;
    },
    findArrayInArray(arrayToWatch, arrayToFind) {
      return (
        arrayToWatch.find((element) =>
          this.arrayCompare(element, arrayToFind)
        ) != undefined
      );
    },
    enregistrerDataSession() {
      this.changementsEnregistres = false;
      var dataSessionComptage = this.dataSessionMisEnForme();
      this.phpPost({
        phpFile: "assets/php/enregistrerSessionComptage.php",
        sendObject: dataSessionComptage,
        disableThumbnailForAll: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            // si l'opération a réussi
            if (response.dataInventaire != undefined) {
              var data = response.dataInventaire;

              /* Insertion des données sans écraser les changements survenus
                                depuis l'envoi de la requête
                            */

              if (
                this.listeChangementsInputs.length != 0 ||
                this.protectedInput !== false
              ) {
                for (var ligne in data.listeComptages) {
                  for (var indexLieu in data.listeComptages[ligne]) {
                    for (var sousLigne in data.listeComptages[ligne][
                      indexLieu
                    ]) {
                      // verification que l'element [ligne, indexLieu, sousLigne] ne fait pas partie de la liste des changements
                      if (
                        this.listeChangementsInputs.find((element) => {
                          return this.arrayCompare(element, [
                            ligne,
                            indexLieu,
                            sousLigne,
                          ]);
                        }) == undefined &&
                        // et que l'input n'est pas celui en cours d'édition
                        !this.arrayCompare(this.protectedInput, [
                          ligne,
                          indexLieu,
                          sousLigne,
                        ])
                      ) {
                        // alors on insère les données reçues
                        this.listeComptages[ligne][indexLieu][sousLigne] =
                          data.listeComptages[ligne][indexLieu][sousLigne];
                      }
                    }
                  }
                }
                // this.enregistrerDataSession()
              } else {
                this.listeComptages = data.listeComptages;
                this.changementsEnregistres = true;
              }

              if (this.listeChangementsVerrouillageLieuxParNumero.length != 0) {
                for (ligne in data.listeComptages) {
                  for (indexLieu in data.listeComptages[ligne]) {
                    if (
                      this.listeChangementsVerrouillageLieuxParNumero.find(
                        (element) => {
                          return this.arrayCompare(element, [ligne, indexLieu]);
                        }
                      ) == undefined
                    ) {
                      this.listeVerrouillageLieuxParNumero[ligne][indexLieu] =
                        data.listeVerrouillageLieuxParNumero[ligne][indexLieu];
                    }
                  }
                }
                this.enregistrerDataSession();
              } else {
                this.listeVerrouillageLieuxParNumero =
                  data.listeVerrouillageLieuxParNumero;
                this.changementsEnregistres = true;
              }

              //                            this.listeVerrouillageLieuxParNumero = data.listeVerrouillageLieuxParNumero;
              this.heureDerniereSynchro = getHour();
              this.heureDerniereSynchroEnMs = new Date().getTime();
            } else {
              console.warn(
                "Erreur de récupération des données - retour synchronisation null"
              );
            }
          } else {
            // si l'enregistrement n'a pas réussi
            if (response.fichierManquant == true) {
              this.desactiverSynchroAuto();
              this.modaleAlert({
                message:
                  "Erreur critique<br>Le fichier de session est manquant - Si plusieurs utilisateurs sont connectés en même temps, il est possible que l'un d'entre eux ait annulé la session<br>Vous allez être redirigé vers la page d'accueil",
                callBack: () => {
                  this.initPage();
                },
              });
            } else if (response.erreurConfig == true) {
              this.desactiverSynchroAuto();
              this.modaleAlert({
                message:
                  "<h3>" + response.reqType + "</h3><br>" + response.reqMessage,
                callBack: () => {
                  this.initPage();
                },
              });
            } else if (response.clotureEnCours == true) {
              this.desactiverSynchroAuto();
              this.modaleAlert({
                message:
                  "Clôture en cours<br>La session est en cours de clôture - il n'est désormais plus possible d'y accéder",
                callBack: () => {
                  this.initPage();
                },
              });
            }
          }
          this.synchroReussie =
            (new Date().getTime() - this.heureDerniereSynchroEnMs) / 1000 < 6;
        },
      });
    },
    dataSessionMisEnForme() {
      /** Détails de la structure des datas :
       *      - listeComptages[numeroLigne][indexL][indexSousligne] = contenu du champ (indexSousLigne = cartons / ramettes / libres / total)
       *      - listeVerrouillageLieuxParNumeros[numeroLigne][indexL] = statut du verrouillage (cadenas) true si verrouillé
       *      - listeChangementsInput : array de [numeroLigne][indexL][indexSousligne] = reference des champs modifiés pour que seuls ceux-ci soient poussés dans
       */

      for (let changement of this.listeChangementsInputs) {
        let indexN = changement[0];
        let indexL = changement[1];
        let sousLigne = changement[2];
        let quantite = this.listeComptages[indexN][indexL][sousLigne];
        if (isNaN(quantite) || quantite == "") {
          let comptage = this.listeComptages[indexN][indexL];
          this.listeComptages[indexN][indexL][sousLigne] = 0;
          var total = this.stockNumeroLieu(indexN, indexL);
          // var total = 0
          // if (this.typeNumero(indexN) == this.TYPE_NUMERO_STANDARD) {
          //   total = parseInt(comptage[this.INDEX_CARTONS]) *
          //     this.contenanceCarton[indexN] +
          //   parseInt(comptage[this.INDEX_RAMETTES]) * this.contenanceRamette +
          //   parseInt(comptage[this.INDEX_LIBRES]);
          // }
          if (isNaN(total)) total = 0;
          this.listeComptages[indexN][indexL].splice(
            this.INDEX_TOTAL,
            1,
            total
          ); //utilisation de splice pour la detection par Vue JS
        }
      }
      var output = {
        dataToSave: JSON.stringify({
          listeComptages: this.listeComptages,
          listeVerrouillageNumeros: this.listeVerrouillageNumeros,
          listeVerrouillageLieuxParNumero: this.listeVerrouillageLieuxParNumero,
        }),
        listeChangementsInputs: this.listeChangementsInputs,
        listeChangementsVerrouillageNumeros: this
          .listeChangementsVerrouillageNumeros,
        listeChangementsVerrouillageLieuxParNumero: this
          .listeChangementsVerrouillageLieuxParNumero,
      };
      this.listeChangementsVerrouillageNumeros = [];
      this.listeChangementsVerrouillageLieuxParNumero = [];
      this.listeChangementsInputs = [];
      // if (typeof output.dataToSave != "string") console.warn('Données vides !');
      this.debug = output;
      return output;
    },
    boutonEnregistrerSessionComptage() {
      this.enregistrerDataSession();
    },
    afficherLoader() {
      this.modaleAlert({
        message: "Patientez",
        displayLoader: true,
      });
    },
    toutVerrouiller() {
      this.modaleAlert({
        message: "Patientez",
        displayLoader: true,
      });
      setTimeout(() => {
        this.verrouilleTout = true;
      }, 500);
    },
    toutDeverrouiller() {
      this.modaleAlert({
        message: "Patientez",
        displayLoader: true,
      });
      setTimeout(() => {
        this.deverrouilleTout = true;
      }, 500);
    },
    definirFiltresAffichageSimple() {
      this.afficherInventairesEnCours =
        this.filtreStatutInventaire == "enCours";
      this.afficherInventairesTermines =
        this.filtreStatutInventaire == "termine";
      this.afficherInventaireEnCoursEtTermines =
        this.filtreStatutInventaire == "enCoursEtTermine";
    },
    definirFiltresAffichage() {
      if (this.afficherLieu.reduce((a, v) => a || v, false)) {
        this.filtreModifie = true;
        this.afficherLoader();
        setTimeout(() => {
          this.definirFiltresAffichageSimple();
        }, 500);
      }
    },
    ligneCorrespondAuxFiltres(indexN) {
      // teste si la ligne repond aux critères choisis par l'utilisateur
      if (indexN != undefined) {
        // checkIfLast

        if (this.verrouilleTout) this.listeVerrouillageNumeros[indexN] = true;
        if (this.deverrouilleTout)
          this.listeVerrouillageNumeros[indexN] = false;

        var derniereLigne = this.nombreParPage - 1;

        // Si on est sur la dernière page, on redefini la dernière ligne
        var pageCourante = Math.floor(indexN / this.nombreParPage);
        var dernierePage = Math.floor(
          (this.listeNumerosTriee().length - 1) / this.nombreParPage
        );
        if (pageCourante == dernierePage) {
          derniereLigne =
            (this.listeNumerosTriee().length % this.nombreParPage) - 1;
        }
        // Test de fin de liste : si on est en fin de liste et qu'un des indicateur est true, on masque la modale
        var positionDansLaPage = indexN % this.nombreParPage;
        if (
          (positionDansLaPage == derniereLigne ||
            this.nombreLignesAAfficher == 0) &&
          (this.verrouilleTout || this.deverrouilleTout || this.filtreModifie)
        ) {
          this.filtreModifie = false;
          this.verrouilleTout = false;
          this.deverrouilleTout = false;
          this.definirVisibiliteModale(false);
        } // Fin checkIfLast

        var listeIndexLieuAAfficher = [];

        for (var indexL = 0; indexL < this.afficherLieu.length; indexL++) {
          if (this.afficherLieu[indexL]) listeIndexLieuAAfficher.push(indexL);
        }
        if (listeIndexLieuAAfficher.length == 0) return false;
        var lignePossedeLieuEnCoursAAfficher =
          listeIndexLieuAAfficher == []
            ? false
            : listeIndexLieuAAfficher.reduce((a, v) => {
                return a || !this.listeVerrouillageLieuxParNumero[indexN][v];
              }, false);
        var lignePossedeLieuTermineAAfficher =
          listeIndexLieuAAfficher == []
            ? false
            : listeIndexLieuAAfficher.reduce((a, v) => {
                return a && this.listeVerrouillageLieuxParNumero[indexN][v];
              }, true);

        // if (indexN == undefined) return false;
        if (this.listeVerrouillageLieuxParNumero[indexN] == undefined)
          return true;

        return (
          (this.afficherInventairesEnCours &&
            lignePossedeLieuEnCoursAAfficher) ||
          (this.afficherInventairesTermines &&
            lignePossedeLieuTermineAAfficher) ||
          this.afficherInventaireEnCoursEtTermines
        );
      } else return false;
    },
    statutVerrouillageLieux(indexL) {
      // teste si le lieu est verrouille sur tous les numeros
      return this.listeVerrouillageLieuxParNumero.reduce(
        (a, v) => a && v[indexL],
        true
      );
    },
    labelStatutInventaire(bool) {
      return bool ? " terminé !" : " en cours";
    },
    statutVerrouillageLieuxLigne(indexN) {
      if (this.listeVerrouillageLieuxParNumero[indexN] == undefined)
        return false;
      return this.listeVerrouillageLieuxParNumero[indexN].reduce(
        (a, v) => a && v,
        true
      );
    },
    labelStatutInventaireLieuxSelectionne(bool) {
      // let pluriel = this.afficherLieu.reduce((a, v) => v ? a + 1 : a, 0) > 1
      // let labelLieu = 'lieu'
      // let labelTermine = "terminé"
      // if (pluriel) {
      //     labelLieu += "x"
      //     labelTermine += "s"
      // }
      return "Comptage numéro " + (bool ? "terminé !" : " en cours");
    },
    statutVerrouillageLieuxSelectionnesLigne(indexN) {
      if (
        this.listeVerrouillageLieuxParNumero[indexN] == undefined ||
        this.afficherLieu == undefined
      )
        return false;
      let output = this.listeVerrouillageLieuxParNumero[indexN].reduce(
        (accumulateur, verrouillageLieu, index) => {
          let v = true;
          if (this.afficherLieu[index]) v = verrouillageLieu;
          return accumulateur && v;
        },
        true
      );

      return output;
    },
    imageStatut(bool) {
      return this.imgSuccessConst.get(bool);
    },
    imageCadenasVerrouillage(bool) {
      return this.imgVerrouillageConst.get(bool);
    },
    stockNumeroLieu(indexN, indexL) {
      var comptage = this.listeComptages[indexN][indexL];
      for (var i = 0; i < comptage.length - 1; i++) {
        if (isNaN(comptage[i])) {
          comptage[i] = 0;
        }
      }
      var total = 0;
      if (this.typeNumero(indexN) == this.TYPE_NUMERO_STANDARD) {
        total =
          parseInt(comptage[this.INDEX_CARTONS]) *
            this.contenanceCarton[indexN] +
          parseInt(comptage[this.INDEX_RAMETTES]) * this.contenanceRamette +
          parseInt(comptage[this.INDEX_LIBRES]);
      } else if (this.typeNumero(indexN) == this.TYPE_NUMERO_COFFRET) {
        total =
          parseInt(comptage[this.INDEX_COFFRETS_VIDES]) +
          parseInt(comptage[this.INDEX_COFFRETS_PLEINS]);
      } else if (this.typeNumero(indexN) == this.TYPE_NUMERO_HS) {
        total = parseInt(comptage[this.INDEX_NUMERO_HS]);
      }
      if (isNaN(total)) total = "?";
      return total;
    },
    tracerChangement(indexN, indexL, sousLigne) {
      this.changementsEnregistres = false;
      if (
        // si le changement n'est pas déjà marqué, alors le marquer
        !this.findArrayInArray(this.listeChangementsInputs, [
          indexN,
          indexL,
          sousLigne,
        ])
      ) {
        this.listeChangementsInputs.push([indexN, indexL, sousLigne]);
        this.listeChangementsInputs.push([indexN, indexL, this.INDEX_TOTAL]);
      }

      var total = this.stockNumeroLieu(indexN, indexL);
      this.listeComptages[indexN][indexL].splice(this.INDEX_TOTAL, 1, total); //utilisation de splice pour la detection par Vue JS
    },
    stockTotal(indexN) {
      if (this.listeComptages[indexN] == undefined) return 0;
      return this.listeComptages[indexN].reduce((a, b) => {
        return (
          a + (b[this.INDEX_TOTAL] == "" ? 0 : parseInt(b[this.INDEX_TOTAL]))
        );
      }, 0);
    },
    changeVerrouillageLieu(numeroLieu) {
      // Utilisation de splice pour forcer la détection du changement par Vuejs
      this.listeVerrouillageLieux.splice(
        numeroLieu,
        1,
        !this.listeVerrouillageLieux[numeroLieu]
      );
    },
    changeVerouillageNumero(numeroLigne) {
      // Utilisation de splice pour forcer la détection du changement par Vuejs
      //this.listeChangementsVerrouillageNumeros.push(numeroLigne);
      this.listeVerrouillageNumeros.splice(
        numeroLigne,
        1,
        !this.listeVerrouillageNumeros[numeroLigne]
      );
    },
    changeVerrouillageLieuParNumero(numeroLigne, numeroLieu) {
      this.listeVerrouillageLieuxParNumero[numeroLigne].splice(
        numeroLieu,
        1,
        !this.listeVerrouillageLieuxParNumero[numeroLigne][numeroLieu]
      );
      this.changementsEnregistres = false;
      this.listeChangementsVerrouillageLieuxParNumero.push([
        numeroLigne,
        numeroLieu,
      ]);
      // Utilisation de splice pour forcer la détection du changement par Vuejs
    },
    verrouillageInput(indexN, indexL) {
      //if (this.listeVerrouillageLieux == undefined || this.listeVerrouillageNumeros == undefined || this.listeVerrouillageLieuxParNumero == undefined) return false;
      return this.listeVerrouillageLieuxParNumero[indexN][indexL];
    },
    chercherSauvegardeSessionComptage() {
      this.modaleAlert({
        message: "Recherche inventaire en cours...",
        displayLoader: true,
      });
      this.phpPost({
        phpFile: "assets/php/chargerSessionComptage.php",
        disableThumbnailForAll: true,
        callBack: (dataJSON) => {
          this.definirVisibiliteModale(false);
          if (dataJSON.reqSuccess && dataJSON.data != null) {
            //si un fichier a bien été trouvé
            this.$nextTick(() => {
              this.erreurDeConnexion = false;
              // Verification de la cohérence des données : les listes sont-elles de la même taille ?
              if (
                this.listeComptages.length ==
                  dataJSON.data.listeComptages.length &&
                this.listeVerrouillageNumeros.length ==
                  dataJSON.data.listeVerrouillageNumeros.length &&
                this.listeVerrouillageLieuxParNumero[0].length ==
                  dataJSON.data.listeVerrouillageLieuxParNumero[0].length
              ) {
                // si les données sont cohérentes
                this.sessionComptageEnCours = true;
                this.listeComptages = dataJSON.data.listeComptages;
                this.listeVerrouillageLieuxParNumero =
                  dataJSON.data.listeVerrouillageLieuxParNumero;
                this.dateDebutSessionComptage = dataJSON.dateDebut;
                this.miseEnPlaceSynchroAuto();
              } else {
                // si les données ne sont pas cohérentes
                this.blocageSynchro = true;
                this.desactiverSynchroAuto();
                this.modaleAlert({
                  message: `Erreur - impossible de charger l'inventaire en cours<br>
                                    Il semble que la liste des lieux (ou la liste numéros) ait été modifiée pendant que l'inventaire était en cours.<br>
                                    L'état existant va être supprimé - une copie temporaire va néanmoins être conservée. Contactez votre administrateur si vous avez besoin d'y accéder
                                    `,
                  callBack: () => {
                    this.phpPost({
                      phpFile: "assets/php/supprimerSessionComptage.php",
                      callBack: () => {
                        this.sessionComptageEnCours = false;
                      },
                    });
                  },
                });
              }
            });
          } else {
            // si un probleme de configuration a été détecté
            if (dataJSON.reqType == "Ajax error") this.erreurDeConnexion = true;
            if (dataJSON.erreurConfig)
              this.modaleAlert(
                "<h3>" +
                  dataJSON.reqType +
                  "</h3>" +
                  "<br>" +
                  dataJSON.reqMessage
              );
            // else this.modaleAlert('<h3>' + dataJSON.reqType + '</h3>' + '<br>' + dataJSON.reqMessage)
            this.desactiverSynchroAuto();
          }
          this.fichierSessionChecked = true;
        },
      });
    },
    initialiserData() {
      this.listeNumeros = this.listeNumerosTriee();
      this.nombreLignesAAfficher = this.listeNumeros.length;
      this.sessionComptageEnCours = false;
      this.computeData();
    },
    computeData() {
      this.listeComptages = [];
      this.listeVerrouillageNumeros = [];
      this.listeVerrouillageLieux = [];
      this.listeVerrouillageLieuxParNumero = [];
      // this.listeVerrouillageNumeros.push(false);
      for (var numero in this.listeNumerosTriee()) {
        this.listeVerrouillageNumeros.push(true);
        this.contenanceCarton.push(150);
        var ligne = [];
        var ligneVerrouillages = [];
        for (var lieu in this.listeLieux) {
          if (this.listeVerrouillageLieux.length < this.listeLieux.length)
            this.listeVerrouillageLieux.push(false);
          if (this.afficherLieu.length < this.listeLieux.length)
            this.afficherLieu.push(false);
          ligneVerrouillages.push(false);
          ligne.push([0, 0, 0, 0]); // Les 4 zeros correspondent à "cartons", "ramettes", "libres" et "total"
        }
        this.listeComptages.push(ligne);
        this.listeVerrouillageLieuxParNumero.push(ligneVerrouillages);
      }
      // this.afficherLieu[0] = true
    },
    initPage() {
      if (this.listeNumerosPrete && this.listeLieuxPrete) {
        this.desactiverSynchroAuto();
        this.initialiserData();
        this.chercherSauvegardeSessionComptage();
      }
    },
  },
  computed: {
    ...mapState(STATES_TO_MAP),
    ...mapGetters(GETTERS_TO_MAP),

    optionsPageAAfficher() {
      // definit les options du select de page
      var options = [];
      for (
        var i = 0;
        i <= Math.floor(this.nombreLignesAAfficher / this.nombreParPage);
        i++
      ) {
        options.push(i + 1);
      }
      return options;
    },
    imgStatutSynchro() {
      if (this.synchroReussie && !this.changementsEnregistres) {
        //return this.imgSuccessConst.get(false)
        return this.imgHorloge;
      }
      return this.imgSuccessConst.get(this.synchroReussie);
    },
    texteStatutSynchro() {
      if (this.synchroReussie) {
        if (this.changementsEnregistres) return "✔️ Enregistré";
        else return "✔️ En cours...";
        // 🕒
      } else
        return (
          "❌ Connexion perdue - dernière sauvegarde : " +
          this.heureDerniereSynchro
        );
    },
  },
  beforeRouteLeave(to, from, next) {
    this.desactiverSynchroAuto();
    next();
  },
  watch: {
    listeNumerosPrete() {
      this.initPage();
    },
    listeLieuxPrete() {
      this.initPage();
    },
  },
  mounted: function() {
    this.initPage();
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";

.titre-large {
  font-size: 1.5em;
}

.corpsDePageSessionComptage {
  margin-left: auto;
  margin-right: auto;
}
.corpsDePageSessionComptage section {
  width: 1000px;

  padding-top: 50px;
}

.imgSynchroSessionComptage {
  width: 25px;
}

.zoneBoutonsSessionComptage {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}

.zoneFiltreResume {
  display: flex;
  justify-content: space-around;
  margin-top: 70px;
  margin-bottom: 70px;
}

.sectionZoneFiltreResume {
  margin-left: auto;
  margin-right: auto;
}

// .separateurZoneFiltreResume {
// }

.labelZoneFiltreResume {
  position: relative;
  left: -25px;
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ligneZoneFiltreResume.resumeStatutLieux {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ligneZoneFiltreResume .resumeLieuxSelectionnes {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.nomLieuStatutInventaire {
  width: 200px;
  font-size: 1.2em;
}

.imgStatutLieu {
  position: relative;
  width: 20px;
  height: 20px;
  top: 1px;
}

.labelStatutInventaireLieu {
  margin-left: 5px;
}

#zoneBoutonsVerrouillageGlobal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 400px;
}

.ligneSessionComptage {
  &.global {
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  }
  &.ligneVerrouillee {
    & .sousLigne {
      align-items: center;
    }
    & .imgCadenasLigne {
      cursor: pointer;
      height: 20px;
      margin-left: 5px;
      margin-right: 8px;
    }
    & .imgStatutLigne {
      height: 20px;
      margin-right: 10px;
      margin-left: 20px;
      position: relative;
    }
    & .statut-comptage-numero {
      width: 300px;
    }
    & .titreNumero {
      font-size: 1.1em;
      width: 400px;
      //  &.small {
      //     font-size: 1.3em;
      // }
      // &.big {
      //     font-size: 1.6em;
      // }
    }
    & .labelSessionComptage {
      font-size: 1.1em;
    }
    & .stockTotal {
      font-size: 1em;
      margin-left: 5px;
      width: 50px;
      // margin-right: 100px;
    }
  }
  &.saisie-stock {
    margin-top: 30px;
    // border: 1px dashed #000;
    padding-top: 10px;
    padding-bottom: 10px;
    // border-radius: 15px;
    // box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4)
  }
}

.titre-numero-container {
  cursor: pointer;
}

.ligneSessionComptage .partieDroite {
  flex-direction: column;
  width: 400px;
  //   margin-top: 15px;
  margin-bottom: 10px;
}
.ligneSessionComptage .partieDroite .infosNumero {
  margin-left: 33px;
}
.ligneSessionComptage .partieDroite .sousLigne {
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 20px;
}
.ligneSessionComptage .partieDroite .imgCadenasLigne {
  position: relative;
  cursor: pointer;
  height: 20px;
  top: 5px;
  margin-left: 5px;
  margin-right: 8px;
}
// .ligneSessionComptage .partieDroite .titreNumero {

// }
.ligneSessionComptage .partieDroite .labelSessionComptage {
  font-size: 1.1em;
  margin-bottom: 5px;
}
.ligneSessionComptage .partieDroite .imgStatutLigne {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}
.ligneSessionComptage .partieDroite .stockTotal {
  font-size: 1.2em;
  //   margin-left: 20px;
}

.ligneSessionComptage .partieGauche {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 50px;
  min-width: 600px;
  border: 1px dashed #000;
  border-radius: 15px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
}

.ligneSessionComptage .partieGauche .imgCadenasLieux {
  height: 20px;
  cursor: pointer;
}
.ligneSessionComptage .partieGauche .sousLigne {
  margin-bottom: 5px;
}
.ligneSessionComptage .partieGauche .labelUniteComptage {
  width: 130px;
}
.ligneSessionComptage .partieGauche .inputComptage {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 70px;
  margin-right: 5px;
}

.pied-de-page {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  & .pages {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    padding: 3px;
    &.page-courante {
      border-bottom: 1px solid #000;
    }
  }
}
</style>
