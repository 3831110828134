// festivalAVisualiser
export const UNITE_FESTIVAL_DEPART = 0;
export const UNITE_FESTIVAL_RETOUR = 1;
export const UNITE_FESTIVAL_VIDE = 2;
export const UNITE_FESTIVAL_PLEIN = 4;
export const UNITE_FESTIVAL_PACK = 8;


// Statistiques

// Clés Objets

export const INPUT_PREMIERS_MOIS = "premiersMois"
export const INPUT_DATE_DEPART = "dateDepart"
export const INPUT_DATE_FIN = "dateFin"
export const INPUT_QUANTITE_FILTRE = "quantiteFiltre"
export const INPUT_DUREE_PROJECTION = "dureeProjection"

export const CHECKBOX_AFFICHER_RENTABLES = "checkboxAfficherRentables"
export const CHECKBOX_AFFICHER_NON_RENTABLES = "checkboxAfficherNonRentables"
export const CHECKBOX_MASQUER_VALEURS_NEGATIVES = "checkboxMasquerValeursNegatives"

export const CHECKBOX_AFFICHER_HORS_SERIE = "checkboxAfficherHorsSerie"
export const CHECKBOX_AFFICHER_PACK = "checkboxAfficherPack"



export const LISTE_NUMEROS_A_VISUALISER = "listeNumerosAVisualiser";
export const LISTE_NUMEROS_TRIEE = "listeNumerosTriee";
export const TYPE_VISUALISATION = "typeVisualisation";
export const MODE_VISUALISATION = "modeVisualisation";
export const PERIODE_VISUALISATION = "periodeVisualisation";
export const TYPE_CLASSEMENT = "typeClassement";
export const ORDRE_CLASSEMENT = "ordreClassement";
export const FILTRE_SUPERIEUR_OU_INFERIEUR = "filtreSuperieurOuInferieur"
export const LIEU_A_VISUALISER = "lieuAVisualiser"
export const FESTIVAL_A_VISUALISER = "festivalAVisualiser"


// Valeurs 
export const TYPE_VISUALISATION_STOCK = "TYPE_VISUALISATION_STOCK";
export const TYPE_VISUALISATION_VENTES = "TYPE_VISUALISATION_VENTES";


export const MODE_VISUALISATION_SOLO = "miseEnFormeSoloOuMultiple";
export const MODE_VISUALISATION_MULTIPLE = "miseEnFormeSoloOuMultiple";
export const MODE_VISUALISATION_GLOBAL = "miseEnFormeGlobal";
export const MODE_VISUALISATION_CLASSEMENT = "miseEnFormeClassement";
export const MODE_VISUALISATION_FINANCES = "miseEnFormeBeneficeParNumero";
export const MODE_VISUALISATION_FESTIVAL = "miseEnFormeFestival";
export const MODE_VISUALISATION_PROJECTIONS = "miseEnFormeProjections";

export const PERIODE_VISUALISATION_DEFINIE = "PERIODE_VISUALISATION_DEFINIE";
export const PERIODE_VISUALISATION_PREMIERS_MOIS =
  "PERIODE_VISUALISATION_PREMIERS_MOIS";
export const PERIODE_VISUALISATION_ENTIERE = "PERIODE_VISUALISATION_ENTIERE";

export const TYPE_CLASSEMENT_STOCK = "miseEnFormeClassementStock"
export const TYPE_CLASSEMENT_VENTES = "miseEnFormeClassementVentes"
export const TYPE_CLASSEMENT_VITESSE = "miseEnFormeClassementVitesse"
export const TYPE_CLASSEMENT_RUPTURE_DE_STOCK = "miseEnFormeClassementRupture"
export const TYPE_CLASSEMENT_RENTABILITE = "miseEnFormeClassementRentabilite"
// export const TYPE_CLASSEMENT_TEST = "fonctionTest"

export const LISTE_TYPE_CLASSEMENT = [
  TYPE_CLASSEMENT_STOCK,
  TYPE_CLASSEMENT_VENTES,
  TYPE_CLASSEMENT_VITESSE,
  TYPE_CLASSEMENT_RUPTURE_DE_STOCK,
  TYPE_CLASSEMENT_RENTABILITE
  // ,
  // TYPE_CLASSEMENT_TEST
]

export const ORDRE_CROISSANT = "ORDRE_CROISSANT"
export const ORDRE_DECROISSANT = "ORDRE_DECROISSANT"
export const ORDRE_CHRONOLOGIQUE = "ORDRE_CHRONOLOGIQUE"

export const FILTRE_SUPERIEUR = "FILTRE_SUPERIEUR"
export const FILTRE_INFERIEUR = "FILTRE_INFERIEUR"
export const FILTRE_EGALITE = "FILTRE_EGALITE"
export const FILTRE_INDIFFERENT = "FILTRE_INDIFFERENT"

export const TOUS_LIEUX_CONFONDUS = "TOUS_LIEUX_CONFONDUS"
export const AUCUN_FESTIVAL_CHOISI = "AUCUN_FESTIVAL_CHOISI"



export const NO_RECOMPUTE_OPTION_LIST = [
  ORDRE_CLASSEMENT,
  FILTRE_SUPERIEUR_OU_INFERIEUR,
  INPUT_QUANTITE_FILTRE
]



export const DEFAULT_MODE_VISUALISATION = {
  label: "Stock / Ventes par numéro",
  value: MODE_VISUALISATION_SOLO,
};
export const DEFAULT_TYPE_VISUALISATION = {
  label: "Stock",
  value: TYPE_VISUALISATION_STOCK,
};
export const DEFAULT_PERIODE_VISUALISATION = {
  value: PERIODE_VISUALISATION_ENTIERE,
  label: "Entiere",
};
export const DEFAULT_TYPE_CLASSEMENT = {
  label: "Stock",
  value: TYPE_CLASSEMENT_STOCK,
};

export const DEFAULT_LIEU_A_VISUALISER = {
  label: "Tous lieux confondus",
  value: TOUS_LIEUX_CONFONDUS,
};

export const DEFAULT_FESTIVAL_A_VISUALISER = {
  label: "Choisir festival",
  value: AUCUN_FESTIVAL_CHOISI,
};

export const DEFAULT_ORDRE_CLASSEMENT = {
  label: "Ordre croissant",
  value: ORDRE_CROISSANT,
};

export const DEFAULT_FILTRE_SUPERIEUR_OU_INFERIEUR = {
  label: "Indifférent",
  value: FILTRE_INDIFFERENT,
};