<template>
    <div class="barreProgression">
      <div :style="computedStyle" :class="computedClass"/>
    </div>
</template>

<script>
export default {
    name: "ComposantLieu",
    data: ()=> {
        return {
            index: Math.round(Math.random(8) * 100000000).toString(),
        }
    },
  props: {
        preset: {default: "line"},
        pourcentage: {default: 60},
        labelPos : {default: 'right'},
        width: {default: 'auto'},
        height: {default: 'auto'},
        fontSize: {default: '1'},
        size: {default: undefined},
        OKColor: {default: '#8AC926'},
        MiddleColor: {default: '#fccc2b'},
        KOColor: {default: '#F25F5C'},
  },
  computed: {
      computedStyle() {
          var dimensions = this.size == undefined ?
            `width: ${this.width}; 
            height: ${this.height};`
            : `width: ${this.size}; 
            height: ${this.size};`
          return `${dimensions}
                  font-size: ${this.fontSize}em`
      },
      computedClass() {
          if (this.labelPos != 'none') return `ldBar label-${this.labelPos} progressBar progressSpinner${this.index}`
          else return `ldBar no-percent progressBar progressSpinner${this.index}`

      }
  },
  mounted: function () {
    var ldBar = window.ldBar;
    let couleur = this.pourcentage>70 ? this.KOColor : this.pourcentage>40 ? this.MiddleColor : this.OKColor;
    this.myBar = new ldBar(".progressSpinner" + this.index,
      {
        "preset": this.preset,
        "stroke": couleur,
        "stroke-trail": '#2D3748',
        "stroke-width": 20,
        "stroke-trail-width": 22,
        "value": this.pourcentage,
        "transition-in": true,
        "duration": 1,
        "linecap": "round"
      });
  }
}
</script>

<style lang="scss">
@import "src/js/loader/loading-bar.scss";
  .barreProgression{
    // margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .label-right {
        width: 150px;
        margin-right: 30px;
    }
    .ldBar path.mainline {
        stroke-width: 20;
        stroke-linecap: round;
      }
    .ldBar path.baseline {
      stroke-width: 24;
      stroke-linecap: round;
    }
}
  </style>