
export const IMG_CADENAS = 'assets/uploads/imgs/cadenas.png';
export const IMG_CADENAS_OUVERT = 'assets/uploads/imgs/cadenas-ouvert.png';
export const IMG_LIVRE = 'assets/icones/foldedArrow.png';
export const IMG_LIVRE_OUVERT = 'assets/icones/unfoldedArrow.png';
// export const IMG_LIVRE = 'assets/uploads/imgs/livre.png';
// export const IMG_LIVRE_OUVERT = 'assets/uploads/imgs/livre_ouvert.png';


export const IMG_CHECKED = 'assets/icones/boxChecked.png';
export const IMG_UNCHECKED = 'assets/icones/boxUnchecked.png';
export const IMG_HORLOGE = 'assets/uploads/imgs/clock.png';
export const IMG_VALIDE = 'assets/uploads/imgs/success.png';
export const IMG_ERREUR = 'assets/uploads/imgs/wrong.png';
export const COUV_MANQUANTE = "assets/uploads/couvertures/couvmanquante.png";
export const IMG_SELECTIONNER_NUMERO = "assets/uploads/couvertures/selectionnernumero.png";
export const MESSAGE_ALERTE_MODIFICATION = "Les changements sur la page n'ont pas été enregistrés. Êtes-vous sûr de vouloir continuer ?";

export const UNITE_FESTIVAL_DEPART = 0;
export const UNITE_FESTIVAL_RETOUR = 1;


export const IMG_VERROUILLAGE = new Map([
    [true, IMG_CADENAS],
    [false, IMG_CADENAS_OUVERT]
])

export const IMG_STATUT = new Map([
    [true, IMG_VALIDE],
    [false, IMG_ERREUR]
])

export const IMG_SUCCESS = new Map([
    [true, IMG_VALIDE],
    [false, IMG_ERREUR]
])

export const RESET_MODIFICATION_PRESENTE = 'resetModificationPresente'
export const MARQUER_MODIFICATION_PRESENTE = 'marquerModificationPresente'
export const RESET_LISTE_NUMEROS_PRETE = 'resetListeNumerosPrete'
export const RESET_LISTE_LIEUX_PRETE = 'resetListeLieuxPrete'
export const RESET_LISTE_CONTRIBUTEURS_PRETE = 'resetListeContributeursPrete'
export const MISE_A_JOUR_NUMEROS = 'miseAJourNumeros';
export const MISE_A_JOUR_LIEUX = 'miseAJourLieux';
export const MISE_A_JOUR_CONTRIBUTEURS = 'miseAJourContributeurs';
export const DEFINIR_CONTENU_MODALE = 'definirContenuModale'
export const DEFINIR_VISIBILITE_MODALE = 'definirVisibiliteModale'
export const INITIALISATION_DATA = 'initialisation';
export const USER_CONNECTED = 'definirStatutConnexion'

export const PHONE_BREAK_POINT = 1200;