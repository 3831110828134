<template>
  <div class="composant-liste-pilons">
    <p class="titre">Pilons</p>
    <p class="titre">{{ titre }}</p>
    <div class="separateur1"></div>
    <div class="frame-titres-liste-versements">
      <p class="label-date">Date</p>
      <p class="label-quantite">Quantite</p>
      <div class="frame-remplissage"></div>
    </div>
    <ComposantPilon
      v-for="(pilon, index) in listePilons"
      :key="pilon.id_pilon"
      class="composant-pilon"
      :pilon="pilon"
      @update="enregistrerModificationPilon(index)"
      @delete="supprimerPilon(index)"
    />

    <div class="separateur1"></div>
    <div class="action-pilon">
      <div
        class="element-clickable frame-pilon-exemplaire"
        @click="ajouterPilon"
        v-if="user.superAdminAccessGranted && !screenIsPhone"
      >
        <img
          class="icone-pilon-exemplaire"
          src="assets/icones/iconeSupprimer.png"
          alt="pilonner"
        />
        <div>Pilonner des exemplaires</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ComposantListePilons",
  components: {
    ComposantPilon: () => import("./ComposantPilon"),
  },
  props: {
    numero: { default: undefined },
    needUpdate: { default: false },
  },
  data: () => {
    return {
      listePilons: [],
      listePilonsAvantModif: [],
    };
  },
  computed: {
    ...mapState(["user", "listeNumeros", "screenIsPhone"]),
    titre() {
      return this.numero.numero_numero + " - " + this.numero.titre_numero;
    },
  },
  methods: {
    ...mapMutations([
      "afficherModale",
      "masquerModale",
      "definirContenuModale",
      "masquerModale",
    ]),
    ...mapActions(["phpPost", "modaleOuiNon", "modaleAlert"]),
    supprimerPilon(index) {
      this.modaleOuiNon({
        message:
          "Êtes vous sûr.e de vouloir supprimer ce pilon ? <br>Conséquences de cette action : <br>- Les ventes seront mises à jour<br>- Les droits d'auteur seront mis à jour",
        callBackYes: () => {
          this.phpPost({
            phpFile: "assets/php/supprimerPilon.php",
            sendObject: this.listePilons[index],
            dispatchAtTheEnd: "miseAJourNumeros",
            callBack: (response) => {
              if (response.reqSuccess) {
                this.updateListePilons();
              }
            },
          });
        },
      });
    },
    enregistrerModificationPilon(index) {
      this.phpPost({
        phpFile: "assets/php/modifierPilon.php",
        sendObject: this.listePilons[index],
        dispatchAtTheEnd: "miseAJourNumeros",
        callBack: (response) => {
          if (!response.reqSuccess) {
            this.listePilons = JSON.parse(
              JSON.stringify(this.listePilonsAvantModif)
            );
          }
        },
      });
    },
    updateListePilons() {
      this.phpPost({
        phpFile: "assets/php/listePilons.php",
        sendObject: { id_numero: this.numero.id_numero },
        disableThumbnail: true,
        displayLoader: "Récupération de la liste...",
        callBack: (response) => {
          // this.masquerModale()
          if (response.reqSuccess) {
            this.listePilons = response.reqList;
            this.listePilonsAvantModif = JSON.parse(
              JSON.stringify(this.listePilons)
            );
          }
        },
      });
    },
    ajouterPilon() {
      this.definirContenuModale({
        componentName: "ComposantAjoutPilon",
        props: {
          idNumero: this.numero.id_numero,
        },
      });
      this.afficherModale();
    },
  },
  mounted: function() {
    this.updateListePilons();
  },
  watch: {
    listeNumeros() {
      this.updateListePilons();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
@import "src/assets/css/listePilons.scss";

.composant-liste-pilons {
  background-color: $fond-page;
  border-radius: 28px;
  padding: 19.5px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    padding: 5px;
  }
}
.titre {
  color: $couleur-texte-fonce;
  text-align: center;
  margin-bottom: 10px;
  @include typo-large;
}
.separateur1 {
  height: 1px;
  background-color: $silver;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  @media (max-width: $mobile-break-point) {
    width: 80%;
  }
}
.frame-titres-liste-versements {
  background-color: $fond-page;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.label-date {
  width: $largeur-date;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @include typo-medium;
}
.label-quantite {
  width: $largeur-quantite;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 10px;
  @include typo-medium;
}

.frame-remplissage {
  width: $largeur-croix;
  height: 15px;
}
.composant-pilon {
  margin-bottom: 10px;
  align-self: stretch;
}

.frame-ajout-pilon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  height: 30px;
}

.frame-pilon-exemplaire {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  height: 30px;
}

.action-pilon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icone-ajout-pilon {
  height: 100%;
}

.icone-pilon-exemplaire {
  height: 100%;
  padding-right: 0.2em;
}
</style>
