<template>
  <div class="composant-contribution">
    <img
      v-if="!locked"
      @click="$emit('supprimer-contribution', numero_contribution)"
      alt="supprimer"
      class="icone-supprimer element-clickable"
      src="https://static.overlay-tech.com/assets/e12a8b14-2108-4dc9-96e1-81cea10c04cd.png"
    />
    <LabelEditable
      class="nom-complet"
      :class="modeAffichage"
      :locked="true"
      v-model="nomCompletContributeur"
      @click="afficherContributeur(contribution.id_contributeur)"
    />
    <LabelEditable
      class="type-contribution"
      :locked="locked"
      v-model="typeContribution"
    />
    <LabelEditable
      v-if="!screenIsPhone"
      class="mode-contribution"
      :locked="locked"
      v-model="modeContribution"
      format="select"
      :selectList="['pourcentage', 'fixe']"
    />
    <LabelEditable
      class="pourcentage-contribution"
      :locked="locked"
      v-model="montantContribution"
      :key="forceRenderKey"
    />
    <div class="symbole">{{ symboleContribution }}</div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState } from "vuex";

export default {
  name: "ComposantContribution",
  model: {
    prop: "contribution",
    event: "update",
  },
  props: {
    contribution: { default: "" },
    locked: { default: true },
    numero_contribution: { default: -1 },
  },
  data: function() {
    return {
      contributionPrivate: cloneDeep(this.contribution),
      forceRenderKey: 0,
    };
  },
  computed: {
    ...mapState(["screenIsPhone"]),
    modeAffichage() {
      return this.locked ? "visualisation" : "edition";
    },
    symboleContribution() {
      let symbole;
      if (this.contribution.mode_contribution == "pourcentage") symbole = "%";
      else symbole = "€";
      return symbole;
    },
    montantContribution: {
      get() {
        return this.contribution.pourcentage_contribution;
      },
      set(nouveauMontant) {
        if (!isNaN(parseFloat(nouveauMontant))) {
          this.contributionPrivate.pourcentage_contribution = nouveauMontant;
          this.$emit("update", this.contributionPrivate);
        } else {
          this.forceRenderKey++;
        }
      },
    },
    modeContribution: {
      get() {
        return this.contribution.mode_contribution;
      },
      set(nouveauMode) {
        if (nouveauMode == "pourcentage" || nouveauMode == "fixe") {
          this.contributionPrivate.mode_contribution = nouveauMode;
          this.$emit("update", this.contributionPrivate);
        }
      },
    },
    typeContribution: {
      get() {
        return this.contribution.type_contribution;
      },
      set(nouveauType) {
        this.contributionPrivate.type_contribution = nouveauType;
        this.$emit("update", this.contributionPrivate);
      },
    },
    nomCompletContributeur() {
      return this.nomContributeur + " " + this.prenomContributeur;
    },
    nomContributeur() {
      return this.contribution.nom_contributeur;
    },
    prenomContributeur() {
      return this.contribution.prenom_contributeur;
    },
  },
  methods: {
    afficherContributeur(idContributeur) {
      if (this.locked) {
        var routeData = this.$router.resolve("/ficheauteur-" + idContributeur);
        window.open(routeData.href, "_blank");
      }
    },
  },
  mounted() {
    console.log("this.contribution", this.contribution);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-contribution {
  background-color: $fond-page;
  border-radius: 19px;
  padding: 8px 15px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.icone-supprimer {
  margin-right: 8px;
}
.nom-complet {
  width: 231px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 8px;
  &.visualisation {
    cursor: pointer;
  }
  @media (max-width: $mobile-break-point) {
    width: 50%;
  }
}
.type-contribution {
  width: 153px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 8px;
  @media (max-width: $mobile-break-point) {
    width: 25%;
  }
}
.mode-contribution {
  width: 110px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
  margin-right: 8px;
  @media (max-width: $mobile-break-point) {
    width: 25%;
  }
}
.pourcentage-contribution {
  width: 30px;
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: right;
  margin-right: 8px;
}
.symbole {
  @include typo-medium;
  color: $couleur-texte-fonce;
  text-align: center;
}
</style>
